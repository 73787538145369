import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";
import commonStyles from '../common/common_styles.module.css';
import styles from './styles.module.css';

const Test = () => {

  const handleSubmit = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getDetailsFromServiceName = httpsCallable(functions, 'getDetailsFromName');
    getDetailsFromServiceName({name: "SOUNDRAW", language: "jpn"})
        .then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleSubmitUpdateUserProfile = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const updateUserProfile = httpsCallable(functions, 'updateUserProfile');
    updateUserProfile({name: "Hisashsh", profile: "THE ut"})
        .then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetUserProfile = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getUserProfile = httpsCallable(functions, 'getUserProfile');
    getUserProfile({})
        .then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

//   const handleSearchServiceByKeyword = async (event) => {
//     event.preventDefault();
//     const functions = getFunctions();
    
//     functions.region = "asia-northeast1"
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     const getUserProfile = httpsCallable(functions, 'searchServicesByKeyword');
//     getUserProfile({})
//         .then((result) => {
//             const data = result.data;
//             console.log(data);
//         }).catch((error) => {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log('Error response data:', error.response.data);
//                 console.log('Error response status:', error.response.status);
//                 console.log('Error response headers:', error.response.headers);
//               } else if (error.request) {
//                 // The request was made but no response was received
//                 console.log('Error request:', error.request);
//               } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Error', error.message);
//               }
//         });
//   };

  const handleGetDetailByName = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getDetailsFromName');
    getServices({name: "Chat GPT", language: "jpn"})
        .then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

//   const handleUpdateServiceSeachListCache = async (event) => {
//     event.preventDefault();
//     const functions = getFunctions();
    
//     functions.region = "asia-northeast1"
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     const getServices = httpsCallable(functions, 'testUpdateServiceSeachListCache');
//     getServices({})
//         .then((result) => {
//             const data = result.data;
//             console.log(data);
//         }).catch((error) => {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log('Error response data:', error.response.data);
//                 console.log('Error response status:', error.response.status);
//                 console.log('Error response headers:', error.response.headers);
//               } else if (error.request) {
//                 // The request was made but no response was received
//                 console.log('Error request:', error.request);
//               } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Error', error.message);
//               }
//         });
//   };

//   const handleUpdateServiceSeachListCacheOrderByGoodCount = async (event) => {
//     event.preventDefault();
//     const functions = getFunctions();
    
//     functions.region = "asia-northeast1"
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     const getServices = httpsCallable(functions, 'testUpdateServiceSeachListCacheOrderByGoodCount');
//     getServices({})
//         .then((result) => {
//             const data = result.data;
//             console.log(data);
//         }).catch((error) => {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log('Error response data:', error.response.data);
//                 console.log('Error response status:', error.response.status);
//                 console.log('Error response headers:', error.response.headers);
//               } else if (error.request) {
//                 // The request was made but no response was received
//                 console.log('Error request:', error.request);
//               } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Error', error.message);
//               }
//         });
//   };

//   const handleUpdateServiceSeachListCacheOrderByRevisionedAt = async (event) => {
//     event.preventDefault();
//     const functions = getFunctions();
    
//     functions.region = "asia-northeast1"
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     const getServices = httpsCallable(functions, 'testUpdateServiceSeachListCacheOrderByRevisionedAt');
//     getServices({})
//         .then((result) => {
//             const data = result.data;
//             console.log(data);
//         }).catch((error) => {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log('Error response data:', error.response.data);
//                 console.log('Error response status:', error.response.status);
//                 console.log('Error response headers:', error.response.headers);
//               } else if (error.request) {
//                 // The request was made but no response was received
//                 console.log('Error request:', error.request);
//               } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Error', error.message);
//               }
//         });
//   };

//   const handleGetServicesOrderByViewCount = async (event) => {
//     event.preventDefault();
//     const functions = getFunctions();
    
//     functions.region = "asia-northeast1"
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     const getServices = httpsCallable(functions, 'getServicesOrderByViewCount');
//     getServices({page: 0})
//         .then((result) => {
//             const data = result.data;
//             console.log(data);
//         }).catch((error) => {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log('Error response data:', error.response.data);
//                 console.log('Error response status:', error.response.status);
//                 console.log('Error response headers:', error.response.headers);
//               } else if (error.request) {
//                 // The request was made but no response was received
//                 console.log('Error request:', error.request);
//               } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Error', error.message);
//               }
//         });
//   };

//   const handleGetServicesOrderBy = async (event) => {
//     event.preventDefault();
//     const functions = getFunctions();
    
//     functions.region = "asia-northeast1"
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     const getServices = httpsCallable(functions, 'getServicesOrderBy');
//     getServices({page: 0, order: "good_count"})
//         .then((result) => {
//             const data = result.data;
//             console.log(data);
//         }).catch((error) => {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log('Error response data:', error.response.data);
//                 console.log('Error response status:', error.response.status);
//                 console.log('Error response headers:', error.response.headers);
//               } else if (error.request) {
//                 // The request was made but no response was received
//                 console.log('Error request:', error.request);
//               } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Error', error.message);
//               }
//         });
//   };

  const handleUpdateUserRankingCacheOrderByUpdatedServiceCount = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'testUpdateUserRankingCacheOrderByUpdatedServiceCount');
    getServices({})
        .then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetUsersOrderBy = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getUsersOrderBy');
    getServices({order: "updated_service_count"})
        .then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleAddUsecase = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'addUsecase');
    getServices({
            title: "初めてのユースケース",
            service_names: [
                "TEST SERVICE",
                "bb",
            ],
            contents: [
                {
                    content_type: "headline",
                    content: "テッステッス"
                },
                {
                    content_type: "paragraph",
                    content: "hey yo yo yo"
                }
            ],
            tags: [
                "work",
                "play",
            ]
        }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleUpdateUsecase = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'updateUsecase');
    getServices({
            usecase_id: "RQY4lAqWRi3GgLjo8f6F",
            title: "初めてのユースケース更新後",
            service_names: [
                "TEST SERVICE",
            ],
            contents: [
                {
                    content_type: "headline",
                    content: "てっすてっす"
                },
                {
                    content_type: "paragraph",
                    content: "hey yo yo yo"
                }
            ],
            tags: [
                "work",
                "play",
            ]
        }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetUsecasesByServiceId = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getUsecasesByServiceName');
    getServices({name: "TEST SERVICE",
            limit: 4, order: "good_count", usecase_id_start_after: "",
        }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleUpdateTagMaps = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'testUpdateTagMaps');
    getServices({}).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetServices = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getServices');
    getServices({
        order_by: "view_count",
        modifier: "asc",
        limit: 10,
        start_after: "",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetFavoriteServices = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getFavoriteServices');
    getServices({
        language: "jpn",
        limit: 10,
        service_id_start_after: "6aAZCCbW2g76ebFzcREC",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetServicesByKeyword = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getServicesByKeyword');
    getServices({
        keyword: "chat",
        limit: 10,
        page: 0,
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleTestUpdateKeywordRanking = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'testUpdateKeywordRanking');
    getServices({}).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetKeywordRanking = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getKeywordRanking');
    getServices({}).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetServiceOutlineFromName = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getServiceOutlineFromName');
    getServices({
        name: "Chat GPT",
        language: "jpn"
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetServicesByTag = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getServicesByTag');
    getServices({
        limit: 10,
        modifier: "desc",
        order_by: "view_count",
        tag: "play"
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleAddNewService = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'addNewService');
    getServices({
        name: "test",
        language: "jpn",
        tag: "play",
        primary_usage: [
            {
                content_type: "table",
                table_type: "no_th",
                content: [
                    ['FreePlan', 'premium', 'ultimate'],
                    ['', '100円', '1000円'],
                ]
            },
        ]
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetPostedUsecases = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getPostedUsecases');
    getServices({
        limit: 10,
        usecase_id_start_after: "",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetDetailsFromId = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getDetailsFromId');
    getServices({
        service_id: "jVcfw7HQI9WL7TiXxLV5",
        language: "jpn",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleAddFavoriteUsecase = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'addFavoriteUsecase');
    getServices({
        usecase_id: "EK2qBI5SYvtLw3cipnoB",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleSetGoodOnUsecase = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'setGoodOnUsecase');
    getServices({
        usecase_id: "EK2qBI5SYvtLw3cipnoB",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetFavoriteUsecases = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getFavoriteUsecases');
    getServices({
        limit: 10,
        favorite_usecase_id_start_after: "",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetAddedServices = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getAddedServices');
    getServices({
        limit: 10,
        service_id_start_after: "",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleAddFavoriteService = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'addFavoriteService');
    getServices({
        service_id: "TyS4HEh6LETnexHJdqrr",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleDeleteFavoriteService = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'deleteFavoriteService');
    getServices({
        service_id: "TyS4HEh6LETnexHJdqrr",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };



  const handleSetGoodOnService = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'setGoodOnService');
    getServices({
        service_id: "TyS4HEh6LETnexHJdqrr",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleDeleteGoodService = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'deleteGoodService');
    getServices({
        service_id: "TyS4HEh6LETnexHJdqrr",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetServiceNamesByKeyword = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getServiceNamesByKeyword');
    getServices({
        keyword: "chat",
        limit: 10,
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleGetUsecaseByIds = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getUsecaseByIds');
    getServices({
        service_id: "0eLxGTVsuQKO95GnuF3c",
        usecase_id: "X9N7HbYcNkJAlNs9mpN4",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleDeleteFavoriteUsecase = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'deleteFavoriteUsecase');
    getServices({
        usecase_id: "EK2qBI5SYvtLw3cipnoB",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetImageAsBase64 = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getImageAsBase64');
    getServices({
        file_name: "0WdGwJEXPxAP0rDF07Gv_thumbnail_20230715050",
        image_type: "thumbnail",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleDeleteGoodUsecase = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'deleteGoodUsecase');
    getServices({
        usecase_id: "EK2qBI5SYvtLw3cipnoB",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetUsecaseByUsecaseId = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getUsecaseByUsecaseId');
    getServices({
        usecase_id: "EK2qBI5SYvtLw3cipnoB",
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleTestUpdateTotalUsecaseViewCount = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'testUpdateTotalUsecaseViewCount');
    getServices({
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };

  const handleGetTotalViewCountRanking = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getTotalViewCountRanking');
    getServices({
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleGetUsersViewCountRanking = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getUsersViewCountRanking');
    getServices({
        limit: 10
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  const handleGetUserCount = async (event) => {
    event.preventDefault();
    const functions = getFunctions();
    
    functions.region = "asia-northeast1"
    connectFunctionsEmulator(functions, "localhost", 5001);
    const getServices = httpsCallable(functions, 'getUserCount');
    getServices({
    }).then((result) => {
            const data = result.data;
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        });
  };


  return (
    <div className={styles.chatContainer}>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >getDetailsFromName</button>
      </form>
      <form onSubmit={handleGetDetailsFromId}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetDetailsFromId</button>
      </form>
      <form onSubmit={handleSubmitUpdateUserProfile}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >updateUserProfile</button>
      </form>
      <form onSubmit={handleGetUserProfile}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUserProfile</button>
      </form>
      <form onSubmit={handleGetDetailByName}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetServicesByKeyword</button>
      </form>
      
      <form onSubmit={handleUpdateUserRankingCacheOrderByUpdatedServiceCount}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >UpdateUserRankingCacheOrderByUpdatedServiceCount</button>
      </form>
      <form onSubmit={handleGetUsersOrderBy}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUsersOrderBy</button>
      </form>

      <form onSubmit={handleAddUsecase}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >AddUsecase</button>
      </form>

      <form onSubmit={handleGetUsecasesByServiceId}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUsecasesByServiceId</button>
      </form>
      <form onSubmit={handleUpdateTagMaps}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >UpdateTagMaps</button>
      </form>

      <form onSubmit={handleGetServices}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetServices</button>
      </form>
      <form onSubmit={handleGetFavoriteServices}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetFavoriteServices</button>
      </form>

      <form onSubmit={handleGetServicesByKeyword}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetServicesByKeyword</button>
      </form>
      {/* <form onSubmit={handleTestUpdateKeywordRanking}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >TestUpdateKeywordRanking</button>
      </form> */}
      <form onSubmit={handleGetKeywordRanking}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetKeywordRanking</button>
      </form>
      <form onSubmit={handleGetServiceOutlineFromName}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetServiceOutlineFromName</button>
      </form>
      <form onSubmit={handleGetServicesByTag}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetServicesByTag</button>
      </form>
      <form onSubmit={handleAddNewService}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >AddNewService</button>
      </form>
      <form onSubmit={handleGetPostedUsecases}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetPostedUsecases</button>
      </form>
      <form onSubmit={handleAddFavoriteUsecase}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >AddFavoriteUsecase</button>
      </form>
      <form onSubmit={handleSetGoodOnUsecase}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >SetGoodOnUsecase</button>
      </form>
      <form onSubmit={handleGetFavoriteUsecases}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetFavoriteUsecases</button>
      </form>
      <form onSubmit={handleGetAddedServices}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetAddedServices</button>
      </form>
      <form onSubmit={handleAddFavoriteService}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >AddFavoriteService</button>
      </form>
      <form onSubmit={handleDeleteFavoriteService}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >DeleteFavoriteService</button>
      </form>
      <form onSubmit={handleSetGoodOnService}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >SetGoodOnService</button>
      </form>
      <form onSubmit={handleDeleteGoodService}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >DeleteGoodService</button>
      </form>
      <form onSubmit={handleGetServiceNamesByKeyword}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetServiceNamesByKeyword</button>
      </form>
      <form onSubmit={handleGetUsecaseByIds}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUsecaseByIds</button>
      </form>
      <form onSubmit={handleDeleteFavoriteUsecase}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >DeleteFavoriteUsecase</button>
      </form>
      <form onSubmit={handleGetImageAsBase64}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetImageAsBase64</button>
      </form>
      <form onSubmit={handleGetImageAsBase64}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetImageAsBase64</button>
      </form>
      <form onSubmit={handleUpdateUsecase}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >UpdateUsecase</button>
      </form>
      <form onSubmit={handleDeleteGoodUsecase}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >DeleteGoodUsecase</button>
      </form>
      <form onSubmit={handleGetUsecaseByUsecaseId}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUsecaseByUsecaseId</button>
      </form>
      <form onSubmit={handleTestUpdateTotalUsecaseViewCount}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >TestUpdateTotalUsecaseViewCount</button>
      </form>
      <form onSubmit={handleGetTotalViewCountRanking}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetTotalViewCountRanking</button>
      </form>
      <form onSubmit={handleGetUsersViewCountRanking}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUsersViewCountRanking</button>
      </form>
      <form onSubmit={handleGetUserCount}>
        <input type="text" placeholder="ここに入力してください..." />
        <button className={`${commonStyles.primaryButton} ${styles.chatButton}`} type="submit" >GetUserCount</button>
      </form>
    </div>
  );
};

export default Test;