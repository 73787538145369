import React, { useLayoutEffect, useState } from 'react';
import 'firebase/compat/functions';
import commonStyles from '../common/common_styles.module.css'
import tagStyles from './tag_styles.module.css';
import { Link, useLocation } from 'react-router-dom';
import SideBar from '../common/sidebar';
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig'; 
import { Helmet } from 'react-helmet-async';
import ServiceCards from '../common/servicesCards';
import Loading from '../common/loading';
import Page404 from '../common/page404';
import ErrorMessage from '../common/errorMessage';
import { tags, tagAndLabel } from '../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';

const Tag = () => {
    const location = useLocation();
    const paths = location.pathname.split('/').filter(Boolean);
    const [isError, setIsError] = useState(false);
    const [isLoaded, setisLoaded] = useState(false);
    const [services, setServices] = useState([]);

    const LIMIT_SERVICES = 10;
    
    const checkPath = (paths) => {
        if (paths.length != 2) {
            return false;
        }
        if (!tags.includes(decodeURI(paths[1]))){
            return false;
        }
        return true;
    }

    useLayoutEffect(()=>{
        initialize();
        if(!checkPath(paths)){
            setisLoaded(false);
            setIsError(true);
            return;
        }
        const getServicesByTag = httpsCallable(functions, 'getServicesByTag');
        getServicesByTag({
                order_by: "view_count",
                limit: LIMIT_SERVICES,
                modifier: "desc",
                tag: decodeURI(paths[1])
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    setisLoaded(true);
                    setIsError(true);
                    return;
                }
                setServices(data.services)
                setisLoaded(true);
                setIsError(false);
            }).catch((error) => {
                setisLoaded(true);
                setIsError(true);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    },[location]);

    const initialize = () => {
        setIsError(false);
        setisLoaded(false);
        setServices([]);
    }

    const handleClickLoadMoreButton = () => {
        setisLoaded(false);
        const getServicesByTag = httpsCallable(functions, 'getServicesByTag');
        getServicesByTag({
                order_by: "view_count",
                limit: LIMIT_SERVICES,
                modifier: "desc",
                tag: decodeURI(paths[1]),
                start_after: services[services.length-1].service_id
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    setisLoaded(true);
                    setIsError(true);
                    return;
                }
                setServices([...services, ...data.services])
                setisLoaded(true);
                setIsError(false);
            }).catch((error) => {
                setisLoaded(true);
                setIsError(true);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }

    return (
        isError && !isLoaded ?
        <Page404/>
        :
        <>
        <Helmet>
            <title>{`${tagAndLabel[decodeURI(paths[1])]} - AI TechBase`}</title>
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
            <SideBar tagNav={true} last={'tagNav'}/>
            <div className={commonStyles.sectionWrapper}>
                <section className={commonStyles.secondarySection}>
                    <div className={tagStyles.tagLogoWrapper}>
                        <FontAwesomeIcon icon={faHashtag}/>
                        <span>{tagAndLabel[decodeURI(paths[1])]}</span>
                    </div>
                </section>
                <section className={commonStyles.secondarySection}>
                    {
                        services.length == 0 && isLoaded && !isError ?
                        <div className={tagStyles.addNewAppButtonWrapper}>
                            <p>ツールが見つかりませんでした。<br/>新しいツールを登録していただけませんか？</p>
                            <Link className={commonStyles.primaryButton} to='/editor' onClick={()=>window.scrollTo({top: 0})}>ツールを追加する</Link>
                        </div>
                        :
                        <>
                            <ServiceCards services={services}/>
                            {
                                services.length && services.length % LIMIT_SERVICES==0 && isLoaded && !isError ?
                                <button className={`${commonStyles.primaryButton} ${tagStyles.loadMoreButton}`} onClick={handleClickLoadMoreButton}>もっと読み込む</button>
                                :
                                <></>
                            }
                            {
                                isLoaded ?
                                <></>
                                :
                                <Loading/>
                            }
                            {
                                isError ?
                                <ErrorMessage message='申し訳ありません。読み込みに失敗しました。'/>
                                :
                                <></>
                            }
                            {
                                isLoaded && !isError ?
                                <div className={tagStyles.addNewAppButtonWrapper}>
                                    <p>あなたも新しいツールを追加してみませんか？</p>
                                    <Link className={commonStyles.primaryButton} to='/editor' onClick={()=>window.scrollTo({top: 0})}>ツールを追加する</Link>
                                </div>
                                :
                                <></>
                            }
                        </>
                    }
                </section>
            </div>
            <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
        </main> 
        </>
    );
}

export default Tag;