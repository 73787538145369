import React, { useLayoutEffect, useState } from 'react';
import 'firebase/compat/functions';
import commonStyles from '../common/common_styles.module.css'
import searchStyles from './search_styles.module.css';
import { Link, useSearchParams } from 'react-router-dom';
import SideBar from '../common/sidebar';
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig'; 
import { Helmet } from 'react-helmet-async';
import ServiceCards from '../common/servicesCards';
import Loading from '../common/loading';
import Page400 from '../common/page400';

const Search = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const queryKeyword = searchParams.get('keyword') === ""  ? " " : searchParams.get('keyword');
    const queryPage = searchParams.get('page');
    const [services, setServices] = useState([]);
    const [isQueryFilled, setIsQueryFilled] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [keyword, setKeyword] = useState(queryKeyword);

    const LIMIT_SERVICES = 10;

    const checkQuery = (keyword, page) => {
        if(!keyword || !page){
            return false;
        }
        if(page <= 0){
            return false;
        }

        return true;
    }
    
    useLayoutEffect(()=>{
        if(checkQuery(queryKeyword, Number(queryPage))){
            setIsQueryFilled(true);
        }else{
            setIsQueryFilled(false);
            setIsInitialized(true);
            return;
        }
        const getServicesByKeyword = httpsCallable(functions, 'getServicesByKeyword');
        getServicesByKeyword({
                keyword: queryKeyword,
                page: Number(queryPage)-1,
                limit: LIMIT_SERVICES
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    return;
                }
                setServices(data.services);
                setIsInitialized(true);
            }).catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    },[searchParams]);

    const initialize = () => {
        setIsInitialized(false);
        setIsQueryFilled(false);
        setServices([]);
    }

    const handleClickMovePageButton = () => {
        initialize();
        window.scrollTo({top: 0});
    }

    const handleClickSearchButton = () => {
        //元のキーワードと同じなら何もしない
        if(queryKeyword == keyword) {
            return;
        }
        initialize();
        window.scrollTo({top: 0});
    }

    return (
        isInitialized && !isQueryFilled ?
        <Page400/>
        :
        <>
        <Helmet>
            <title>{`${queryKeyword} - AI TechBase`}</title>
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
            <SideBar tagNav={true} last={'tagNav'}/>
            <div className={commonStyles.sectionWrapper}>
                <section className={commonStyles.secondarySection}>
                    <div className={searchStyles.searchForm}>
                        <input className={commonStyles.primaryInput} type="text" placeholder="キーワードを入力" value={keyword} onChange={(e)=>setKeyword(e.currentTarget.value)}/>
                        <Link className={`${commonStyles.primaryButton} ${searchStyles.chatButton}`} to={`/search?keyword=${keyword}&page=1`} onClick={handleClickSearchButton}>検索</Link>
                    </div>
                </section>
                <section className={commonStyles.secondarySection}>
                    {
                        isInitialized ?
                        (
                            services.length == 0 ?
                            <div className={searchStyles.addNewAppButtonWrapper}>
                                <p>ツールが見つかりませんでした。<br/>新しいツールを登録していただけませんか？</p>
                                <Link className={commonStyles.primaryButton} to='/editor' onClick={()=>window.scrollTo({top: 0})}>ツールを追加する</Link>
                            </div>
                            :
                            <>
                                <div className={searchStyles.moveButtonWrapper}>
                                    {
                                        queryPage > 1 ?
                                        <Link className={commonStyles.primaryButton} to={`/search?keyword=${queryKeyword}&page=${Number(queryPage)-1}`} onClick={handleClickMovePageButton}>前のページ</Link>
                                        :
                                        <></>
                                    }
                                    {
                                        services.length == LIMIT_SERVICES ?
                                        <Link className={commonStyles.primaryButton} to={`/search?keyword=${queryKeyword}&page=${Number(queryPage)+1}`} onClick={handleClickMovePageButton}>次のページ</Link>
                                        :
                                        <></>
                                    }
                                </div>
                                <ServiceCards services={services}/>
                                <div className={searchStyles.addNewAppButtonWrapper}>
                                    <p>あなたも新しいツールを追加してみませんか？</p>
                                    <Link className={commonStyles.primaryButton} to='/editor' onClick={()=>window.scrollTo({top: 0})}>ツールを追加する</Link>
                                </div>
                            </>
                        )
                        :
                        <Loading/>
                    }
                </section>

            </div>
            <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
        </main> 
        </>
    );
}

export default Search;