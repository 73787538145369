import commonStyles from './common_styles.module.css';
import { useLayoutEffect, useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../database/firebaseConfig';
import sideBarStyles from './sidebar_styles.module.css';
import twitterIcon from "../img/x_logo-black.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { tagAndLabel } from './constants';
import Loading from './loading';

const SideBar = ({twitterNav, updateNav, tagNav, userViewRankingNav, articleNav, articleNavList, last}) => {

    const [usersViewRanking, setUsersViewRanking] = useState([]);
    const [isLoadedUsersEditRanking, setIsLoadedUserEditRanking] = useState(false);

    useLayoutEffect(()=>{
        if(userViewRankingNav) {
            const getUsersViewCountRanking = httpsCallable(functions, 'getUsersViewCountRanking');
            getUsersViewCountRanking({
                    limit: 10
                }).then((result) => {
                    const data = result.data;
                    if (data.status != 200){
                        return;
                    }
                    setUsersViewRanking(data.users);
                    setIsLoadedUserEditRanking(true);
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }        
    }, []);

    return (
        <div className={commonStyles.sideBar}>
            {/*{
                articleNav ? 
                <nav className={sideBarStyles.primarySideBarNav}>
                    <p className={sideBarStyles.articleNavHeader}>目次</p>
                    <ul className={sideBarStyles.navH2List}>
                        {articleNavList.map((h2, index) => {
                            return (
                            <li key={index}>
                                <span className={sideBarStyles.navH2Index}>{index+1}</span>
                                <div className={sideBarStyles.navNest}>
                                    <a className={`${sideBarStyles.navH2} ${commonStyles.primaryA}`} href={`#${h2.key}`}>{h2.label}</a>
                                    <ul className={sideBarStyles.navH3List}>
                                        {h2.list.map((h3, i) => {
                                            return(
                                            <li key={i}>
                                                <a className={`${sideBarStyles.navH3} ${commonStyles.primaryA}`} href={`#${h3.key}`}>{h3.label}</a>
                                            </li>);
                                        })}
                                    </ul>
                                </div>
                            </li>);
                        })}
                    </ul>
                </nav> : 
                <></>
            }*/}
            {
                articleNav ? 
                <nav className={`${sideBarStyles.primarySideBarNav} ${last=='articleNav' ? sideBarStyles.stickyNav : ''}`}>
                    <p className={sideBarStyles.articleNavHeader}>目次</p>
                    <ul className={sideBarStyles.navH2List}>
                        {articleNavList.map((h2, index) => {
                            return (
                            <li key={index}>
                                <span className={sideBarStyles.navH2Index}>{index+1}</span>
                                <div className={sideBarStyles.navNest}>
                                    <a className={`${sideBarStyles.navH2} ${commonStyles.primaryA}`} href={`#${h2.key}`} onClick={ (e) => {
                                        e.preventDefault();
                                        if (h2.onClick) {
                                            h2.onClick();
                                            setTimeout(() => {window.location.hash = h2.key;}, 0);
                                        } else {
                                            window.location.hash = h2.key;
                                        }
                                    }}>
                                        {h2.label}
                                    </a>
                                    <ul className={sideBarStyles.navH3List}>
                                        {h2.list.map((h3, i) => {
                                            return(
                                            <li key={i}>
                                                <a className={`${sideBarStyles.navH3} ${h3.key !== undefined ? commonStyles.primaryA : ''}`} href={`#${h3.key}`}>
                                                    {h3.label}
                                                </a>
                                            </li>);
                                        })}
                                    </ul>
                                </div>
                            </li>);
                        })}
                    </ul>
                </nav> : 
                <></>
            }
            {
               twitterNav ?  
                <nav className={`${sideBarStyles.primarySideBarNav} ${last=='twitterNav' ? sideBarStyles.stickyNav : ''}`}>
                    <div className={sideBarStyles.twitterCard}>
                        <a href="https://X.com/ai_techbase" target="_blank"><img className={sideBarStyles.twitterIcon} src={twitterIcon}></img></a>
                        <div>
                            <p className={sideBarStyles.twitterDescription}>アップデートの内容などを発信中です！AIに関する情報も呟きます😃</p>
                            <a href="https://X.com/ai_techbase" target="_blank" rel="noopener noreferrer" className={sideBarStyles.twitterDescription}>@ai_techbase</a>
                        </div>
                    </div>
                </nav> :
                <></>
            }
            {
                updateNav ?  
                <nav className={`${sideBarStyles.primarySideBarNav} ${last=='updateNav' ? sideBarStyles.stickyNav : ''}`}>
                    <div className={sideBarStyles.updateCard}>
                        <p className={sideBarStyles.updateHeader}>アップデート情報</p>
                        <p className={sideBarStyles.updateDescription}>サイドバーのデザインを変更しました。<br/><br/>今後も皆様が使いやすいようにアップデートを重ねていきます。気になる部分がありましたら、些細なことでも構いませんのでお問い合わせください。</p>
                        <a className={`${sideBarStyles.updateDescription} ${sideBarStyles.updateLink}`} href='https://docs.google.com/forms/d/e/1FAIpQLScNF5xevLzFdt822oiDNekf2n_MA_m8-8AJl4QP4NmkliiiSw/viewform?usp=sf_link' target="_blank">お問い合わせ</a>
                    </div>
                </nav> :
                <></>
            }
            {
                tagNav ?
                <nav className={`${sideBarStyles.primarySideBarNav} ${last=='tagNav' ? sideBarStyles.stickyNav : ''}`}>
                    <div className={sideBarStyles.tagCard}>
                        <p className={sideBarStyles.tagHeader}>タグ一覧</p>
                        <div className={sideBarStyles.tagList}>
                            {
                                Object.keys(tagAndLabel).map((key, i) => {
                                    return <Link key={i} to={`/tags/${key}`} className={commonStyles.tagPrimaryColor} onClick={()=>window.scrollTo({top: 0})}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[key]}</Link>
                                })
                            }
                        </div>
                    </div>
                </nav> :
                <></>
            }
            {
                userViewRankingNav ?
                <nav className={`${sideBarStyles.primarySideBarNav} ${last=='userViewRankingNav' ? sideBarStyles.stickyNav : ''}`}>
                    <div className={sideBarStyles.userEditRankingCard}>
                        <p className={sideBarStyles.userEditRankingHeader}>注目ユーザー 👀</p>
                        <ul className={sideBarStyles.userList}>
                            {
                                isLoadedUsersEditRanking ?
                                usersViewRanking.map((user, i) => {
                                    return <li key={i}>
                                            <div className={sideBarStyles.userWrapper}>
                                                <span className={sideBarStyles.rankingIndex}>{i+1}</span>
                                                <div className={sideBarStyles.userIconWrapper}>
                                                    <img className={commonStyles.userIcon} src={user.icon_base64 ? (user.icon_base64.startsWith('data:image') ? user.icon_base64 : `data:image/jpeg;base64,${user.icon_base64}`) : ''}></img>
                                                </div>
                                                <Link to={`/userPage/${user.user_id}`} key={i} className={sideBarStyles.userName} onClick={()=>window.scrollTo({top: 0})}>{user.name}</Link>
                                            </div>
                                            <p><span className={sideBarStyles.userEdit}>調整中</span></p>
                                        </li>
                                })
                                :
                                <Loading/>
                            }
                        </ul>
                    </div>
                </nav> :
                <></>
            }
        </div>
    );
};

export default SideBar;