import commonStyles from '../common/common_styles.module.css';
import SideBar from '../common/sidebar';
import aboutCommonStyles from './about_common_styles.module.css';
import productStyles from './product_styles.module.css';
import { Helmet } from 'react-helmet-async';

const Product = () => {

    return (
        <>
        <Helmet>
            <title>AI TechBaseについて - AI TechBase</title>
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarR} ${commonStyles.primaryMain}`}>
            <div className={commonStyles.sectionWrapper}>
                <section className={`${commonStyles.primarySection} ${aboutCommonStyles.mainWrapper}`}>
                    <h2 id="overview" className={commonStyles.primaryH2}>概要</h2>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        {`便利なAIツールを取りまとめ、紹介しています。検索やレビューにより、ユーザーが望むツールを提供します。またユーザーがツールの投稿・編集を行えるよう、wikiシステムを導入しています。`}
                    </p>
                    <h2 id="philosophy" className={commonStyles.primaryH2}>理念</h2>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        {`昨今のAIブームにより、膨大な数のAIツールがリリースされています。しかし、「どのツールを使えば良いかわからない」「使い方や利用方法がよくわからない」といった悩みが多いはずです。そんな悩みを解消するために、本サービスは開発されました。
                    「誰もが、自分の要望に沿ったAIツールを見つけ、実際に扱えるようになる」ことを目指しています。`}
                    </p>
                    <h2 id="contact" className={commonStyles.primaryH2}>お問い合わせ</h2>
                    <a className={`${commonStyles.primaryButton} ${productStyles.inquiryButton}`} href='https://docs.google.com/forms/d/e/1FAIpQLScNF5xevLzFdt822oiDNekf2n_MA_m8-8AJl4QP4NmkliiiSw/viewform?usp=sf_link' target="_blank">お問い合わせ（新規タブが開きます）</a>
                </section>
            </div>
            <SideBar twitterNav={true} updateNav={true} articleNav={true} articleNavList={[{key:'overview', label:'概要', list:[]}, {key:'philosophy', label:'理念', list:[]}, {key:'contact', label:'お問い合わせ', list:[]}]} last={'updateNav'}/>
        </main>
        </>
    );
};

export default Product;