import React, { useLayoutEffect, useState } from 'react';
import 'firebase/compat/functions';
import commonStyles from '../common/common_styles.module.css'
import allStyles from './all_styles.module.css';
import SideBar from '../common/sidebar';
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig'; 
import { Helmet } from 'react-helmet-async';
import ServiceCards from '../common/servicesCards';
import Loading from '../common/loading';
import ErrorMessage from '../common/errorMessage';

const First = () => {
    const LIMIT_LOAD_SERVICES = 10;

    const [services, setServices] = useState({
        view_count: {
            asc: {is_loaded:false, is_error_: false, services: []},
            desc: {is_loaded:false, is_error_: false, services: []}
        },
        good_count: {
            asc: {is_loaded:false, is_error_: false, services: []},
            desc: {is_loaded:false, is_error_: false, services: []}
        },
        revisioned_at: {
            asc: {is_loaded:false, is_error_: false, services: []},
            desc: {is_loaded:false, is_error_: false, services: []}
        }
    });

    const [sort, setSort] = useState('view_count');

    const getStartAfter = (order_by, modifier) => {
        if(services[order_by][modifier].services.length){
            return services[order_by][modifier].services[services[order_by][modifier].services.length-1].service_id;
        } else {
            return '';
        }
    }

    const setIsLoaded = (order_by, modifier, isLoaded) => {
        const servicesTemp = JSON.parse(JSON.stringify(services));
        servicesTemp[order_by][modifier] = {...servicesTemp[order_by][modifier], is_loaded: isLoaded};
        setServices(servicesTemp);
    }

    const setIsError = (order_by, modifier, isError) => {
        const servicesTemp = JSON.parse(JSON.stringify(services));
        servicesTemp[order_by][modifier] = {...servicesTemp[order_by][modifier], is_error: isError};
        setServices(servicesTemp);
    }

    const loadServices = (order_by, modifier) => {
        const getServices = httpsCallable(functions, 'getServices');
        getServices({
            order_by: order_by,
            limit: LIMIT_LOAD_SERVICES,
            modifier: modifier,
            start_after: getStartAfter(order_by, modifier),
        }).then((result) => {
            const data = result.data;
            if (data.status != 200){
                setIsError(order_by, modifier, true);
                return;
            }
            const servicesTemp = JSON.parse(JSON.stringify(services));
            servicesTemp[order_by][modifier].services = [...servicesTemp[order_by][modifier].services, ...data.services];
            servicesTemp[order_by][modifier].is_loaded = true;
            setServices(servicesTemp);
        }).catch((error) => {
            setIsError(order_by, modifier, true);
            console.error(error);
        });
    }

    useLayoutEffect(()=>{
        loadServices('view_count', 'desc');
    },[]);

    const handleClickSortOption = (option) => {
        setSort(option);
        if(services[option].desc.services.length==0){
            loadServices(option, 'desc');
        }
    }

    const handleClickLoadMoreButton = () => {
        setIsLoaded(sort, 'desc', false);
        loadServices(sort, 'desc');
    }

    return (
        <>
        <Helmet>
            <title>一覧 - AI TechBase</title>
            <meta
            name="description"
            content="サービスの一覧です。登録されているサービスをすべてご覧いただけます。"
            />
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
            <SideBar tagNav={true} last={'tagNav'}/>
            <div className={commonStyles.sectionWrapper}>
                <section className={commonStyles.secondarySection}>
                    <div className={allStyles.sortWrapper}>
                        <ul className={allStyles.sortOptions}>
                            <li className={`${commonStyles.tagPrimaryColor} ${sort=='view_count' ?  allStyles.tagActive : ''}`} onClick={()=>handleClickSortOption('view_count')}>閲覧数</li>
                            <li className={`${commonStyles.tagPrimaryColor} ${sort=='good_count' ?  allStyles.tagActive : ''}`} onClick={()=>handleClickSortOption('good_count')}>いいね数</li>
                            <li className={`${commonStyles.tagPrimaryColor} ${sort=='revisioned_at' ?  allStyles.tagActive : ''}`} onClick={()=>handleClickSortOption('revisioned_at')}>更新日</li>
                        </ul>
                    </div>
                </section>
                <section className={commonStyles.secondarySection}>
                    {
                        services[sort].desc.is_loaded && !services[sort].desc.is_error ?
                        <>
                            <ServiceCards services={services[sort].desc.services}/>
                            {
                                services[sort].desc.services.length && services[sort].desc.services.length%LIMIT_LOAD_SERVICES==0 ?
                                <button className={`${commonStyles.primaryButton} ${allStyles.loadMoreButton}`} onClick={handleClickLoadMoreButton}>もっと読み込む</button>
                                :
                                <></>
                            }
                        </>
                        :
                        services[sort].desc.is_error ?
                        <>
                            <ServiceCards services={services[sort].desc.services}/>
                            <ErrorMessage className={commonStyles.marginTopMiddle} message='情報の読み込み中に問題が発生しました。'/>
                        </>
                        :
                        <>
                            <ServiceCards services={services[sort].desc.services}/>
                            <Loading className={commonStyles.marginTopMiddle}/>
                        </>
                    }
                </section>
            </div>
            <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
        </main> 
        </>
    );
}

export default First;