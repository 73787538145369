import loadingStyles from './loading_styles.module.css';


const Loading = () => {

    return (
        <div className={loadingStyles.loaderWrapper}>
            <div className={loadingStyles.loader}></div>
        </div>
    );
};

export default Loading;