import commonStyles from './common_styles.module.css';
import page404Styles from './page404_styles.module.css';
import { Helmet } from 'react-helmet-async';


const Page404 = () => {

    return (
        <>
        <Helmet>
            <title>{`404 - AI TechBase`}</title>
            <meta name="robots" content="noindex"/>
        </Helmet>
        <main className={`${commonStyles.primaryMain} ${commonStyles.mainNoSideBar}`}>
            <div className={commonStyles.sectionWrapper}>
                <section className={commonStyles.secondarySection}>
                    <p className={page404Styles.logo}>404</p>
                    <p>ページが見つかりません。</p>
                    <p>URLをご確認ください。</p>
                </section>
            </div>
        </main>
        </>
    );
};

export default Page404;