import commonStyles from '../common/common_styles.module.css';
import aboutCommonStyles from './about_common_styles.module.css';
import useStyles from './use_styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import userInitialIcon from "../img/user_initial_icon.png";
import SideBar from '../common/sidebar';
import { Helmet } from 'react-helmet-async';

const Use = () => {

    return (
        <>
        <Helmet>
            <title>使い方 - AI TechBase</title>
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarR} ${commonStyles.primaryMain}`}>
            <div className={commonStyles.sectionWrapper}>
                <section className={`${commonStyles.primarySection} ${aboutCommonStyles.mainWrapper}`}>
                    <h2 id="user" className={commonStyles.primaryH2}>ユーザー設定</h2>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        {'1. 右上の '}
                        <button className={aboutCommonStyles.userButton}>登録</button>
                        {' から新規登録\n'}
                        {'2. 右上の '}
                        <button className={aboutCommonStyles.userButton}>ログイン</button>
                        {' からログイン\n'}
                        <div className={useStyles.config}>{'3. 右上の '}<FontAwesomeIcon icon={faCog} />{' からユーザー情報を設定・変更\n'}</div>
                        <div className={aboutCommonStyles.supplement}>{'※ 新規登録・ログインをしていなくても利用可能。'}</div>
                    </p>
                    <h2 id="search" className={commonStyles.primaryH2}>探す</h2>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>検索</div>
                        {`1. ホームの検索欄にキーワードを入力して検索。タグで検索も可能\n`}
                        {`2. サービスが一覧で表示される。利用したいサービスをクリック`}
                    </p>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>おすすめ表示</div>
                        {`1. ホームにおすすめサービスが表示されている\n`}
                        {`2. `}
                        <button className={aboutCommonStyles.submitButton}>もっと見る</button>
                        {` をクリック\n`}
                        {`3. `}
                        <button className={aboutCommonStyles.tagPrimaryColor}>閲覧数</button>
                        <button className={aboutCommonStyles.tagPrimaryColor}>いいね</button>
                        <button className={aboutCommonStyles.tagPrimaryColor}>更新日</button>
                        {`から表示順を選択`}
                    </p>
                    <h2 id="submit" className={commonStyles.primaryH2}>投稿・編集</h2>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>サービスの投稿</div>
                        {`1. ログインする\n`}
                        {/*<div className={useStyles.settingButton}>{`2. 右上の `}<img src={userInitialIcon}/>{` から新規投稿をクリック`}</div>*/}
                        {`2. 右上の `}
                        <FontAwesomeIcon icon={faEdit} />
                        {` からサービスを投稿をクリック\n`}
                        {`3. 各項目を入力`}
                        <div className={aboutCommonStyles.supplement}>{'※「基本情報」は入力するのが望ましい。「追加情報」と「開発者向け」は余裕があれば記入する。'}</div>
                        {`4. `}
                        <button className={aboutCommonStyles.submitButton}>公開</button>
                        {' または '}
                        <button className={aboutCommonStyles.submitButton}>プレビュー</button>
                        {` を選択`}
                    </p>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>サービスの編集</div>
                        {`1. ログインする\n`}
                        <div>{`2. 編集したいサービスのページで `}<FontAwesomeIcon icon={faEdit} />{` から編集をクリック`}</div>
                        {`3. 変更したい情報、追加したい情報を記入\n`}
                        {`4. `}
                        <button className={aboutCommonStyles.submitButton}>公開</button>
                        {' または '}
                        <button className={aboutCommonStyles.submitButton}>プレビュー</button>
                        {` を選択`}
                    </p>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>使用例の投稿</div>
                        {`1. ログインする\n`}
                        {/*<div className={useStyles.settingButton}>{`2. 右上の `}<img src={userInitialIcon}/>{` から新規投稿をクリック`}</div>*/}
                        {`2. 右上の `}
                        <FontAwesomeIcon icon={faEdit} />
                        {` から使用例を投稿をクリック\n`}
                        {`3. 使用例を入力\n`}
                        {`4. `}
                        <button className={aboutCommonStyles.submitButton}>公開</button>
                        {' または '}
                        <button className={aboutCommonStyles.submitButton}>プレビュー</button>
                        {` を選択`}
                    </p>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>使用例の編集</div>
                        {`1. ログインする\n`}
                        {`2. 右上のユーザーアイコンからマイページをクリック\n`}
                        {`3. `}
                        <button className={aboutCommonStyles.tabItem}>使用例 <FontAwesomeIcon icon={faEdit} /></button>
                        {` タブをクリック\n`}
                        {`4. 使用例を編集する\n`}
                    </p>
                    <h2 id="favorite" className={commonStyles.primaryH2}>保存</h2>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>サービスの保存</div>
                        {`1. ログインする\n`}
                        <div>{`2. 保存したいサービスのページで、サービス名の横にある `}<FontAwesomeIcon icon={faBookmark} />{` をクリック`}</div>
                        {`3. 右上のユーザーアイコンからマイページをクリック\n`}
                        {`4. `}
                        <button className={aboutCommonStyles.tabItem}>サービス <FontAwesomeIcon icon={faBookmark} /></button>
                        {` タブに保存したサービスが表示される`}
                    </p>
                    <p className={`${aboutCommonStyles.textArea} ${commonStyles.primaryP}`} style={{whiteSpace: "pre-line"}}>
                        <div className={aboutCommonStyles.subject}>使用例の保存</div>
                        {`1. ログインする\n`}
                        {`2. 保存したいサービスのページで `}
                        <button className={aboutCommonStyles.tabItem}>使用例</button>
                        {` タブをクリック\n`}
                        <div>{`3. `}<FontAwesomeIcon icon={faBookmark} />{` をクリック`}</div>
                        {`4. 右上のユーザーアイコンからマイページをクリック\n`}
                        {`5. `}
                        <button className={aboutCommonStyles.tabItem}>使用例 <FontAwesomeIcon icon={faBookmark} /></button>
                        {` タブに保存したサービスが表示される`}
                    </p>
                </section>
            </div>
            <SideBar twitterNav={true} updateNav={true} articleNav={true} articleNavList={[{key:'user', label:'ユーザー設定', list:[]}, {key:'search', label:'探す', list:[]}, {key:'submit', label:'投稿・編集', list:[]}, {key:'favorite', label:'保存', list:[]}]} last={'updateNav'}/>
        </main>
        </>
    );
};

export default Use;