import loadingDownStyles from './loading_down_styles.module.css';


const Loading = () => {

    return (
        <div className={loadingDownStyles.loaderWrapper}>
            <div className={loadingDownStyles.loader}></div>
        </div>
    );
};

export default Loading;