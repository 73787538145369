import commonStyles from './common_styles.module.css';
import headerStyles from './header_styles.module.css';
import React, {useState, useEffect, useRef} from 'react';
//import userInitialIcon from "../img/user_initial_icon.png";
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import service_logo from "../img/service_logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const Header = ({isLogin,
                userInfo,
                handleLogin, 
                handleSignin, 
                handleLogout, 
                handleDeleteAccount,
                setConfigOpen,}) => {
    
    const [isSettingOpen, setIsSettingOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const dropButtonRef = useRef(null);
    const dropDownRef = useRef(null);
    const editButtonRef = useRef(null);
    const editDownRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    const handleClickUserPageButton = () => {
        setIsSettingOpen(false);
        window.scrollTo({top: 0})
    }

    const handleClickEditorButton = () => {
        setIsEditOpen(false);
        window.scrollTo({top: 0})
    }

    const handleSettingOutside = (event) => {
        if (!dropButtonRef.current.contains(event.target) && !dropDownRef.current.contains(event.target)) {
            setIsSettingOpen(false);
        }
    };

    const handleEditOutside = (event) => {
        if (!editButtonRef.current.contains(event.target) && !editDownRef.current.contains(event.target)) {
            setIsEditOpen(false);
        }
    };

    useEffect(() => {
        if (isSettingOpen) {
            document.addEventListener('mousedown', handleSettingOutside);
        } 
        return () => {
            document.removeEventListener('mousedown', handleSettingOutside);
        };
    }, [isSettingOpen]);

    useEffect(() => {
        if (isEditOpen) {
            document.addEventListener('mousedown', handleEditOutside);
        } 
        return () => {
            document.removeEventListener('mousedown', handleEditOutside);
        };
    }, [isEditOpen]);
      
    return (
        <header>
            <div className={headerStyles.headerInner}>
                <Link className={headerStyles.logo} to='/' onClick={()=>window.scrollTo({top: 0})}>
                    <img src={service_logo}/>
                    <span>AI TechBase(β)</span>
                </Link> 
                <div className={headerStyles.configButton}>
                    <div className={headerStyles.buttonContainer}>
                        {
                        isLogin ? 
                        <div className={headerStyles.settingButtonContainer}>
                            {/*<button className={headerStyles.settingButton} ref={dropButtonRef} onClick={() => setIsSettingOpen(prevState => !prevState)}><img src={userInitialIcon}/></button>/*/}
                            <button className={headerStyles.settingButton} ref={dropButtonRef} onClick={() => setIsSettingOpen(prevState => !prevState)}><img src={`data:image/png;base64,${userInfo.icon_base64}`} alt="User Icon" /></button>
                            {
                            isSettingOpen ?
                            <div ref={dropDownRef} className={headerStyles.dropDown}>
                                <ul>
                                    <li><Link to='/userPage' onClick={handleClickUserPageButton}>マイページ</Link></li>
                                </ul>
                                <ul>
                                    <li onClick={() => {handleLogout(); setIsSettingOpen(false)}}>ログアウト</li>
                                    <li onClick={() => {handleDeleteAccount(); setIsSettingOpen(false)}}>アカウント削除</li>
                                </ul>
                                <ul>
                                    <li onClick={() => setIsSettingOpen(false)}>閉じる</li>
                                </ul>
                            </div>
                            :
                            <></>
                            }
                        </div>
                        :
                        <>
                            <button className={`${commonStyles.whiteButton} ${headerStyles.headerButton}`} onClick={handleLogin}>ログイン</button>
                            <button className={`${commonStyles.whiteButton} ${headerStyles.headerButton}`} onClick={handleSignin}>登録</button>
                        </>}
                    </div>
                    {
                    isLogin ? 
                    <div className={headerStyles.buttonContainer}>
                        <div className={headerStyles.config}>
                            <button ref={editButtonRef} onClick={() => setIsEditOpen(prevState => !prevState)}><FontAwesomeIcon icon={faEdit} /></button>
                            {
                            isEditOpen ?
                            <div ref={editDownRef} className={headerStyles.dropDown}>
                                <ul>
                                    <li><Link to='/editor' onClick={handleClickEditorButton}>サービスを投稿</Link></li>
                                    <li><Link to='/usecase' onClick={handleClickEditorButton}>使用例を投稿</Link></li>
                                </ul>
                                <ul>
                                    <li onClick={() => setIsEditOpen(false)}>閉じる</li>
                                </ul>
                            </div>
                            :
                            <></>
                            }
                        </div>
                    </div>
                    :
                    <></>
                    }
                    {
                    isLogin ?
                    <div className={isClicked ? `${headerStyles.config} ${headerStyles.rotate}` : headerStyles.config} onClick={() => {
                        setIsClicked(true);
                        setTimeout(() => setIsClicked(false), 200);
                        setConfigOpen(true);}}>
                        <FontAwesomeIcon icon={faCog} />
                    </div>
                    :
                    <></> 
                    }
                </div>
            </div>
        </header>
    );
};

export default Header;