export const getDiffDays = (date) => {
    const currentDate = new Date();
    /*日時の差をミリ秒単位で取得*/
    const diffMilliSec = currentDate - date;
    /*ミリ秒を日数に変換*/
    const diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);
    return diffDays;
}

export const isURL = (str) => {
    if(str.match(/https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g)){
        return true;
    }
    return false;
}