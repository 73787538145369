import { useLayoutEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig';
import Loading from "../common/loading";
import noPhoto from "../img/no_photo.svg";
import articleStyles from './article_styles.module.css';

const Image = ({fileName, imageType}) => {

    const [content, setContent] = useState('');
    const [isError, setIsError] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useLayoutEffect(()=>{
        const getImageAsBase64 = httpsCallable(functions, 'getImageAsBase64');

        getImageAsBase64({
            file_name: fileName,
            image_type: imageType
            }).then((result) => {
                if (result.data.status != 200){
                    setContent('');
                    setIsError(true);
                    return;
                }
                setContent(result.data.image_base64);
                setIsError(false);
                return;
            }).catch((error) => {
                setContent('');
                setIsError(true);
                return;
            });
    }, []);

    return (
        content ?
            <>
                <img className={articleStyles.img} src={content.startsWith('data:image') ? content : `data:image/jpeg;base64,${content}`} onClick={() => setShowModal(true)}></img>
                {showModal && 
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setShowModal(false)}>
                        <img src={content.startsWith('data:image') ? content : `data:image/jpeg;base64,${content}`} style={{ maxWidth: '110%', maxHeight: '110%' }}></img>
                    </div>
                }
            </>        
        :
        isError ?
        <img src={noPhoto}></img>
        :
        <Loading/>
    );
}

export default Image;