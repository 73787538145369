import commonStyles from '../common/common_styles.module.css';
import SideBar from '../common/sidebar';
import termsStyles from './terms_styles.module.css';
import { Helmet } from 'react-helmet-async';


const Terms = () => {

    return (
        <>
        <Helmet>
            <title>利用規約 - AI TechBase</title>
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
            <div className={commonStyles.sectionWrapper}>
                <section className={`${commonStyles.primarySection} ${termsStyles.termsWrapper}`}>
                    <h1 className={commonStyles.primaryH1}>利用規約</h1>
                    <p>この利用規約（以下、「本規約」といいます。）は、AI TechBase運営（以下、「当運営」といいます。）が
                        当ウェブサイト（ai-techbase.com）で提供するサービス「AI TechBase」（以下、本サービス）の提供条件と、
                        本サービスを利用するユーザーに遵守していただくべき事項、当運営とユーザーとの間の権利義務関係が定められています。
                        本サービスをユーザーとして利用するには、本規約の全文をお読みいただいたうえで、本規約に同意していただく必要があります。</p>
                    <h2 id='term1' className={commonStyles.primaryH2}>第１条（適用）</h2>
                    <p className={commonStyles.primaryP}>１．本規約は、ユーザーと当運営との間の本サービスの利用に関わる全ての関係に適用されるものとします。</p>
                    <p className={commonStyles.primaryP}>２．当運営は本サービスに関し、本規約のほか、ご利用にあたってルール等各種の定め（以下、「個別規定」）をすることがあります。
                    これらの個別規定はその名称の如何に関わらず、本規約の一部を構成するものとします。</p>
                    <p className={commonStyles.primaryP}>３．本規約の規定が前項の個別規定と矛盾する場合には、個別規定において特段の定めなき限り、本規約の規定が優先して適用されるものとします。</p>
                    <h2 id='term2' className={commonStyles.primaryH2}>第２条（本規約の改訂・変更）</h2>
                    <p className={commonStyles.primaryP}>１．当運営は、当運営の判断において、いつでも本規約の内容を変更または追加できるものとします。
                    変更後の利用規約は、当運営が別途定める場合を除いて当運営ウェブサイトまたは本サービスの利用画面に表示された時点より効力を生じるものとします。</p>
                    <h2 id='term3' className={commonStyles.primaryH2}>第３条（定義）</h2>
                    <p className={commonStyles.primaryP}>本規約において、次の各号に掲げる用語の意義は当該各号に定めるところによるものとします。</p>
                    <p className={commonStyles.primaryP}>（１）「本規約」とは、本サービスの提供条件と本サービスの利用に関する当運営とユーザーとの間の権利関係、本サービスを利用するユーザーに遵守していただくべき事項を定めた規定を意味します。</p>
                    <p className={commonStyles.primaryP}>（２）「当運営」とは、AI TechBase運営を意味します。</p>
                    <p className={commonStyles.primaryP}>（３）「当ウェブサイト」とは、そのドメインが（ai-techbase.com）である、当運営が運営するウェブサイト（理由の如何を問わず、当運営のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</p>
                    <p className={commonStyles.primaryP}>（４）「本サービス」とは、当運営が提供するAI TechBaseという名称のサービス（理由の如何を問わずサービスの名称が変更された場合は、当該変更後のサービスを含む）を意味します。</p>
                    <p className={commonStyles.primaryP}>（５）「ユーザー」とは、当ウェブサイトにアクセスし、本サービスを利用する利用者様を意味します。</p>
                    <p className={commonStyles.primaryP}>（６）「登録情報」とは、本サービスの一部機能の利用を希望する人が、当運営に提出する情報を意味します。</p>
                    <p className={commonStyles.primaryP}>（７）「ユーザー登録」とは、本サービスの一部機能の利用を希望する人が、その一部機能を利用するために行うアカウント作成を意味します。</p>
                    <p className={commonStyles.primaryP}>（８）「登録ユーザー」とは、ユーザー登録を行なったユーザーを意味します。</p>
                    <h2 id='term4' className={commonStyles.primaryH2}>第４条（ユーザー登録）</h2>
                    <p className={commonStyles.primaryP}>１．登録ユーザーとして本サービスの利用を希望する個人（以下、「ユーザー登録希望者」）は、当運営の定める方法に従い、情報を当運営に提供することにより、当運営に対し、ユーザー登録を申請することができます。</p>
                    <p className={commonStyles.primaryP}>２．当運営は、当運営の基準にしたがって、第１項に基づいて登録の申請を行なったユーザーの登録の可否を判断し、当運営が登録を認める場合にはその旨を通知します。ユーザー登録希望者としての登録は、当運営が本項の通知を行なったことを持って完了したものとします。</p>
                    <p className={commonStyles.primaryP}>３．当運営はユーザー登録希望者が前項に掲げる場合のほか、以下の各号のいずれかの事由に該当する場合には、本サービスの利用を拒否することがあります。拒否をする場合には、その理由について当運営は、一切の開示義務を負いません。</p>
                    <p className={commonStyles.primaryP}>（１）当運営に提供された情報の全部または一部につき、虚偽、誤りまたは記載漏れがあった場合</p>
                    <p className={commonStyles.primaryP}>（２）未成年者、成年被後見人、被保佐人または非補助人のいずれかであって、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</p>
                    <p className={commonStyles.primaryP}>（３）ユーザー登録希望者が過去に当運営との契約に違反した者、またはその関係者であると当運営が判断した場合</p>
                    <p className={commonStyles.primaryP}>（4）その他、等事務局が申請を適当でないと判断した場合</p>
                    <h2 id='term5' className={commonStyles.primaryH2}>第５条（ユーザーに関する情報の取り扱い）</h2>
                    <p className={commonStyles.primaryP}>１．ユーザーは、当サービスの利用に際して、自己に関する情報そのほか当サービスの利用にあたって当事運営が求める情報を送信する場合には、真実かつ正確な情報を提供しなければなりません。</p>
                    <p className={commonStyles.primaryP}>２．登録情報に誤りがあった場合または変更が生じた場合、ユーザーは当運営の定める方法により変更となった事項を遅滞なく当運営に通知するものとします。この通知を怠った場合、本サービスの利用が停止される場合があることをユーザーは理解しているものとします。</p>
                    <p className={commonStyles.primaryP}>３．当運営は、登録情報、そのほか本サービスの利用に関しユーザーから収集する情報（以下、個人情報）を以下の各号に従って取り扱います。</p>
                    <p className={commonStyles.primaryP}>（１）登録情報、個人情報は適正かつ適法な方法で取得します。</p>
                    <p className={commonStyles.primaryP}>（２）登録情報、個人情報は個人を特定できない統計的な情報としての利用、公開を除き、本人の同意なく第三者に提供しません。</p>
                    <p className={commonStyles.primaryP}>（３）個人情報の管理にあたっては、漏洩・滅失、毀損の防止及び是正、そのほかの安全管理のために必要かつ適切な措置を講じるよう努めます。</p>
                    <h2 id='term6' className={commonStyles.primaryH2}>第６条（アカウントの管理）</h2>
                    <p className={commonStyles.primaryP}>１．ユーザーは、自己の責任において本サービスのアカウントを管理・保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。</p>
                    <p className={commonStyles.primaryP}>２．アカウントの管理不十分または、第三者使用等による損害の責任はユーザーが負うものとし、当運営は一切の責任を負いません。</p>
                    <p className={commonStyles.primaryP}>３．ユーザーは、アカウントが盗用されまたは第三者に使用されていることが判明した場合には、直ちにその旨を当運営に通知するとともに、当運営からの指示に従うものとします。</p>
                    <h2 id='term7' className={commonStyles.primaryH2}>第７条（未成年者による利用）</h2>
                    <p className={commonStyles.primaryP}>未成年者は、ユーザー登録を含む本サービスの利用に関する一切の行為につき、親権者等の法定代理人の同意を得た上でこれを行うものとします。本サービスの利用を行なった時点で、本規約の内容について、法定代理人の同意があったものとみなします。</p>
                    <p className={commonStyles.primaryP}>未成年者のユーザーが、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、そのほか行為能力者であることを信じさせるために詐術を用いた場合、当該利用に関する一切の法律行為を取り消すことはできません。</p>
                    <p className={commonStyles.primaryP}>本規約の同意時に未成年であったユーザーが成年に達したあとに本サービスを利用した場合、当該ユーザーは、本サービスに関する一切の法律行為を追認したものとみなされます。</p>
                    <h2 id='term8' className={commonStyles.primaryH2}>第８条（提供するサービス）</h2>
                    <p className={commonStyles.primaryP}>１．当運営は、本サービスにおいて、AI関連のアプリ・拡張機能等紹介記事の投稿・編集・閲覧を行えるサービスを提供します。また、口コミ・フォーラム・使用例などユーザー同士のコミュニティ形成を補助する機能も提供します。</p>
                    <h2 id='term9' className={commonStyles.primaryH2}>第９条（権利の帰属）</h2>
                    <p className={commonStyles.primaryP}>１．ユーザーは、紹介記事で公開したデータ（文章、画像など）について、当運営は、その著作物に関する全ての権利（著作権法第２７条及び第２８条に定める権利を含みます）を譲渡していただいたものとします。なお譲渡についての対価は無償とさせて頂きますのでご了承ください。</p>
                    <p className={commonStyles.primaryP}>２．ユーザーが口コミ、使用例、フォーラムで投稿したデータの著作権は当該ユーザーに帰属しますが、当運営はそれらデータ制限なく利用すること、および利用にあたり内容の真実性を損なわない範囲で編集・加工等を行うことを許諾して頂いたものとします。なお利用についての対価は無償とさせて頂きますのでご了承ください。</p>
                    <p className={commonStyles.primaryP}>３．ユーザーは、紹介記事を本サービス上に限り、投稿・編集できます。</p>
                    <h2 id='term10' className={commonStyles.primaryH2}>第１０条（禁止行為）</h2>
                    <p className={commonStyles.primaryP}>１．ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。</p>
                    <p className={commonStyles.primaryP}>（１）本サービスの誤作動を誘引する行為</p>
                    <p className={commonStyles.primaryP}>（２）本サービスおよびそのほか当運営が提供するサービスの運営を妨げる行為、または当運営の信用・名誉等を毀損する行為</p>
                    <p className={commonStyles.primaryP}>（３）本サービスアカウントを第三者に利用させ、または貸与、譲渡、名義変更、売買等の行為</p>
                    <p className={commonStyles.primaryP}>（４）反社会的勢力等への利益供与行為およびこれにつながる可能性のある行為</p>
                    <p className={commonStyles.primaryP}>（５）故意に虚偽のデータ等を当運営に送信する行為</p>
                    <p className={commonStyles.primaryP}>（６）本規約および本サービスの主旨・目的に反する行為</p>
                    <p className={commonStyles.primaryP}>（７）そのほか、当運営が不適切と判断する行為</p>
                    <h2 id='term11' className={commonStyles.primaryH2}>第１１条（利用制限及び登録解除）</h2>
                    <p className={commonStyles.primaryP}>１．当運営は、ユーザーが以下の各号いずれかの事由に該当し、または該当する恐れがあると当運営が判断した場合には当運営の裁量によりユーザーに事前に通知することなく、当該ユーザーについて本サービスの利用を一時停止もしくは制限、ユーザー登録の抹消することが出来るものとします。</p>
                    <p className={commonStyles.primaryP}>（１）本規約のいずれかの条項に違反した場合</p>
                    <p className={commonStyles.primaryP}>（２）当運営からの問い合わせそのほかの解答を求める連絡に対してに対して20日以上応答がない場合</p>
                    <p className={commonStyles.primaryP}>（３）そのほか、当運営が本サービスの利用、ユーザーの登録、またはサービス利用契約の継続を適当でないと判断した場合</p>
                    <p className={commonStyles.primaryP}>２．当運営は、本条に基づき当運営が行った行為によりユーザーに生じた損害について一切の責任を負いません。</p>
                    <h2 id='term12' className={commonStyles.primaryH2}>第１２条（退会）</h2>
                    <p className={commonStyles.primaryP}>１．ユーザーは、当運営の定める方法により、いつでもユーザー登録を抹消することが出来ます。</p>
                    <p className={commonStyles.primaryP}>２．当運営は、ユーザーが本サービスを退会した後も当該ユーザーに関し当運営が取得した情報を本規約に基づいて利用できるものとします。</p>
                    <p className={commonStyles.primaryP}>３．当運営は、当運営の裁量によりあらかじめ通知することなく、最終のアクセスから半年以上経過しているアカウントを削除できるものとします。</p>
                    <p className={commonStyles.primaryP}></p>
                    <p className={commonStyles.primaryP}></p>
                    <h2 id='term13' className={commonStyles.primaryH2}>第１３条（本サービスの変更・中断・終了等）</h2>
                    <p className={commonStyles.primaryP}>１．当運営は、ユーザーに事前に通知することなく、本サービスの内容の全部または一部を変更または追加できるものとします。</p>
                    <p className={commonStyles.primaryP}>２．当運営は、事前に、本サービス上への掲示そのほか当運営が適当と判断する方法でユーザーに通知することにより当運営の裁量で、本サービスを終了することが出来るものとします。ただし、緊急の場合はユーザーへの通知を行わない場合があります。</p>
                    <p className={commonStyles.primaryP}>３．当運営は以下の各号の事由が生じた場合には、ユーザーに事前に通知することなく、本サービスの一部または全部を一時的に中断することが出来るものとします。</p>
                    <p className={commonStyles.primaryP}>（１）本サービス用の通信機器設備等に関わるメンテナンスや修理を定期的または緊急に行う場合</p>
                    <p className={commonStyles.primaryP}>（２）アクセス集中、そのほか予期せぬ要因でシステムに負荷が集中した場合</p>
                    <p className={commonStyles.primaryP}>（３）ユーザーのセキュリティを確保する必要が生じた場合</p>
                    <p className={commonStyles.primaryP}>（４）天災等の不可抗力により本サービスの提供が困難な場合</p>
                    <p className={commonStyles.primaryP}>（５）火災、停電、そのほかの不慮の事故または戦争、紛争、動乱、暴動等により本サービスの提供が困難な場合</p>
                    <p className={commonStyles.primaryP}>（６）法令またはこれらに基づく措置により本サービスの運営が不能となった場合</p>
                    <p className={commonStyles.primaryP}>（７）そのほか前各号に準じ当運営が必要と判断した場合</p>
                    <p className={commonStyles.primaryP}>４．当運営は、本条に基づき当運営が行った措置によりザーに生じた損害について一切の責任を負いません。</p>
                    <h2 id='term14' className={commonStyles.primaryH2}>第１４条（保証の否認及び免責）</h2>
                    <p className={commonStyles.primaryP}>１．当運営は、本サービスを通じて提供されるコンテンツそのほか一切の情報につき、ユーザーの特定の目的への適合性、商品的価値、正確性、有用性、完全性、適法性、ユーザーに適用のある団体の内部基礎高騰への適合性を有すること、およびセキュリティ上の欠陥、エラー、バグまたは不具合が存在しないこと、並びに第三者の権利を侵害しないことについて如何なる保証も行うものではありません。</p>
                    <p className={commonStyles.primaryP}>２．当運営の責めに帰すべき事由によってユーザーに損害が生じた場合であっても、当運営が負う賠償責任の範囲は、当運営に故意または重過失が存する場合を除いて、金一万円を上限とします。</p>
                    <p className={commonStyles.primaryP}>３．ユーザーが本サービスを利用することにより、ユーザーまたは第三者に対し、不利益・損害を与えた場合、ユーザーは自己の費用と責任においてこれを賠償するものとし、これらの一切の不利益・損害について当運営は一切責任を負わないものとします。</p>
                    <h2 id='term15' className={commonStyles.primaryH2}>第１５条（連絡方法）</h2>
                    <p className={commonStyles.primaryP}>１．本サービスに関する当運営からユーザーへの連絡は、当ウェブサイト内の適宜の場所への掲示、電子メールの送信等、当運営が適当と判断する方法により行うものとします。</p>
                    <p className={commonStyles.primaryP}>２．当運営が電子メールの送信による通知を行った場合、当運営からの通知は、ユーザーが登録したメールアドレスにメールを送信することをもって、当該メールが通常到達すべきときに到着したものとみなします。</p>
                    <p className={commonStyles.primaryP}>３．本サービスに関する問い合わせ、そのほかユーザーから当運営に対する連絡または通知は、当ウェブサイト内の適宜の場所に設置するお問い合わせフォームへの送信、そのほか当運営が指定する方法により行うものとします。</p>
                    <h2 id='term16' className={commonStyles.primaryH2}>第１６条（権利義務の譲渡禁止）</h2>
                    <p className={commonStyles.primaryP}>１．ユーザーは、当運営の書面による事前の承諾がある場合を除き、本規約に基づくユーザーの権利もしくは義務、または本利用契約上の地位について、第三者への譲渡、承継、担保設定、そのほか一切の処分をすることはできません。</p>
                    <p className={commonStyles.primaryP}>２．当運営は、本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約基づく権利および義務並びにユーザーの登録事項そのほかの顧客情報を当該事業譲渡の譲受人に譲渡することが出来るものとし、ユーザーは、あらかじめこれに同意するものとします。</p>
                    <h2 id='term17' className={commonStyles.primaryH2}>第１７条（分離可能性）</h2>
                    <p className={commonStyles.primaryP}>１．本規約のいずれかの条項またはその一部が、消費者契約法そのほかの法令等により無効または失効不能と判断された場合であっても、当該無効または執行不能と判断された条項または部分（以下、「無効等部分」）以外の部分は継続して完全に効力を有するものとします。当運営及びユーザーは、無効等部分を適法とし、執行力を持たせるために必要な範囲で修正し、無効等部分の主旨および法律的・経済的に同等の効果を確保できるように努めるものとします。本規約のいずれかの条項またはその一部が、あるユーザーとの関係で無効または執行不能と判断された場合であっても、ほかのユーザーとの関係における有効性等には影響を及ぼさないものとします。</p>
                    <p className={commonStyles.primaryP}>２．本規約のいずれかの条項またはその一部が、あるユーザーとの関係で無効または執行不能と判断された場合であっても、ほかのユーザーとの関係における有効性等には影響を及ぼさないものとします。</p>
                    <h2 id='term18' className={commonStyles.primaryH2}>第１８条（準拠法）</h2>
                    <p className={commonStyles.primaryP}>本規約は日本法に準拠するものとします。</p>
                    <p className={commonStyles.primaryP}>2023/07/08</p>
                </section>
            </div>
            <SideBar articleNav={true} articleNavList={[{key:'term1', label:'第１条（適用）', list:[]}, {key:'term2', label:'第２条（本規約の改訂・変更）', list:[]}, {key:'term3', label:'第３条（定義）', list:[]}, {key:'term4', label:'第４条（ユーザー登録）', list:[]}, {key:'term5', label:'第５条（ユーザーに関する情報の取り扱い）', list:[]}, {key:'term6', label:'第６条（アカウントの管理）', list:[]}, {key:'term7', label:'第７条（未成年者による利用）', list:[]}, {key:'term8', label:'第８条（提供するサービス）', list:[]}, {key:'term9', label:'第９条（権利の帰属）', list:[]}, {key:'term10', label:'第１０条（禁止行為）', list:[]}, {key:'term11', label:'第１１条（利用制限及び登録解除）', list:[]}, {key:'term12', label:'第１２条（退会）', list:[]}, {key:'term13', label:'第１３条（本サービスの変更・中断・終了等）', list:[]}, {key:'term14', label:'第１４条（保証の否認及び免責）', list:[]}, {key:'term15', label:'第１５条（連絡方法）', list:[]}, {key:'term16', label:'第１６条（権利義務の譲渡禁止）', list:[]}, {key:'term17', label:'第１７条（分離可能性）', list:[]}, {key:'term18', label:'第１８条（準拠法）', list:[]},]} last={'articleNav'}/>
        </main>
        </>
    );
};

export default Terms;