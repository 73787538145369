import React, { useLayoutEffect, useState } from 'react';
import styles from "./styles.module.css";
import { Link } from 'react-router-dom';
import Loading from '../common/loading';
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig'; 
import ErrorMessage from '../common/errorMessage';


const SubContent = () => {
    const [trendKeywords, setTrendKeywords] = useState([]);
    const [isLoadedTrendKeywords, setIsLoadedTrendKeywords] = useState(false);
    const [isErrorTrendKeywords, setIsErrorTrendKeyword] = useState(false);
    const [popularServices, setPopularServices] = useState([]);
    const [isLoadedPopularServices, setIsLoadedPopularServices] = useState(false);
    const [isErrorPopularServices, setIsErrorPopularServices] = useState(false);

    useLayoutEffect(()=>{
        const getKeywordRanking = httpsCallable(functions, 'getKeywordRanking');
        getKeywordRanking({
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    setIsErrorTrendKeyword(true);
                    return;
                }
                setTrendKeywords(data.ranking);
                setIsLoadedTrendKeywords(true);
            }).catch((error) => {
                setIsErrorTrendKeyword(true);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });

        const getServices = httpsCallable(functions, 'getServices');
        getServices({
                limit: 10,
                modifier: 'desc',
                order_by: "good_count"
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    setIsErrorPopularServices(true);
                    return;
                }
                setPopularServices(data.services)
                setIsLoadedPopularServices(true);
            }).catch((error) => {
                setIsErrorPopularServices(true);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    },[]);

    return (
        <div className={styles.trendsContainer}>
            <div>
                <p className={styles.trendListTitle}>人気のサービス</p>
                {
                    isLoadedPopularServices && !isErrorPopularServices ?
                    <ul className={styles.trendList}>
                        {popularServices.map((service, index) => (
                            <li key={index}>
                                <span className={styles.trendIndex}>{index+1}</span>
                                <Link className={styles.trendLink} to={`services/${service.service_name}`} onClick={()=>window.scrollTo({top: 0})}>{service.service_name}</Link>
                            </li>
                        ))}
                    </ul>
                    :
                    <></>
                }
                {
                    !isLoadedPopularServices && !isErrorPopularServices ?
                    <Loading/>
                    :
                    <></>
                }
                {
                    isErrorPopularServices ?
                    <ErrorMessage message='読み込み中に問題が発生しました。'/>
                    :
                    <></>
                }
            </div>
            <div>
                <p className={styles.trendListTitle}>Trend Keywords</p>
                {
                    isLoadedTrendKeywords && !isErrorTrendKeywords ?
                    <ul className={styles.trendList}>
                        {trendKeywords.map((item, index) => (
                            <li key={index}>
                                <span className={styles.trendIndex}>{index+1}</span>
                                <Link className={styles.trendLink} to={`search?keyword=${item.keyword}&page=1`} onClick={()=>window.scrollTo({top: 0})}>{item.keyword}</Link>
                            </li>
                        ))}
                    </ul>
                    :
                    <></>
                }
                {
                    !isLoadedTrendKeywords && !isErrorTrendKeywords ?
                    <Loading/>
                    :
                    <></>
                }
                {
                    isErrorTrendKeywords ?
                    <ErrorMessage message='読み込み中に問題が発生しました。'/>
                    :
                    <></>
                }
            </div>
        </div>
    );
};

export default SubContent;