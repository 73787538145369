import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import 'firebase/compat/functions';
//import Search from './search';
//import ChatBot from './chat';
import commonStyles from '../common/common_styles.module.css'
import styles from './styles.module.css';
import FirstSubContent from './firstSubContent'; 
import { Link } from 'react-router-dom';
import SideBar from '../common/sidebar';
import { httpsCallable } from "firebase/functions";
import { functions, auth } from '../database/firebaseConfig'; 
import { Helmet } from 'react-helmet-async';
import ServiceCards from '../common/servicesCards';
import Loading from '../common/loading';
import ErrorMessage from '../common/errorMessage';

const First = () => {

    const [servicesOrderByViewCount, setServicesOrderByViewCount] = useState([]);
    const [isLoadedServicesOrderByViewCount, setIsLoadedServicesOrderByViewCount] = useState(false);
    const [isErrorOnLoadServicesOrderByViewCount, setIsErrorLoadServicesOrderByViewCount] = useState(false);
    const [keyword, setKeyword] = useState('');

    useLayoutEffect(()=>{
        const getServices = httpsCallable(functions, 'getServices');
        getServices({
                limit: 10,
                modifier: 'desc',
                order_by: 'view_count'
            }).then((result) => {
                
                const data = result.data;
                if (data.status != 200){
                    setIsErrorLoadServicesOrderByViewCount(true);
                    return;
                }
                setServicesOrderByViewCount(data.services);
                setIsLoadedServicesOrderByViewCount(true);
            }).catch((error) => {
                setIsErrorLoadServicesOrderByViewCount(true);
                console.error(error);
            });
    },[]);

    return (
        <>
        <Helmet>
            <title>AI TechBase</title>
            <meta
            name="description"
            content="「誰もが、自分の要望に沿ったAIツールを見つけ、実際に扱えるようになる」サービスです。便利なAIツールを取りまとめ、紹介しています。誰でもAIツールを追加可能です。"
            />
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
            <SideBar twitterNav={true} updateNav={true} tagNav={true} last={'tagNav'}/>
            <div className={commonStyles.sectionWrapper}>
                <section className={`${commonStyles.primarySection} ${styles.whatIsTechBaseWrapper}`}>
                    <p className={styles.whatIsTechBaseHeader}>AI TechBaseとは？</p>
                    <p className={styles.whatIsTechBase}>便利なAIツールを取りまとめ、紹介しています。<br/>検索やレビューにより、簡単にツールを探すことができます。<br/>またユーザーがツールの投稿・編集を行えるよう、wikiシステムを導入しています。<br/>Let's AI TechBase.🔥</p>
                    <Link to='product' className={`${commonStyles.primaryButton} ${styles.whatIsTechBaseButton}`} onClick={()=>window.scrollTo({top: 0})}>AI TechBaseをもっと知る</Link>
                </section>
                <section className={commonStyles.secondarySection}>  
                    <div className={styles.searchForm}>
                        <input className={commonStyles.primaryInput} type="text" placeholder="キーワードを入力" value={keyword} onChange={(e)=>setKeyword(e.currentTarget.value)}/>
                        <Link className={`${commonStyles.primaryButton} ${styles.chatButton}`} to={`/search?keyword=${keyword}&page=1`} onClick={()=>window.scrollTo({top: 0})}>検索</Link>
                    </div>
                </section>
                <section className={commonStyles.secondarySection}>
                    <h2 className={`${commonStyles.fontSizeHeavy} ${commonStyles.marginBottomMiddle}`}>注目のサービス</h2>
                    {
                        isLoadedServicesOrderByViewCount && !isErrorOnLoadServicesOrderByViewCount ?
                        <>
                            <ServiceCards services={servicesOrderByViewCount}/>
                            <Link className={`${commonStyles.primaryButton} ${commonStyles.marginTopMiddle}`} to='/all' onClick={()=>window.scrollTo({top: 0})}>もっと見る</Link>
                        </>
                        :
                        <></>
                    }
                    {
                        isErrorOnLoadServicesOrderByViewCount ?
                        <ErrorMessage message='読み込み中に問題が発生しました。'/>
                        :
                        <></>
                    }
                    {
                        !isLoadedServicesOrderByViewCount ?
                        <Loading/>
                        :
                        <></>
                    }
                </section>
                <section className={commonStyles.secondarySection}>
                    <FirstSubContent />
                </section>
            </div>
            <SideBar userViewRankingNav={true} last={'userViewRankingNav'}/>
        </main> 
        </>
    );
}

export default First;