import { useState, useLayoutEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig';
import Loading from '../common/loading';
import UseCasesCards from "../common/usecasesCards";
import commonStyles from '../common/common_styles.module.css';
import styles from './styles.module.css';

const UsecaseExample = () => {
    const [postedUsecases, setPostedUsecases] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [lastUsecaseId, setLastUsecaseId] = useState("");
    const [hasMore, setHasMore] = useState(true);

    const loadMoreUsecases = () => {
        const getPostedUsecases = httpsCallable(functions, 'getPostedUsecases');
            getPostedUsecases({
                limit: 10,
                posted_usecase_document_id_start_after: lastUsecaseId
            }).then((result) => {
                const dataUsecases = result.data;
                //console.log(dataUsecases)
                if (dataUsecases.status != 200){
                    return;
                }
                if (dataUsecases.usecases.length < 10) {
                    setHasMore(false);
                }
                setLastUsecaseId(dataUsecases.usecases[dataUsecases.usecases.length - 1].usecase_document_id);
            
                const getDetailsFromName = httpsCallable(functions, 'getDetailsFromName');
            
                const serviceInfoPromises = [];
            
                dataUsecases.usecases.forEach(usecase => {
                    usecase.service_names.forEach(serviceName => {
                        const promise = getDetailsFromName({
                            name: serviceName,
                            language: "jpn",
                        }).then((result) => {
                            if (result.data.status != 200){
                                return null;
                            }
                            return { 
                                usecase_id: usecase.usecase_id,
                                service_name: serviceName, 
                                thumbnail_base64: result.data.thumbnail_base64 
                            };
                        }).catch((error) => {
                            if (error.response) {
                                console.log('Error response data:', error.response.data);
                                console.log('Error response status:', error.response.status);
                                console.log('Error response headers:', error.response.headers);
                            } else if (error.request) {
                                console.log('Error request:', error.request);
                            } else {
                                console.log('Error', error.message);
                            }
                        });
                        serviceInfoPromises.push(promise);
                    });
                });
            
                Promise.all(serviceInfoPromises).then((serviceInfos) => {
                    const sendUsecases = dataUsecases.usecases.map((usecase) => {
                        const relevantServiceInfos = serviceInfos.filter(info => usecase.usecase_id === info.usecase_id);
                        return {
                            service_info: relevantServiceInfos,
                            usecase_id: usecase.usecase_id,
                            contents: usecase.contents,
                            usecase_good: usecase.good_count,
                            usecase_view: usecase.view_count,
                            posted_at: usecase.posted_at,
                            tags: usecase.tags,
                            title: usecase.title
                        }
                    });
                    //console.log(sendUsecases)
                    setPostedUsecases(prev => [...prev, ...sendUsecases]);
                    setIsLoaded(true); 
                });
            }).catch((error) => {
                setIsLoaded(true); 
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {                  
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }

    useLayoutEffect(()=>{
        loadMoreUsecases();
    }, []);

    return (
        <>
            {isLoaded ? (
                <>
                    <UseCasesCards sendUsecases={postedUsecases} isEdit={true}/>
                    {hasMore && <button onClick={loadMoreUsecases} className={`${commonStyles.primaryButton} ${styles.moreButton}`}>もっと見る</button>}
                </>
            ) : (
                <Loading/>
            )}
        </>
    );
}

export default UsecaseExample;