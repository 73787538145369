import React, { useLayoutEffect, useRef, useState } from 'react'; 
import commonStyles from '../common/common_styles.module.css';
import pStyles from './p_styles.module.css';
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';

/* Pは段落の意味 */
const P = ({handleChangeTextAreaAsP, handleChangeSelectTypeOfP, handleChangeTable, handleChangeTableType, id, handleChangeInputFile, handleClickMoveButton, handleClickDeleteButton, handleClickAddPButton, p, setSubmissionMessage}) => {

    //header, paragraph用
    const textAreaAsP = useRef();
    const selectTypeOfP = useRef();

    //image用
    const inputFile = useRef();

    //table用
    const { show, hideAll } = useContextMenu();
    const [rows, setRows] = useState(0);
    const [columns, setColumns] = useState(0);

    const MAX_LENGTH_DOCUMENT_HEADLINE = 64;
    const MAX_LENGTH_DOCUMENT_SMALL_HEADLINE = 64;
    const MAX_LENGTH_DOCUMENT_PARAGRAPH = 256;

    const MAX_ROW = 64;
    const MAX_COLUMN = 12;

    useLayoutEffect(()=>{
        switch(p.content_type){
            case 'image':
                break;
            case 'table':
                break;
            default:
                adjustTextAreaHeight(textAreaAsP.current);
                break;
        }
    }, []);

    const adjustTextAreaHeight = (textarea) => {
        textarea.style.height = '';
        textarea.style.height = textarea.scrollHeight+'px';
    }

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    }

    //table用関数
    const addRow = (index, direction) => {
        if(rows >= MAX_ROW){
            return;
        }
        const table = JSON.parse(JSON.stringify(p.content));
        const newRow = [];
        for(let i = 0; i < table[0].length; i++){
            newRow[i] = '';
        }
        switch(direction){
            case 'above':
                handleChangeTable([...table.slice(0, index), newRow, ...table.slice(index)]);
                break;
            case 'bottom':
                handleChangeTable([...table.slice(0, index+1), newRow, ...table.slice(index+1)]);
                break;
        }
    }

    const addColumn = (index, direction) => {
        if(columns >= MAX_COLUMN){
            return;
        }
        const table = JSON.parse(JSON.stringify(p.content));
        switch(direction){
            case 'right':
                for(let i = 0; i < table.length; i++){
                    table[i] = [...table[i].slice(0, index+1), '', ...table[i].slice(index+1)];
                }
                break;
            case 'left':
                for(let i = 0; i < table.length; i++){
                    table[i] = [...table[i].slice(0, index), '', ...table[i].slice(index)];
                }
                break;
        }
        handleChangeTable(table);
    }

    const removeRow = (index) => {
        const table = JSON.parse(JSON.stringify(p.content));
        if(table.length < 2){
            return;
        }
        handleChangeTable([...table.slice(0, index), ...table.slice(index+1)]);
    }

    const removeColumn = (index) => {
        const table = JSON.parse(JSON.stringify(p.content));
        if(table[0].length < 2){
            return;
        }
        for(let i = 0; i < table.length; i++){
            table[i] = [...table[i].slice(0, index), ...table[i].slice(index+1)];
        }
        handleChangeTable(table);
    }

    const addNewTable = () => {
        const table = [];
        if(rows < 1 || columns < 1){
            return;
        }
        for(let row = 0; row < rows; row++){
            table[row] = [];
            for(let column = 0; column < columns; column++){
                table[row][column] = '';
            }
        }
        handleChangeTable(table);
    }

    const handleChangeTableContent = (row, column, value) => {
        const table = JSON.parse(JSON.stringify(p.content));
        table[row][column] = value;
        handleChangeTable(table);
    }

    const handleChangeContextMenuTable = (event, id, row, column) => {
        show({
            id: id,
            event: event,
            props: {
                row: row,
                column: column
            }
        });
    }

    const handleItemClick = ({ id, event, props }) => {
        switch (id) {
            case 'add_row_above':
                addRow(props.row, 'above');
                break;
            case 'add_row_bottom':
                addRow(props.row, 'bottom');
                break;
            case 'add_column_left':
                addColumn(props.column, 'left');
                break;
            case 'add_column_right':
                addColumn(props.column, 'right');
                break;
            case 'remove_row':
                removeRow(props.row);
                break;
            case 'remove_column':
                removeColumn(props.column);
                break;
        }
    }

    return (
        <>
        <div className={pStyles.pWrapper}>
            {
                (() => {switch(p.content_type){
                    case 'image':
                        return (<div className={pStyles.pInner}>
                                {
                                    !p.content && p.file_name ?
                                    <p className={pStyles.errorOnLoadImageText}>申し訳ございません。画像を読み込めませんでした。<br/>このままでもご編集いただけますが、リロードをお試しください。</p>
                                    :
                                    <>
                                        <img className={pStyles.img} src={p.content ? (p.content.startsWith('data:image') ? p.content : `data:image/jpg;base64,${p.content}`) : ''} onClick={() => handleImageClick(p.content)} />
                                        <button type="button" className={`${commonStyles.whiteButton} ${pStyles.buttonInput}`} onClick={() => inputFile.current.click()}>
                                            ファイルを選択
                                        </button>
                                        <input type='file' accept='image/png, image/jpeg' ref={inputFile} style={{ display: 'none' }} onChange={(e) => {setSubmissionMessage(''); handleChangeInputFile(e)}}/>
                                    </>
                                }
                            </div>);
                    case 'table':
                        return (<div className={pStyles.pInner}>
                                {
                                    p.content && p.table_type=='no_th' ?
                                    <div className={commonStyles.primaryTableWrapper}>
                                        <table className={`${commonStyles.primaryTable} ${pStyles.table}`}>
                                            <tbody>
                                                {
                                                    p.content.map((row, i)=>{
                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                            {
                                                                row.map((column, j)=>{
                                                                    return <td key={j} className={commonStyles.primaryTd} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></td>
                                                                })
                                                            }
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    p.content && p.table_type=='row_th' ?
                                    <div className={commonStyles.primaryTableWrapper}>
                                        <table className={`${commonStyles.primaryTable} ${pStyles.table}`}>
                                            <tbody>
                                                {
                                                    p.content.map((row, i)=>{
                                                        if(i == 0){
                                                            return <tr key={i} className={`${commonStyles.primaryTrHeader} ${pStyles.trHeader}`}>
                                                                {
                                                                    row.map((column, j)=>{
                                                                        return <th key={j} className={commonStyles.primaryTh} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></th>
                                                                    })
                                                                }
                                                            </tr>
                                                        } else {
                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                {
                                                                    row.map((column, j)=>{
                                                                        return <td key={j} className={commonStyles.primaryTd} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></td>
                                                                    })
                                                                }
                                                            </tr>
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    p.content && p.table_type=='col_th' ?
                                    <div className={commonStyles.primaryTableWrapper}>
                                        <table className={`${commonStyles.primaryTable} ${pStyles.table}`}>
                                            <tbody>
                                                {
                                                    p.content.map((row, i)=>{
                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                            {
                                                                row.map((column, j)=>{
                                                                    if(j==0){
                                                                        return <th key={j} className={commonStyles.primaryTh} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></th>
                                                                    }
                                                                    return <td key={j} className={commonStyles.primaryTd} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></td>
                                                                })
                                                            }
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    p.content && p.table_type=='col_row_th' ?
                                    <div className={commonStyles.primaryTableWrapper}>
                                        <table className={`${commonStyles.primaryTable} ${pStyles.table}`}>
                                            <tbody>
                                                {
                                                    p.content.map((row, i)=>{
                                                        if(i == 0){
                                                            return <tr key={i} className={`${commonStyles.primaryTrHeader} ${pStyles.trHeader}`}>
                                                                {
                                                                    row.map((column, j)=>{
                                                                        return <th key={j} className={commonStyles.primaryTh} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></th>
                                                                    })
                                                                }
                                                            </tr>
                                                        } else {
                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                {
                                                                    row.map((column, j)=>{
                                                                        if(j==0){
                                                                            return <th key={j} className={commonStyles.primaryTh} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></th>
                                                                        }
                                                                        return <td key={j} className={commonStyles.primaryTd} onContextMenu={(event)=>handleChangeContextMenuTable(event, `${id}_table_menu`, i, j)}><input type='text' maxLength={64} size={10} value={p.content[i][j]} onChange={(event)=>handleChangeTableContent(i, j, event.target.value)}/></td>
                                                                    })
                                                                }
                                                            </tr>
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    p.content ?
                                    <Menu id={`${id}_table_menu`}>
                                        <Item id='add_row_above' onClick={handleItemClick}>上に行を追加</Item>
                                        <Item id="add_row_bottom" onClick={handleItemClick}>下に行を追加</Item>
                                        <Separator />
                                        <Item id='add_column_left' onClick={handleItemClick}>左に列を追加</Item>
                                        <Item id="add_column_right" onClick={handleItemClick}>右に列を追加</Item>
                                        <Separator />
                                        <Item id='remove_row' onClick={handleItemClick}>行を削除</Item>
                                        <Item id="remove_column" onClick={handleItemClick}>列を削除</Item>
                                    </Menu>
                                    :
                                    <></>
                                }
                                {
                                    !p.content ?
                                    <>
                                        <div className={pStyles.createTable}>
                                            <input type='number' placeholder='行' min={1} max={MAX_ROW} className={`${commonStyles.primaryInput} ${pStyles.inputRow}`} onChange={(event)=>{setRows(event.target.value)}} value={rows}/>
                                            <span>×</span>
                                            <input type='number' placeholder='列' min={1} max={MAX_COLUMN} className={`${commonStyles.primaryInput} ${pStyles.inputColumn}`} onChange={(event)=>{setColumns(event.target.value)}} value={columns}/>
                                            <button className={commonStyles.primaryButton} onClick={addNewTable}>作成</button>
                                        </div>
                                        <div className={pStyles.thOptionsWrapper}>
                                            <div>
                                                <input type='radio' name={`${id}thOptions`} id={`${id}_no_th`} value='no_th' onChange={()=>{handleChangeTableType('no_th')}} checked={p.table_type === 'no_th'}/>
                                                <label htmlFor={`${id}_no_th`}>見出しなし</label>
                                            </div>
                                            <div>
                                                <input type='radio' name={`${id}thOptions`} id={`${id}_row_th`} value='row_th' onChange={()=>{handleChangeTableType('row_th')}} checked={p.table_type === 'row_th'}/>
                                                <label htmlFor={`${id}_row_th`}>一行目に見出し</label>
                                            </div>
                                            <div>
                                                <input type='radio' name={`${id}thOptions`} id={`${id}_col_th`} value='col_th' onChange={()=>{handleChangeTableType('col_th')}} checked={p.table_type === 'col_th'}/>
                                                <label htmlFor={`${id}_col_th`}>一列目に見出し</label>
                                            </div>
                                            <div>
                                                <input type='radio' name={`${id}thOptions`} id={`${id}_col_row_th`} value='col_row_th' onChange={()=>{handleChangeTableType('col_row_th')}} checked={p.table_type === 'col_row_th'}/>
                                                <label htmlFor={`${id}_col_row_th`}>一行目と一列目に見出し</label>
                                            </div>
                                        </div>
                                        {
                                            rows > 0 && columns > 0 ?
                                            <table className={pStyles.tableTemplate}>
                                                <tbody>
                                                {
                                                    (()=>{
                                                        const trs = [];
                                                        for(let row = 0; row < rows; row++){
                                                            trs.push(<tr>
                                                                {
                                                                    (()=>{
                                                                        const tds = [];
                                                                        for(let column = 0; column < columns; column++){
                                                                            if(p.table_type=='no_th' || p.table_type=='col_th' || row!=0){
                                                                                tds.push(<td></td>);
                                                                            } else if((p.table_type == 'row_th' || p.table_type == 'col_row_th') && row==0){
                                                                                tds.push(<th></th>);
                                                                            }
                                                                        }
                                                                        return tds;
                                                                    })()
                                                                }
                                                            </tr>);
                                                        }
                                                        return trs
                                                    })()
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <></>
                                        }
                                    </>
                                    :
                                    <></>
                                }
                                </div>);
                    default:
                        if (p.content_type === 'paragraph') {
                            return (
                                <>
                                    <textarea
                                        className={`${pStyles.typeP} ${pStyles.pInner}`}
                                        maxLength={MAX_LENGTH_DOCUMENT_PARAGRAPH}
                                        ref={textAreaAsP}
                                        onChange={(e)=>{setSubmissionMessage(''); adjustTextAreaHeight(e.target); handleChangeTextAreaAsP(e)}}
                                        value={p.content}
                                    ></textarea>
                                    <p className={pStyles.restrictLength}>{p.content.length}/{MAX_LENGTH_DOCUMENT_PARAGRAPH}字</p>
                                </>
                            );
                        }
                         else if (p.content_type === 'headline') {
                            return (
                                <>
                                    <textarea
                                        className={`${pStyles.typeH} ${pStyles.pInner}`}
                                        maxLength={MAX_LENGTH_DOCUMENT_HEADLINE}
                                        ref={textAreaAsP}
                                        onChange={(e)=>{setSubmissionMessage(''); adjustTextAreaHeight(e.target); handleChangeTextAreaAsP(e)}}
                                        value={p.content}
                                    ></textarea>
                                    <p className={pStyles.restrictLength}>{p.content.length}/{MAX_LENGTH_DOCUMENT_HEADLINE}字</p>
                                </>
                            );
                        }
                        else if (p.content_type === 'small_headline') {
                            return (
                                <>
                                    <textarea
                                        className={`${pStyles.typeSH} ${pStyles.pInner}`}
                                        maxLength={MAX_LENGTH_DOCUMENT_HEADLINE}
                                        ref={textAreaAsP}
                                        onChange={(e)=>{setSubmissionMessage(''); adjustTextAreaHeight(e.target); handleChangeTextAreaAsP(e)}}
                                        value={p.content}
                                    ></textarea>
                                    <p className={pStyles.restrictLength}>{p.content.length}/{MAX_LENGTH_DOCUMENT_SMALL_HEADLINE}字</p>
                                </>
                            );
                        }
                        else {
                            return;
                        }
                }})()
            }
            <select className={pStyles.selectTypeOfP} ref={selectTypeOfP} onChange={(e)=>handleChangeSelectTypeOfP(e)}>
                <option value='headline' selected={p.content_type=='headline' ? true : false}>見出し</option>
                <option value='small_headline' selected={p.content_type=='small_headline' ? true : false}>小見出し</option>
                <option value='paragraph' selected={p.content_type=='paragraph' ? true : false}>段落</option>
                <option value='image' selected={p.content_type=='image' ? true : false}>画像</option>
                <option value='table' selected={p.content_type=='table' ? true : false}>テーブル</option>
            </select>
            <div className={pStyles.moveButtonWrapper}>
                <button type="button" onClick={()=>handleClickMoveButton('up')}>↑</button>
                <button type="button" onClick={()=>handleClickMoveButton('down')}>↓</button>
            </div>
            <button type="button" className={pStyles.buttonDelete} onClick={handleClickDeleteButton}>×</button>
            <button type="button" className={pStyles.buttonAddP} onClick={handleClickAddPButton}>+</button>
        </div>

        {selectedImage && (
            <div className={pStyles.modal} onClick={() => setSelectedImage(null)}>
                <img className={pStyles.modalImage} src={selectedImage} alt="Modal" />
            </div>
        )}
        </>
    );
}

export default P;