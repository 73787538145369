import React, { useState, useLayoutEffect, useRef, RefObject, createRef, useEffect } from 'react'; 
import articleStyles from './article_styles.module.css';
import commonStyles from '../common/common_styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faBookmark } from '@fortawesome/free-regular-svg-icons';
import { httpsCallable } from "firebase/functions";
import { functions, auth } from '../database/firebaseConfig'; 
import { faTag, faHashtag, faLaptop, faRocket, faArrowsRotate, faAt, faBrain, faCreditCard, faEllipsisVertical, faEdit } from '@fortawesome/free-solid-svg-icons';
import Page404 from '../common/page404';
import Loading from '../common/loading';
import LoadinDown from '../common/loading_down';
import SideBar from '../common/sidebar';
import { useLocation } from 'react-router-dom'; 
import { tagAndLabel, paymentAndLabel, environmentAndLabel } from '../common/constants';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { isURL } from '../common/functions';
import Image from './image';
import { useSearchParams } from 'react-router-dom';

const Article = ({setLoginOpen}) => {
    const [appDetails, setAppDetails] = useState({});
    const [usecases, setUsecases] = useState([]);
    const [hasMoreUsecases, setHasMoreUsecases] = useState(true);
    const sliderRef = useRef(null);
    const [order, setOrder] = useState('good_count');
    const [currentUsecaseIndex, setCurrentUsecaseIndex] = useState(0);
    const [usecaseId, setUsecaseId] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const paths = useLocation().pathname.split('/').filter(Boolean);
    const [isError, setIsError] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const settings = {
        speed: 0,
        afterChange: current => handleUsecaseClick(current),
    };
    const [isLoadingUsecase, setIsLoadingUsecase] = useState(true);
    const [searchParams] = useSearchParams(); 
    const [queryId, setQueryId] = useState(searchParams.get('id'));
    const [queryUsecase, setQueryUsecase] = useState({});
    const dropButtonRef = useRef(null);
    const dropDownRef = useRef(null);
    const editButtonRef = useState(null);
    const editDownRef = useState(null);
    //console.log(appDetails)
    //console.log('queryUsecase',queryUsecase)
    //console.log('usecases', usecases)

    const handleClickFavoriteButton = () => {
        if(appDetails.user_unique.set_favorite){
            const deleteFavoriteService = httpsCallable(functions, 'deleteFavoriteService');
            deleteFavoriteService({
                service_id: appDetails.service_id
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    return;
                    }
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        } else {
            const addFavoriteService = httpsCallable(functions, 'addFavoriteService');
            addFavoriteService({
                service_id: appDetails.service_id
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    return;
                }
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }
        setAppDetails({...appDetails, user_unique : {...appDetails.user_unique, set_favorite: !appDetails.user_unique.set_favorite}});
    }

    const handleClickGoodButton = () => {
        if(appDetails.user_unique.set_good){
            const deleteGoodService = httpsCallable(functions, 'deleteGoodService');
            deleteGoodService({
                service_id: appDetails.service_id
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    return;
                }
            }).catch((error) => {
                if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            setAppDetails({...appDetails, good_count: appDetails.good_count-1, user_unique : {...appDetails.user_unique, set_good: false}});
        } else {
            const setGoodOnService = httpsCallable(functions, 'setGoodOnService');
            setGoodOnService({
                service_id: appDetails.service_id
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    return;
                }
            }).catch((error) => {
                if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            setAppDetails({...appDetails, good_count: appDetails.good_count+1, user_unique : {...appDetails.user_unique, set_good: true}});
        }
    }

    const handleClickFavoriteUsecaseButton = () => {
        if(usecases[currentUsecaseIndex].user_unique.set_favorite){
            const deleteFavoriteUsecase = httpsCallable(functions, 'deleteFavoriteUsecase');
            deleteFavoriteUsecase({
                usecase_id: usecaseId
            }).then((result) => {
                const data = result.data;
                console.log(data)
                if (data.status != 200){
                    return;
                }
                /*setUsecases(usecases.map(usecase =>
                    usecase.usecase_id === usecaseId
                    ? { ...usecase, user_unique: { ...usecase.user_unique, set_favorite: false } }
                    : usecase
                ));*/
                usecases[currentUsecaseIndex].user_unique.set_favorite = false;
                setUsecases([...usecases]);
                if(queryId) {
                    setQueryUsecase(prev => ({
                        ...prev, 
                        user_unique: {
                            ...prev.user_unique,
                            set_favorite : false
                        }
                    }));
                }
            }).catch((error) => {
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        } else {
            const addFavoriteUsecase = httpsCallable(functions, 'addFavoriteUsecase');
            addFavoriteUsecase({
                usecase_id: usecaseId
                }).then((result) => {
                    const data = result.data;
                    console.log(data)
                    if (data.status != 200){
                        return;
                    }
                    /*setUsecases(usecases.map(usecase =>
                        usecase.usecase_id === usecaseId
                        ? { ...usecase, user_unique: { ...usecase.user_unique, set_favorite: true } }
                        : usecase
                    ));*/
                    usecases[currentUsecaseIndex].user_unique.set_favorite = true;
                    setUsecases([...usecases]);
                    
                    if(queryId) {
                        setQueryUsecase(prev => ({
                            ...prev, 
                            user_unique: {
                                ...prev.user_unique,
                                set_favorite : true
                            }
                        }));
                    }
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }
    }

    const handleClickGoodUsecaseButton = () => {
        if(usecases[currentUsecaseIndex].user_unique.set_good) {
            const deleteGoodUsecase = httpsCallable(functions, 'deleteGoodUsecase');
            deleteGoodUsecase({
                usecase_id: usecaseId
            }).then((result) => {
                const data = result.data;
                console.log(data)
                if (data.status != 200){
                    return;
                }
                /*setUsecases(usecases.map(usecase =>
                    usecase.usecase_id === usecaseId
                    ? { ...usecase, good_count: usecase.good_count-1, user_unique: { ...usecase.user_unique, set_good: false } }
                    : usecase
                ));*/
                usecases[currentUsecaseIndex].good_count--;
                usecases[currentUsecaseIndex].user_unique.set_good = false;
                setUsecases([...usecases]);

                if(queryId) {
                    setQueryUsecase(prev => ({
                        ...prev, 
                        good_count: prev.good_count - 1,
                        user_unique: {
                            ...prev.user_unique,
                            set_good : false
                        }
                    }));
                }
            }).catch((error) => {
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        } else {
            const setGoodOnUsecase = httpsCallable(functions, 'setGoodOnUsecase');
            setGoodOnUsecase({
                usecase_id: usecaseId
                }).then((result) => {
                    const data = result.data;
                    console.log(data)
                    if (data.status != 200){
                        return;
                    }
                    /*setUsecases(usecases.map(usecase =>
                        usecase.usecase_id === usecaseId
                        ? { ...usecase, good_count: usecase.good_count+1, user_unique: { ...usecase.user_unique, set_good: true } }
                        : usecase
                    ));*/
                    usecases[currentUsecaseIndex].good_count++;
                    usecases[currentUsecaseIndex].user_unique.set_good = true;
                    setUsecases([...usecases]);
                    if(queryId) {
                        setQueryUsecase(prev => ({
                            ...prev, 
                            good_count: prev.good_count + 1,
                            user_unique: {
                                ...prev.user_unique,
                                set_good : true
                            }
                        }));
                    }                                      
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }
    }

    useLayoutEffect(() => {
        if (paths.length != 2) {
            setIsInitialized(true);
            setIsError(true);
            return;
        }
        setIsLoadingUsecase(true);
        const getDetailsFromName = httpsCallable(functions, 'getDetailsFromName');
        getDetailsFromName({
            name: decodeURI(paths[1]),
            language: 'jpn'
            }).then((result) => {
                setIsInitialized(true);
                const data = result.data;
                console.log(data.service_id)
                if (data.status != 200){
                    setIsError(true);
                    return;
                }
                setIsError(false);
                setAppDetails(data);

                if (queryId) {
                    setIsInitialized(false);
                    const getUsecaseByUsecaseId = httpsCallable(functions, 'getUsecaseByUsecaseId');
                    getUsecaseByUsecaseId({
                        usecase_id: queryId
                    }).then((result) => {
                        const queryData = result.data;
                        //console.log(queryData)
                        if (queryData.status != 200){
                            setIsError(true);
                            return;
                        }
                        setIsError(false);
                        const getUserPublicData = httpsCallable(functions, 'getUserPublicData');
                        getUserPublicData({uid: queryData.poster_uid})
                        .then((result) => {
                            setQueryUsecase({
                                ...queryData, 
                                user_name: result.data.user_name, 
                                icon_base64: result.data.icon_base64
                            });
                            setIsInitialized(true);
                            //console.log('queryData', queryData)
                        }).catch((error) => {
                            setIsInitialized(true);
                            setIsError(true);
                            if (error.response) {
                                console.log('Error response data:', error.response.data);
                                console.log('Error response status:', error.response.status);
                                console.log('Error response headers:', error.response.headers);
                            } else if (error.request) {
                                console.log('Error request:', error.request);
                            } else {
                                console.log('Error', error.message);
                            }
                        })
                    }).catch((error) => {
                        setIsInitialized(true);
                        setIsError(true);
                        if (error.response) {
                            console.log('Error response data:', error.response.data);
                            console.log('Error response status:', error.response.status);
                            console.log('Error response headers:', error.response.headers);
                        } else if (error.request) {
                            console.log('Error request:', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    })
                }        
            }).catch((error) => {
                setIsInitialized(true);
                setIsError(true);
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        setIsLoadingUsecase(false);
    }, []);
    
    const fetchUsecases = (startAfterId) => {
        setIsLoadingUsecase(true);
        const limit = 5;
        const getUsecasesByServiceName = httpsCallable(functions, 'getUsecasesByServiceName')
        getUsecasesByServiceName({ name: decodeURI(paths[1]), limit: limit, order: order, usecase_id_start_after: startAfterId })
            .then((result) => {
                const data = result.data;
                if (data.status != 200){
                    setIsError(true);
                    return;
                }
                setIsError(false);
                const newUsecases = data.usecases;
                if (newUsecases.length < limit) {
                    setHasMoreUsecases(false); 
                }     
                if (newUsecases.length > 0 && usecaseId === null) {
                    setUsecaseId(newUsecases[0].usecase_id);  
                }
                const getUserPublicData = httpsCallable(functions, 'getUserPublicData')
                const fetchUserPromises = newUsecases.map(async (usecase) => {
                    const result = await getUserPublicData({uid: usecase.poster_uid});
                    return {...usecase, user_name: result.data.user_name, icon_base64: result.data.icon_base64};
                });                        
                Promise.all(fetchUserPromises)
                    .then(usecasesWithUser => setUsecases(prevUsecases => [...prevUsecases, ...usecasesWithUser]))
                    .catch((error) => {
                        console.log('Error in getting user public data:', error);
                    });
                setIsLoadingUsecase(false)
            })
            .catch((error) => {
                setIsInitialized(true);
                setIsError(true);             
                console.log('Error code:', error.code);
                console.log('Error message:', error.message);
                console.log('Error details:', error.details);
            });
    }

    const handleMoreClick = () => {
        if (!hasMoreUsecases) {
            return; 
        }
        setIsLoadingMore(true); 
        fetchUsecases(usecases[usecases.length - 1].usecase_id);
    };

    useEffect(() => {
        if (appDetails && appDetails.user_unique && appDetails.user_unique.set_good !== undefined) {
            fetchUsecases();
        
        }
    }, [appDetails.service_id, order]);

    useEffect(() => {
        if (sliderRef.current && !isLoadingMore) {
            sliderRef.current.slickGoTo(currentUsecaseIndex);
        }
        if (usecases.length > currentUsecaseIndex) {
            setUsecaseId(usecases[currentUsecaseIndex].usecase_id);
        }
        setIsLoadingMore(false);
       
    }, [usecases]);

    const handleUsecaseClick = (index) => {
        setCurrentUsecaseIndex(index);
        setUsecaseId(usecases[index].usecase_id);
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };

    const handleOrderChange = (newOrder) => {
        setOrder(newOrder);
        setUsecases([]); 
        setHasMoreUsecases(true); 
        setCurrentUsecaseIndex(0);
        setIsLoadingMore(false); 
    };

    const handleChange = (event) => {
        handleOrderChange(event.target.value);
    };

    const deleteQueryId = () => {
        setQueryId(null);
    }

    const [activeTab, setActiveTab] = useState(queryId ? '使用例' : '使い方');
    const [isSettingOpen, setIsSettingOpen] = useState();
    const [isEditOpen, setIsEditOpen] = useState();
    const [activeInfoTab, setActiveInfoTab] = useState('基本');
    const tabs = ['使い方', '料金', '使用例'];
    const infoTabs = ['基本', '開発者向け'];

    const createNavList = (srcList) => {
        const navList = [];
        srcList.map((p) => {
            if(p.content_type == 'headline'){
                navList.push({key: p.content, label: p.content});
            }
        })
        return navList
    }

    const handleSettingOutside = (event) => {
        if (!dropButtonRef.current.contains(event.target) && !dropDownRef.current.contains(event.target)) {
            setIsSettingOpen(false);
        }
    };

    const handleEditOutside = (event) => {
        if (!editButtonRef.current.contains(event.target) && !editDownRef.current.contains(event.target)) {
            setIsEditOpen(false);
        }
    };

    useEffect(() => {
        if (isSettingOpen) {
            document.addEventListener('mousedown', handleSettingOutside);
        } 
        return () => {
            document.removeEventListener('mousedown', handleSettingOutside);
        };
    }, [isSettingOpen]);

    useEffect(() => {
        if (isEditOpen) {
            document.addEventListener('mousedown', handleEditOutside);
        } 
        return () => {
            document.removeEventListener('mousedown', handleEditOutside);
        };
    }, [isEditOpen]);

    return (
        (paths.length == 2 && !isError && isInitialized) ? 
        <>
        <Helmet>
            <title>{`${appDetails.name} - AI TechBase`}</title>
            <meta name="description" content={appDetails.short_description}/>
        </Helmet>
        <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
            <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
            <div className={commonStyles.sectionWrapper}>
                <section className={commonStyles.primarySection}>
                    <div className={articleStyles.appInfoWrapper}>
                        <img className={articleStyles.appThumbnail} src={appDetails.thumbnail_base64 ? (appDetails.thumbnail_base64.startsWith('data:image') ? appDetails.thumbnail_base64 : `data:image/jpeg;base64,${appDetails.thumbnail_base64}`) : ''}></img>
                        <div>
                            <div className={articleStyles.titleWrapper}>
                                <h1 className={articleStyles.appName}>{appDetails.name}</h1>
                                <div className={articleStyles.settingButtonContainer}>
                                    <button className={articleStyles.editButton} ref={dropButtonRef} onClick={()=>{isSettingOpen ? setIsSettingOpen(false) : setIsSettingOpen(true)}}>
                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                    </button>
                                    {isSettingOpen ?
                                    (<div ref={dropDownRef} className={articleStyles.dropDown}>
                                        <ul>
                                            <li><a onClick={()=>{setIsSettingOpen(false)}} href={`https://docs.google.com/forms/d/e/1FAIpQLSdJjzaOF0WwGruTpMghqMWbQ8zyJ9G9WiwdnqOHp_3XzIW-vg/viewform?usp=pp_url&entry.1943588655=${appDetails.name}`} target='_blank'>通報</a></li>
                                            <li><a onClick={()=>{setIsSettingOpen(false)}} href='https://docs.google.com/forms/d/e/1FAIpQLScNF5xevLzFdt822oiDNekf2n_MA_m8-8AJl4QP4NmkliiiSw/viewform?usp=sf_link' target="_blank">お問い合わせ</a></li>
                                            <li onClick={() => {setIsSettingOpen(false)}}>閉じる</li>
                                        </ul>
                                    </div>) :
                                    (<></>)}
                                    {auth.currentUser && 
                                        <button ref={editButtonRef} onClick={()=>{isEditOpen ? setIsEditOpen(false) : setIsEditOpen(true)}}>
                                            <FontAwesomeIcon icon={faEdit} size='lg'/>
                                        </button>
                                    }
                                    {isEditOpen ?
                                    (<div ref={editDownRef} className={articleStyles.dropDown}>
                                        <ul>
                                            <li><Link to={`/editor?app=${appDetails.name}`}>編集</Link></li>
                                            {/*<li onClick={() => {setReviewOpen(true); setIsSettingOpen(false);}}>レビューを書く</li>*/}
                                            <li><Link to={`/usecase?app=${appDetails.name}`}>使用例を投稿</Link></li>
                                            <li onClick={() => {setIsEditOpen(false)}}>閉じる</li>
                                        </ul>
                                    </div>) :
                                    (<></>)}
                                </div>
                            </div>
                            <p className={articleStyles.viewWrapper}>
                                <span className={articleStyles.viewCount}>{appDetails.view_count}</span><span className={articleStyles.viewsText}>views</span>
                            </p>
                            <div className={articleStyles.evaluateWrapper}>
                                <button className={appDetails.user_unique.set_good ? articleStyles.setButton : ''} onClick={auth.currentUser !== null ? handleClickGoodButton : () => setLoginOpen(true)}>
                                    <FontAwesomeIcon className={articleStyles.thumbUp} icon={faThumbsUp}/>
                                </button>
                                <span className={appDetails.user_unique.set_good ? commonStyles.primaryAccentColor : ''}>{appDetails.good_count}</span>
                                <button className={`${articleStyles.favoriteButton} ${appDetails.user_unique.set_favorite ? articleStyles.setButton : ''}`} onClick={auth.currentUser !== null ? handleClickFavoriteButton : () => setLoginOpen(true)}>
                                    <FontAwesomeIcon className={articleStyles.favorite} icon={faBookmark}/>
                                </button>
                            </div>
                            {(()=>{
                                switch(activeInfoTab) {
                                    case '基本':
                                        return (
                                            <div className={articleStyles.infoTab} >
                                                {appDetails.published_at ? 
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faRocket} />
                                                        <p>{appDetails.published_at.slice(0,7)}</p>    
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {appDetails.revisioned_at ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faArrowsRotate} />
                                                        <p>{appDetails.revisioned_at}</p>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {appDetails.tags.length ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faTag} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.tags.map((tag, i)=>{
                                                                    return <Link key={i} to={`/tags/${tag}`} className={`${commonStyles.tagPrimaryColor} ${articleStyles.tag}`} onClick={()=>window.scrollTo({top: 0})}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</Link>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {appDetails.payments.length  ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faCreditCard} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.payments.map((payment, i)=>{
                                                                    return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{paymentAndLabel[payment]}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {appDetails.environments.length ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faLaptop} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.environments.map((environment,i)=>{
                                                                    return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{environmentAndLabel[environment]}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {/*appDetails.equipments.length ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faMicrochip} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.equipments.map((equipment)=>{
                                                                    return <li className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{equipmentAndLabel[equipment]}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                */}
                                            </div>
                                        );
                                    case '開発者向け':
                                        return (
                                            <div className={articleStyles.infoTab}>
                                                {appDetails.publishers.length ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faAt} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.publishers.map((publisher, i)=>{
                                                                    return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{publisher}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {appDetails.models.length ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faBrain} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.models.map((model, i)=>{
                                                                    return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{model}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {/*appDetails.datasets.length ?
                                                    <div className={articleStyles.iconWrapper}>
                                                        <FontAwesomeIcon icon={faDatabase} />
                                                        <ul className={articleStyles.iconsUl}>
                                                            {
                                                                appDetails.datasets.map((dataset)=>{
                                                                    return <li className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{dataset}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                                */}
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })()}
                            <div className={commonStyles.tabInverseContainer}>
                                {infoTabs.map(tab => (
                                <div key={tab} className={`${commonStyles.tabItemInverse} ${activeInfoTab === tab ? commonStyles.tabItemActive : ''}`} onClick={() => setActiveInfoTab(tab)}>
                                    {tab}
                                </div>
                                ))}
                            </div> 
                        </div>
                    </div>
                    <p className={`${commonStyles.primaryP} ${articleStyles.longDescription}`}>{appDetails.long_description}</p>
                    <a href={isURL(appDetails.url) ? appDetails.url : ''} target='_blank' rel='nofollow' className={`${commonStyles.primaryButton} ${articleStyles.toSiteButton}`}>公式サイトはこちら</a>
                </section>
                <section className={commonStyles.primarySection}>
                    <div className={`${commonStyles.tabContainer} ${articleStyles.tabContainerArticle}`}>
                        {tabs.map(tab => (
                        <div key={tab} className={`${commonStyles.tabItem} ${activeTab === tab ? commonStyles.tabItemActive : ''}`} onClick={() => setActiveTab(tab)}>
                            {tab}
                        </div>
                        ))}
                    </div> 
                    <div className={articleStyles.articleArea}>
                    {(()=>{
                        switch(activeTab) {
                            case '使い方':
                                return (
                                    <>
                                        <h2 id='primary_usage' className={commonStyles.primaryH2}>主な用途</h2>
                                        {
                                            appDetails.primary_usage.map((p, i)=>{
                                                switch(p.content_type){
                                                    case 'paragraph':
                                                        return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                    case 'headline':
                                                        return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                    case 'small_headline':
                                                        return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                    case 'image':
                                                        return <Image key={i} fileName={p.file_name} imageType='service_primary_usage'/>
                                                    case 'table':
                                                        if(p.content && p.table_type=='no_th'){
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    if(j==0){
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    }
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        if(j==0){
                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                        }
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        }
                                                        break;
                                                }
                                            })
                                        }
                                        <h2 id='how_to_install' className={commonStyles.primaryH2}>導入方法</h2>
                                        {
                                            appDetails.how_to_install.map((p, i)=>{
                                                switch(p.content_type){
                                                    case 'paragraph':
                                                        return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                    case 'headline':
                                                        return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                    case 'small_headline':
                                                        return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                    case 'image':
                                                        return <Image key={i} fileName={p.file_name} imageType='service_how_to_install'/>
                                                    case 'table':
                                                        if(p.content && p.table_type=='no_th'){
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if(p.content && p.table_type=='row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    if(j==0){
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    }
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        if(j==0){
                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                        }
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        }
                                                        break;    
                                                }
                                            })
                                        }
                                        <h2 id='how_to_execute' className={commonStyles.primaryH2}>操作方法</h2>
                                        {
                                            appDetails.how_to_execute.map((p, i)=>{
                                                switch(p.content_type){
                                                    case 'paragraph':
                                                        return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                    case 'headline':
                                                        return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                    case 'small_headline':
                                                        return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                    case 'image':
                                                        return <Image key={i} fileName={p.file_name} imageType='service_how_to_execute'/>
                                                    case 'table':
                                                        if(p.content && p.table_type=='no_th'){
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if(p.content && p.table_type=='row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    if(j==0){
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    }
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        if(j==0){
                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                        }
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        }
                                                        break;   
                                                }
                                            })
                                        } 
                                    </>
                                ); 
                            case '料金':
                                return (
                                    <>
                                        <h2 id='payment_detail' className={commonStyles.primaryH2}>料金の詳細</h2>
                                        {
                                            appDetails.payment_detail.map((p, i)=>{
                                                switch(p.content_type){
                                                    case 'paragraph':
                                                        return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                    case 'headline':
                                                        return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                    case 'small_headline':
                                                        return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                    case 'image':
                                                        return <Image key={i} fileName={p.file_name} imageType='service_payment_detail'/>
                                                    case 'table':
                                                        if(p.content && p.table_type=='no_th'){
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if(p.content && p.table_type=='row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                            {
                                                                                                row.map((column, j)=>{
                                                                                                    if(j==0){
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    }
                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        } else if (p.content && p.table_type=='col_row_th') {
                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                        <table className={commonStyles.primaryTable}>
                                                                            <tbody>
                                                                                {
                                                                                    p.content.map((row, i)=>{
                                                                                        if(i == 0){
                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        } else {
                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                {
                                                                                                    row.map((column, j)=>{
                                                                                                        if(j==0){
                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                        }
                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                        }
                                                        break;       
                                                }
                                            })
                                        }
                                    </>
                                )
                            case '使用例':
                                return (
                                    <>
                                        {!queryId ?
                                            <>
                                                <div className={articleStyles.setting}>
                                                    <label htmlFor="order-select"></label>
                                                    <select className={`${articleStyles.selectButton} ${articleStyles.index}`} id="order-select" onChange={handleChange}>
                                                        <option value="good_count">評価順</option>
                                                        <option value="view_count">閲覧数順</option>
                                                        <option value="posted_at">新着順</option>
                                                    </select>
                                                    {usecases.map((_, index) => (
                                                        <button className={`${articleStyles.index} ${index === currentUsecaseIndex ? commonStyles.underbarButton : ''}`} key={index} onClick={() => handleUsecaseClick(index)}>
                                                            {index + 1}
                                                        </button>
                                                    ))}
                                                    {hasMoreUsecases && <button className={articleStyles.indexMore} onClick={handleMoreClick}>もっと見る</button>}
                                                </div>
                                                {!isLoadingUsecase ?
                                                    <Slider ref={sliderRef} {...settings}>
                                                        {usecases.map((usecase, _) => (
                                                            <div id={usecase.usecase_id}>
                                                                <div className={articleStyles.user}>
                                                                    <img className={articleStyles.userIcon} src={`data:image/jpg;base64,${usecase.icon_base64}`} alt="user icon" />
                                                                    <Link to={`/userPage/${usecase.poster_uid}`} className={articleStyles.userName} onClick={()=>window.scrollTo({top: 0})}>{usecase.user_name}</Link>
                                                                </div>
                                                                <p className={articleStyles.viewWrapper}>
                                                                    <span className={articleStyles.viewCount}>{usecase.view_count}</span><span className={articleStyles.viewsText}>views</span>
                                                                </p>
                                                                <div className={`${articleStyles.evaluateWrapper} ${articleStyles.evaluateBottomWrapper}`}>
                                                                    <button className={usecase.user_unique.set_good ? articleStyles.setButton : ''} onClick={auth.currentUser !== null ? handleClickGoodUsecaseButton : () => setLoginOpen(true)}>
                                                                        <FontAwesomeIcon className={articleStyles.thumbUp} icon={faThumbsUp}/>
                                                                    </button>
                                                                    <span>{usecase.good_count}</span>
                                                                    <button className={`${articleStyles.favoriteButton} ${usecase.user_unique.set_favorite ? articleStyles.setButton : ''}`} onClick={auth.currentUser !== null ? handleClickFavoriteUsecaseButton : () => setLoginOpen(true)}>
                                                                        <FontAwesomeIcon className={articleStyles.favorite} icon={faBookmark}/>
                                                                    </button>
                                                                </div>
                                                                {usecase.tags.length ?
                                                                    <div className={articleStyles.iconWrapper}>
                                                                        <FontAwesomeIcon icon={faTag} />
                                                                        <ul className={articleStyles.iconsUl}>
                                                                            {
                                                                                usecase.tags.map((tag, i)=>{
                                                                                    return <Link key={i} to={`/tags/${tag}`} className={`${commonStyles.tagPrimaryColor} ${articleStyles.tag}`} onClick={()=>window.scrollTo({top: 0})}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</Link>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                                }
                                                                <h2 className={articleStyles.title}>{usecase.title}</h2>
                                                                {usecase.contents.map((c, i) => {
                                                                    switch(c.content_type) {
                                                                        case 'paragraph':
                                                                            return <p key={i} className={`${commonStyles.primaryP} ${articleStyles.para}`}>{c.content}</p>
                                                                        case 'headline':
                                                                            return <h3 key={i} className={`${commonStyles.primaryH3} ${i === 0 ? "" : articleStyles.head}`}>{c.content}</h3>
                                                                        case 'small_headline':
                                                                            return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{c.content}</span></h4>
                                                                        case 'image':
                                                                            return <Image key={i} fileName={c.file_name} imageType='usecase_contents'/>
                                                                        case 'table':
                                                                            if(c.content && c.table_type=='no_th'){
                                                                                return <div className={commonStyles.primaryTableWrapper}>
                                                                                            <table className={commonStyles.primaryTable}>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        c.content.map((row, i)=>{
                                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                                {
                                                                                                                    row.map((column, j)=>{
                                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                    })
                                                                                                                }
                                                                                                                </tr>
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                            } else if (c.content && c.table_type=='row_th') {
                                                                                return <div className={commonStyles.primaryTableWrapper}>
                                                                                            <table className={commonStyles.primaryTable}>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        c.content.map((row, i)=>{
                                                                                                            if(i == 0){
                                                                                                                return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                                    {
                                                                                                                        row.map((column, j)=>{
                                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tr>
                                                                                                            } else {
                                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                                    {
                                                                                                                        row.map((column, j)=>{
                                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tr>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                            } else if (c.content && c.table_type=='col_th') {
                                                                                return <div className={commonStyles.primaryTableWrapper}>
                                                                                            <table className={commonStyles.primaryTable}>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        c.content.map((row, i)=>{
                                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                                {
                                                                                                                    row.map((column, j)=>{
                                                                                                                        if(j==0){
                                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                        }
                                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                    })
                                                                                                                }
                                                                                                            </tr>
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                            } else if (c.content && c.table_type=='col_row_th') {
                                                                                return <div className={commonStyles.primaryTableWrapper}>
                                                                                            <table className={commonStyles.primaryTable}>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        c.content.map((row, i)=>{
                                                                                                            if(i == 0){
                                                                                                                return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                                    {
                                                                                                                        row.map((column, j)=>{
                                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tr>
                                                                                                            } else {
                                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                                    {
                                                                                                                        row.map((column, j)=>{
                                                                                                                            if(j==0){
                                                                                                                                return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                            }
                                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tr>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                }
                                                                        default:
                                                                            return null;
                                                                    }
                                                                })}
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                    :
                                                    <Loading />
                                                }
                                            </>
                                            :
                                            <>
                                                {(!isLoadingUsecase) && (queryUsecase.user_name)  ? 
                                                    <div>
                                                        <div className={articleStyles.deleteButton}>
                                                            <button className={commonStyles.underbarButton} onClick={deleteQueryId}>もっと見る</button>
                                                        </div>
                                                        <div className={articleStyles.user}>
                                                            <img className={articleStyles.userIcon} src={`data:image/jpg;base64,${queryUsecase.icon_base64}`} alt="user icon" />
                                                            <Link to={`/userPage/${queryUsecase.poster_uid}`} className={articleStyles.userName} onClick={()=>window.scrollTo({top: 0})}>{queryUsecase.user_name}</Link>
                                                        </div>
                                                        <p className={articleStyles.viewWrapper}>
                                                            <span className={articleStyles.viewCount}>{queryUsecase.view_count}</span><span className={articleStyles.viewsText}>views</span>
                                                        </p>
                                                        <div className={`${articleStyles.evaluateWrapper} ${articleStyles.evaluateBottomWrapper}`}>
                                                            <button className={queryUsecase.user_unique.set_good ? articleStyles.setButton : ''} onClick={handleClickGoodUsecaseButton}>
                                                                <FontAwesomeIcon className={articleStyles.thumbUp} icon={faThumbsUp}/>
                                                            </button>
                                                            <span>{queryUsecase.good_count}</span>
                                                            <button className={`${articleStyles.favoriteButton} ${queryUsecase.user_unique.set_favorite ? articleStyles.setButton : ''}`} onClick={handleClickFavoriteUsecaseButton}>
                                                                <FontAwesomeIcon className={articleStyles.favorite} icon={faBookmark}/>
                                                            </button>
                                                        </div>
                                                        {queryUsecase.tags.length ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faTag} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        queryUsecase.tags.map((tag, i)=>{
                                                                            return <Link key={i} to={`/tags/${tag}`} className={`${commonStyles.tagPrimaryColor} ${articleStyles.tag}`} onClick={()=>window.scrollTo({top: 0})}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</Link>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        :
                                                            <></>
                                                        }
                                                        <h2 className={articleStyles.title}>{queryUsecase.title}</h2>
                                                        {queryUsecase.contents.map((c, i) => { 
                                                            switch(c.content_type) {
                                                                case 'paragraph':
                                                                    return <p key={i} className={`${commonStyles.primaryP} ${articleStyles.para}`}>{c.content}</p>
                                                                case 'headline':
                                                                    return <h3 id={c.content} key={i} className={`${commonStyles.primaryH3} ${articleStyles.head}`}>{c.content}</h3>
                                                                case 'small_headline':
                                                                    return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{c.content}</span></h4>
                                                                case 'image':
                                                                    return <Image key={i} fileName={c.file_name} imageType='usecase_contents'/>
                                                                    case 'table':
                                                                        if(c.content && c.table_type=='no_th'){
                                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                                        <table className={commonStyles.primaryTable}>
                                                                                            <tbody>
                                                                                                {
                                                                                                    c.content.map((row, i)=>{
                                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                            {
                                                                                                                row.map((column, j)=>{
                                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                })
                                                                                                            }
                                                                                                            </tr>
                                                                                                    })
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                        } else if (c.content && c.table_type=='row_th') {
                                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                                        <table className={commonStyles.primaryTable}>
                                                                                            <tbody>
                                                                                                {
                                                                                                    c.content.map((row, i)=>{
                                                                                                        if(i == 0){
                                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                                {
                                                                                                                    row.map((column, j)=>{
                                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                    })
                                                                                                                }
                                                                                                            </tr>
                                                                                                        } else {
                                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                                {
                                                                                                                    row.map((column, j)=>{
                                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                    })
                                                                                                                }
                                                                                                            </tr>
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                        } else if (c.content && c.table_type=='col_th') {
                                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                                        <table className={commonStyles.primaryTable}>
                                                                                            <tbody>
                                                                                                {
                                                                                                    c.content.map((row, i)=>{
                                                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                            {
                                                                                                                row.map((column, j)=>{
                                                                                                                    if(j==0){
                                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                    }
                                                                                                                    return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                })
                                                                                                            }
                                                                                                        </tr>
                                                                                                    })
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                        } else if (c.content && c.table_type=='col_row_th') {
                                                                            return <div className={commonStyles.primaryTableWrapper}>
                                                                                        <table className={commonStyles.primaryTable}>
                                                                                            <tbody>
                                                                                                {
                                                                                                    c.content.map((row, i)=>{
                                                                                                        if(i == 0){
                                                                                                            return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                                {
                                                                                                                    row.map((column, j)=>{
                                                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                    })
                                                                                                                }
                                                                                                            </tr>
                                                                                                        } else {
                                                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                                {
                                                                                                                    row.map((column, j)=>{
                                                                                                                        if(j==0){
                                                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                        }
                                                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                                    })
                                                                                                                }
                                                                                                            </tr>
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                            }
                                                                default:
                                                                    return null;
                                                            }
                                                        })}
                                                    </div>
                                                    :
                                                    <Loading />
                                                }
                                            </>
                                        }
                                    </>
                                )                                                                  
                            default:
                                return null;
                        }
                    })()}
                    </div>
                </section>
            </div>
            <SideBar 
                articleNav={true} 
                articleNavList={
                    activeTab == '使い方' ? 
                    [{key:'primary_usage', label:'主な用途', list: createNavList(appDetails.primary_usage)}, {key:'how_to_install', label:'導入方法', list: createNavList(appDetails.how_to_install)}, {key:'how_to_execute', label:'操作方法', list:createNavList(appDetails.how_to_execute)}] 
                    : activeTab=='料金' ? 
                    [{key:'payment_detail', label:'料金の詳細', list: createNavList(appDetails.payment_detail)}] 
                    : []
                    /*: activeTab=='使用例' && !queryId ?
                    usecases.map((usecase, index) => ({ key: `${usecase.usecase_id}`, label: `例 ${index + 1}`, list: createUsecaseNavList(usecase.contents), onClick: () => handleUsecaseClick(index) }))
                    : []*/
                }
                last={'articleNav'}
            />
        </main>
        </>
        : isInitialized ?
        <Page404/>
        :
        <main className={commonStyles.primaryMain}>
            <section className={commonStyles.transparentBgColor}>
                <div className={commonStyles.sectionInner}>
                    <Loading/>
                    {queryId && <LoadinDown/>}
                </div>
            </section>
        </main>
    );
};

export default Article;