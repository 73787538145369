import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import First from './first/first.js';
import Header from './common/header.js';
import Editor from './editor/editor.js';
import Search from './search/search.js';
import Usecase from './useCase/useCase.js';
import Test from './test/test.js';
import Article from './article/article.js';
//import ArticlePreview from './article/articlePreview.js';
import Terms from './legal/terms.js';
import Privacy from './legal/privacy.js';
import Product from './about/product.js';
import Use from './about/use.js';
import Tag from './tags/tag.js';
import Page404 from './common/page404.js';
import All from './all/all.js';
import styles from './App_styles.module.css';
import commonStyles from './common/common_styles.module.css';
import { updateEmail, updatePassword, signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword, sendEmailVerification, deleteUser, EmailAuthProvider, reauthenticateWithCredential, sendPasswordResetEmail } from "firebase/auth"; 
import 'firebase/compat/functions';
import { functions, auth } from './database/firebaseConfig'; 
import Footer from './common/footer.js';
import UserPage from './userPage/userPage.js';
import google_icon from "./img/google.webp";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { httpsCallable } from "firebase/functions";
import Loading from './common/loading';
import MailRedirect from './redirect/mailRedirect.js';

function App() {
	const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const [isReviewOpen, setReviewOpen] = useState(false);
	const [isLoginOpen, setLoginOpen] = useState(false);
	const [isRegisterOpen, setRegisterOpen] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [registerEmail, setRegisterEmail] = useState("");
	const [registerPassword, setRegisterPassword] = useState("");
	const [username, setUsername] = useState("");
	const [user, setUser] = useState(null); 
	const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState("");
	const [registerError, setRegisterError] = useState(null);
	const [loginError, setLoginError] = useState(null);  
	const [configError, setConfigError] = useState(null);
	const [mailPasswordError, setMailPasswordError] = useState(null);
	const [isConfigOpen, setConfigOpen] = useState(false);
	const [isMailPasswordOpen, setMailPasswordOpen] = useState(false);
	const [image, setImage] = useState(null); 
	const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
	const [isMailConfirmModalOpen, setIsMailConfirmModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [userName, setUserName] = useState(null);
	const [userProfile, setUserProfile] = useState(null);
	const [currentEmail, setCurrentEmail] = useState(null);
	const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
	const [resendError, setResendError] = useState(null);
	const [isDeletePasswordModal, setIsDeletePasswordModal] = useState(false);
	const [deletePasswordError, setDeletePasswordError] = useState(null);
	const [registerAttention, setRegisterAttention] = useState(false);
	const [deleteGoogleReauthenticate, setDeleteGoogleReauthenticate] = useState(false);
	const [resetPassword, setResetPassword] = useState(null);
	const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
	const [isLogin, setIsLogin] = useState(false);
	const modalRef = useRef(null);
	const googleConfirm = useRef(false);
	const MAX_LENGTH_NAME = 32;
	const MAX_LENGTH_PROFILE = 256;

	useEffect(() => {
    	const unsubscribe = onAuthStateChanged(auth, async (user) => {
      		if (user) {
        		await user.reload(); 
        		if (user.emailVerified) {
					setUser(user); 
          			setCurrentEmail(user.email);
          			const getUserInfo = httpsCallable(functions, 'getUserInfo');
          			if (!googleConfirm.current) {
            			getUserInfo().then(async (result) => { 
							setUserInfo(result.data);
							setUserName(result.data.user_name);
							setUserProfile(result.data.profile);
							setIsLogin(true);
							setIsLoading(false);
            			}).catch((error) => {
              				console.error("getUserInfo Error", error);
            			});
          			}
        		}                   
      		} else {
				setUser(null);
				setUserInfo(null);
				setIsLogin(false);
      		}
    	});
		googleConfirm.current = false
    	return () => unsubscribe();
	},[auth, googleConfirm]);

	const handleClickOutside = (event) => {
        if (!modalRef.current.contains(event.target)) {
			setRegisterOpen(false);
			setLoginOpen(false);
			setConfigOpen(false);
			setRegisterAttention(false);
			setMailPasswordOpen(false)
        }
    };   

	const handleImageChange = (event) => {
    	if (event.target.files && event.target.files[0]) {
      		let img = URL.createObjectURL(event.target.files[0]);
      		setImage(img);
    	}
  	}

	const handleUserNameChange = (event) => {
    	if (event.target.value.length <= MAX_LENGTH_NAME) {
      		setUserName(event.target.value);
    	}
  	}

	const handleUserProfileChange = (event) => {
    	if (event.target.value.length <= MAX_LENGTH_PROFILE) {
      		setUserProfile(event.target.value);
    	}
  	}

  	const handleUserEmailChange = (event) => {
    	setCurrentEmail(event.target.value);
  	}

	  const handleChangeEmail = (e) => {
    	setEmail(e.target.value);
  	};

  	const handleChangePassword = (e) => {
    	setPassword(e.target.value);
  	};

  	const handleRegister = async (username, email, password, passwordConfirm) => {
		googleConfirm.current = false;
    	if (!username) {
      		setRegisterError("ユーザー名を記入してください。");
      		return;
    	}
		if (!email) {
			setRegisterError("メールアドレスを記入してください。");
			return;
		}
		if (!password) {
			setRegisterError("パスワードを記入してください。");
			return;
		}
		if (password !== passwordConfirm) {
			setRegisterError("パスワードが一致していません。");
			return;
		}
		if (password.length < 6) {
			setRegisterError("パスワードは6文字以上で記入してください。");
			return;
		}
    	try {
      		setIsLoading(true);
      		const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      		const user = userCredential.user;
      		await sendEmailVerification(user);
      		const updateUserProfile = httpsCallable(functions, 'updateUserProfile');
        	updateUserProfile({
			name: username,
			profile: '',
			update_icon: false,
        	}).then(response => {
				if(response.data.status === 200) {
					console.log('updateUserProfile');
					setUser(user);
				}
				else {
					console.log('Failed to updateUserProfile')
				}
        	}).catch(err => {
          		console.error(err);
        	});
      		setIsLoading(false);
      		setRegisterOpen(false);
      		setIsRegisterModalOpen(true);
    	} catch (error) {
      		setIsLoading(false);
      		switch (error.code) {
        		case 'auth/email-already-in-use':
          			setRegisterError('このメールアドレスは既に使用されています。');
          			break;
        		case 'auth/invalid-email':
          			setRegisterError('不正なメールアドレス形式です。');
          			break;
				/*case 'auth/weak-password':
					setRegisterError('パスワードが弱すぎます。');
					break;*/
        		default:
          			setRegisterError('登録に失敗しました。もう一度お試しください。');
          			break;
      		}
    	}
  	};

  	const handleLogin = async (email, password) => {
		googleConfirm.current = false;
		setIsLoading(true);
    	try {
      		setLoginOpen(false);
      		const userCredential = await signInWithEmailAndPassword(auth, email, password);
      		const user = userCredential.user;
      		if (user.emailVerified) {
        		setIsLoading(false);
        		setLoginError(null);
      		} else {
        		setIsLoading(false);
        		setLoginOpen(true);
        		setLoginError('メールアドレスが認証されていません。');  
      		}
    	} catch (error) {
			console.error("Failed to login", error);
			setIsLoading(false);
			setLoginOpen(true);
			setLoginError('メールアドレスまたはパスワードが違います。'); 
    	}
  	};

	const handleGoogleRegister = async () => {
    	try {
			googleConfirm.current = true;
      		const provider = new GoogleAuthProvider();
      		const userCredential = await signInWithPopup(auth, provider);
      		const user = userCredential.user;
      		const checkUserExistence = httpsCallable(functions, 'checkUserExistence');
			checkUserExistence({
				user_id: user.uid
			}).then(async response => { 
				if (response.data.exists) {
        			setIsLoading(false);
          			return;
				}
				else {
					const updateUserProfile = httpsCallable(functions, 'updateUserProfile');
					updateUserProfile({
						name: user.displayName, 
						profile: '',
						update_icon: false,
					}).then(response => {
						if(response.data.status === 200) {
							const getUserInfo = httpsCallable(functions, 'getUserInfo');
						  	getUserInfo().then(async (result) => { 
								setUserInfo(result.data);
								setUserName(result.data.user_name);
								setUserProfile(result.data.profile);
								setIsLogin(true);
						  	}).catch((error) => {
								setIsLogin(false);
								console.error("getUserInfo Error", error);
						  	});
						}
						else {
							console.log('updateUserProfile Error')
						}
					}).catch(error => {
						console.error(error);
					});
				}
			}).catch(error => {
				console.error('checkUserExistence Error', error);
			});
			googleConfirm.current = false;
      	} catch (error) {
      		console.error("Failed to handleGoogleRegister", error);
      		return;
    	}
  	};

  	const handleGoogleSignIn = async () => {
    	try {
			googleConfirm.current = true;
      		const provider = new GoogleAuthProvider();
      		const result = await signInWithPopup(auth, provider);
	  		const checkUserExistence = httpsCallable(functions, 'checkUserExistence');
			checkUserExistence({
            	user_id: result.user.uid
          	}).then(async response => { 
            	if (response.data.status === 200) {
              		if (!response.data.exists) {
						await deleteUser(result.user) 
                		setRegisterAttention(true);
              		}
            	}
            	else {
					console.log('checkUserExistence Error')
            	}
          	}).catch(error => {
            	console.error(error);
          	});
    	} catch (error) {
      		console.error("Failed to handleGoogleSignIn", error);
    	}
		googleConfirm.current = false
  	};
    
  	const handleLogout = async () => {
    	try {
      		await auth.signOut();
      		setUser(null);
    	} catch (error) {
      		console.error("Failed to logout", error);
    	}
	};

  	const handleDeleteAccount = async (event) => {
    	try {
      		if (user && user.providerData.some(pd => pd.providerId === 'password')) {
        		event.preventDefault();
				setIsLoading(true);
        		const delete_password = document.getElementById('delete_password').value;
        		const credential = EmailAuthProvider.credential(user.email, delete_password);
        		await reauthenticateWithCredential(user, credential);
      		} 
      		else if (user && user.providerData.some(pd => pd.providerId === 'google.com')) {
				googleConfirm.current = true;
        		const provider = new GoogleAuthProvider();
        		const result = await signInWithPopup(auth, provider); 
        		if (result.user.uid !== user.uid) {
          			setDeleteGoogleReauthenticate(true);
          			const checkUserExistence = httpsCallable(functions, 'checkUserExistence');
          			checkUserExistence({
            			user_id: result.user.uid
          			}).then(async response => { 
            			if (response.data.status === 200) {
              				if(!response.data.exists) {
                				await deleteUser(result.user) 
							}
            			}
            			else {
							console.log('checkUserExistence Error')
            			}
         			}).catch(error => {
            			console.error(error);
          			});
          			throw new Error("再認証に失敗しました。現在のユーザーアカウントを選択してください。");
        		}        
      		}
      		const deleteUserProfile = httpsCallable(functions, 'deleteUserProfile');
      		const response = await deleteUserProfile();
      		if (response.data.status === 200) {
        		await deleteUser(user);
				setIsLoading(false);
        		setIsDeletePasswordModal(false)
      		} else {
				setIsLoading(false);
        		console.log('deleteUserProfile');
      		}
    	} catch (error) {
			setIsLoading(false);
			setDeletePasswordError('パスワードが違います。');
			console.error("Failed to handleDeleteAccount", error);  
    	}
		googleConfirm.current = false;
    	setDeleteConfirmOpen(false);
	};
  
	const handleUserProfileSubmit = async (event) => {
    	event.preventDefault();
    	setIsLoading(true);

    	const userName = document.getElementById('userName').value;
    	const userProfile = document.getElementById('userProfile').value;
    	const userImage = document.getElementById('userImage').files[0];

    	let payload = {
        	name: userName,
        	profile: userProfile,
        	update_icon: false
    	};

    	if (userImage) {
        	const reader = new FileReader();
        	const base64data = await new Promise((resolve) => {
            	reader.readAsDataURL(userImage);
            	reader.onloadend = function () {
                	resolve(reader.result);
           	 	};
        	});

        	payload.update_icon = true;
        	payload.icon_base64 = base64data;
    	}

    	try {
        	const updateUserProfile = httpsCallable(functions, 'updateUserProfile');
        	const response = await updateUserProfile(payload);

        	if (response.data.status === 200) {
          		const getUserInfo = httpsCallable(functions, 'getUserInfo');
          		getUserInfo().then((result) => {
            		setUserInfo(result.data);
            		setUserName(result.data.user_name);
            		setUserProfile(result.data.profile);
          		}).catch((error) => {
            		console.error("getUserInfo Error", error);
          		});
          		setConfigOpen(false);
          		setIsLoading(false);
        	} else {
            	setConfigError('ユーザー名を入力してください。') 
            	setIsLoading(false);
            	console.error("updateUserProfile Error");
        	}
    	} catch (error) {
        	setConfigOpen(false);
        	setIsLoading(false);
        	console.error(error);
    	}
  	}

	const handleEmailPasswordConfirm = (event) => {
		event.preventDefault();
		setIsLoading(true);
		const currentEmail = document.getElementById('currentEmail').value;
		const currentPassword = document.getElementById('currentPassword').value;
		const newEmail = document.getElementById('newEmail').value;
		const newPassword = document.getElementById('newPassword').value;
		const newPasswordConfirm = document.getElementById('newPasswordConfirm').value;
		const credential = EmailAuthProvider.credential(currentEmail, currentPassword);
	
		reauthenticateWithCredential(user, credential).then(() => {
			if (newPassword && newEmail) { 
				if (newPassword !== newPasswordConfirm) {
					setIsLoading(false);
					setMailPasswordError("パスワードが一致していません。");
					return;
				} else if (newPassword.length < 6) {
					setIsLoading(false);
					setMailPasswordError("パスワードは6文字以上で記入してください。");
					return;
				} else {
					updatePassword(user, newPassword).then(() => {
						updateEmail(user, newEmail).then(() => {
							sendEmailVerification(user).then(() => {
								setMailPasswordOpen(false);
								setIsMailConfirmModalOpen(true);
								setIsLoading(false);
							}).catch((error) => {
								console.error('sendEmailVerification Error:', error);
							});
						}).catch((error) => {
							setIsLoading(false);
							switch (error.code) {
								case 'auth/email-already-in-use':
									setMailPasswordError('このメールアドレスは既に使用されています。');
									break;
								case 'auth/invalid-email':
									setMailPasswordError('不正なメールアドレス形式です。');
									break;
								default:
									setMailPasswordError('登録に失敗しました。もう一度お試しください。');
									break;
							}
						});
					}).catch((error) => {
						setIsLoading(false);
						console.error(error);
					});
				}
			} else if (newPassword && !newEmail) {
				if (newPassword !== newPasswordConfirm) {
					setIsLoading(false);
					setMailPasswordError("パスワードが一致していません。");
					return;
				} else if (newPassword.length < 6) {
					setIsLoading(false);
					setMailPasswordError("パスワードは6文字以上で記入してください。");
					return;
				} else {
					updatePassword(user, newPassword).then(() => {
						setMailPasswordOpen(false);
						setIsLoading(false);
					}).catch((error) => {
						setIsLoading(false);
						console.error(error);
					});
				}
			} else if (newEmail && !newPassword) {
				updateEmail(user, newEmail).then(() => {
					sendEmailVerification(user).then(() => {
						setMailPasswordOpen(false)
						setIsMailConfirmModalOpen(true);
						setIsLoading(false);
					}).catch((error) => {
						console.error('sendEmailVerification Error:', error);
					});
				}).catch((error) => {
					setIsLoading(false);
					switch (error.code) {
						case 'auth/email-already-in-use':
							setMailPasswordError('このメールアドレスは既に使用されています。');
							break;
						case 'auth/invalid-email':
							setMailPasswordError('不正なメールアドレス形式です。');
							break;
						default:
							setMailPasswordError('登録に失敗しました。もう一度お試しください。');
							break;
					}
				});
			} else {
				setMailPasswordOpen(false);
				setIsLoading(false);
			}
		})
		.catch((error) => {
			setIsLoading(false);
			setMailPasswordError("現在のメールアドレスまたはパスワードが正しくありません。");
			console.error('Error in reauthentication:', error);
		});
	};

  	const resendVerificationEmail = async () => {
    	try {
      		if (user) {
        		await sendEmailVerification(user);
        		setResendButtonDisabled(true);
        		setTimeout(() => setResendButtonDisabled(false), 60000);
      		} else {
        		console.error("No user is signed in");
      	}	
    	} catch (error) {
      		if (error.code === "auth/too-many-requests") {
        		setResendError("メールが届かない場合、1分後に再送信してください。");
      		} else {
        		console.error("Failed to resend verification email", error);
      		}
    	}
  	};

	const handlePasswordReset = async (email) => {
		if (!email) {
			setResetPassword("上の記入欄にメールアドレスを入力してください。");
			return;
		}
		sendPasswordResetEmail(auth, email)
			.then(() => {
				setLoginOpen(false)
				setIsResetPasswordModalOpen(true);
			})
			.catch((error) => {
				setResetPassword("メールアドレスが登録されていません。新規登録をしてください。")
			});
	}
	

  	return (
    	<>
      		{isRegisterOpen && (
       			<div className={styles.modal} onClick={handleClickOutside}>
          			<div className={styles.formWrapper} ref={modalRef}>
            			<input className={commonStyles.primaryInput} type="text" value={username} onChange={(e) => {setRegisterError(""); setUsername(e.target.value)}} placeholder="ユーザー名" required />
            			<input className={commonStyles.primaryInput} type="email" value={registerEmail} onChange={(e) => {setRegisterError(""); setRegisterEmail(e.target.value)}} placeholder="メールアドレス" required />
						<input className={commonStyles.primaryInput} type="password" value={registerPassword} onChange={(e) => {setRegisterError(""); setRegisterPassword(e.target.value)}} placeholder="パスワード" required />
						<input className={commonStyles.primaryInput} type="password" value={registerPasswordConfirm} onChange={(e) => {setRegisterError(""); setRegisterPasswordConfirm(e.target.value)}} placeholder="パスワードの確認" required />
            			{registerError && <p className={commonStyles.cautionText}>{registerError}</p>}
            			<div className={styles.google}>
              				<button className={commonStyles.primaryButton} onClick={() => {handleRegister(username, registerEmail, registerPassword, registerPasswordConfirm)}}>新規登録</button>
              				<button className={`${commonStyles.primaryButton} ${styles.googleButton}`} onClick={() => {handleGoogleRegister(); setRegisterOpen(false);}}>
                				<img className={styles.googleIcon} src={google_icon} alt="google icon" />
                				新規登録
              				</button>
            			</div>
            			<button className={commonStyles.underbarButton} onClick={() => setRegisterOpen(false)}>閉じる</button>
          			</div>
        		</div>
      		)}
      		{isRegisterModalOpen && (
        		<div className={styles.modal}>
          			<div className={styles.formWrapper}>
            			<p>認証メールを送信しました。</p>
            			<p><span className={styles.strong}>認証後ページをリロードをしてください。</span></p>
            			{resendError && <p>{resendError}</p>}
            			<button className={commonStyles.underbarButton} onClick={() => resendVerificationEmail()} disabled={resendButtonDisabled}>再送信する</button>
            			<button className={commonStyles.underbarButton} onClick={() => {setIsRegisterModalOpen(false); setRegisterOpen(true)}}>作り直す</button>
          			</div>
       			</div>
      		)}
			{isMailConfirmModalOpen && (
        		<div className={styles.modal}>
          			<div className={styles.formWrapper}>
            			<p>メールを<span className={styles.strong}>2通</span>送信しました。</p>
            			<p><span className={styles.strong}>新しいメールアドレスに送信される</span>認証メールで認証してください。</p>
            			<p><span className={styles.strong}>認証後ページをリロードをしてください。</span></p>
            			{resendError && <p>{resendError}</p>}
            			<button className={commonStyles.underbarButton} onClick={() => resendVerificationEmail()} disabled={resendButtonDisabled}>再送信する</button>
            			<button className={commonStyles.underbarButton} onClick={() => {setIsMailConfirmModalOpen(false); setRegisterOpen(true)}}>作り直す</button>
          			</div>
       			</div>
      		)}
      		{isResetPasswordModalOpen && (
        		<div className={styles.modal}>
          			<div className={styles.formWrapper}>
            			<p>パスワード変更メールを送信しました。</p>
            			<p>メールに記載されているURLにアクセスしてください。</p>
            			<p><span className={styles.strong}>パスワード変更後、ページをリロードをしてください。</span></p>
						<button className={commonStyles.underbarButton} onClick={() => {setIsResetPasswordModalOpen(false)}}>閉じる</button>
          			</div>
       			</div>
      		)}
      		{isLoginOpen && (
        		<div className={styles.modal} onClick={handleClickOutside}>
          			<div className={styles.formWrapper} ref={modalRef}>
            			<input className={commonStyles.primaryInput} type="email" value={email} onChange={(e) => {setLoginError(""); setResetPassword(""); handleChangeEmail(e)}}  placeholder="メールアドレス" required />
            			<input className={commonStyles.primaryInput} type="password" value={password} onChange={(e) => {setLoginError(""); handleChangePassword(e)}} placeholder="パスワード" required />
            			{loginError && <p className={commonStyles.cautionText}>{loginError}</p>}  
            			<div className={styles.google}>
              				<button className={commonStyles.primaryButton} onClick={() => {handleLogin(email, password)}}>ログイン</button>
              				<button className={`${commonStyles.primaryButton} ${styles.googleButton}`} onClick={() => {handleGoogleSignIn(); setLoginOpen(false);}}>
                				<img className={styles.googleIcon} src={google_icon} alt="google icon" />
                				ログイン 
              				</button>
            			</div>
            			<button className={commonStyles.underbarButton} onClick={() => {setLoginOpen(false); setRegisterOpen(true);}}>新規登録へ</button>
						{resetPassword && <p className={commonStyles.cautionText}>{resetPassword}</p>}  
						<button className={commonStyles.underbarButton} onClick={() => handlePasswordReset(email)}>パスワードを忘れた方</button>
            			<button className={commonStyles.underbarButton} onClick={() => setLoginOpen(false)}>閉じる</button>
          			</div>
        		</div>
      		)}
      		{isReviewOpen && (
        		<div className={styles.modal}>
          			<div className={styles.formWrapper}>
            			<textarea className={commonStyles.primaryTextArea} placeholder="140字以下でレビューを書く" requirUed />
            			<button className={commonStyles.primaryButton} onClick={() => setConfigOpen(false)}>投稿</button>
            			<button className={commonStyles.underbarButton} onClick={() => setReviewOpen(false)}>閉じる</button>
          			</div>
        		</div>
      		)}
      		{isDeleteConfirmOpen && (
        		<div className={styles.modal}>
          			<div className={styles.deleteConfirmBox}>
            			<p className={styles.deleteConfirmMessage}>本当にアカウントを削除しますか？</p>
            			<div className={styles.deleteButton}>
              				<button className={commonStyles.primaryButton} onClick={() => {
								if (user && user.providerData.some(pd => pd.providerId === 'password')) {setIsDeletePasswordModal(true); setDeleteConfirmOpen(false);
								} else if (user && user.providerData.some(pd => pd.providerId === 'google.com')) {handleDeleteAccount(); setDeleteConfirmOpen(false)}}}>
								削除する
							</button>
							<button className={commonStyles.underbarButton} onClick={() => setDeleteConfirmOpen(false)}>キャンセル</button>
            			</div>
          			</div>
        		</div>
      		)}
      		{isDeletePasswordModal && (
        		<div className={styles.modal}>
          			<form onSubmit={handleDeleteAccount} className={styles.formWrapper}>
            			<input className={commonStyles.primaryInput} type="password" id='delete_password' onChange={() => setDeletePasswordError('')} placeholder='パスワードを入力してください。'/>
            			{deletePasswordError && <p className={commonStyles.cautionText}>{deletePasswordError}</p>}
           				 <div className={styles.deleteButton}>
							<button className={commonStyles.primaryButton} type="submit">確認</button>
							<button className={commonStyles.underbarButton} onClick={() => setIsDeletePasswordModal(false)}>閉じる</button>
            			</div>
          			</form>
        		</div>
      		)}
      		{isConfigOpen && (
        		<div className={styles.modal} onClick={handleClickOutside}>
          			<form onSubmit={handleUserProfileSubmit} className={styles.formWrapper} ref={modalRef}>
            			<div className={styles.iconWrapper}>
              				プロフィール画像
              				<label className={styles.imageUpload}>
								<img className={styles.icon} src={image ? image : `data:image/png;base64,${userInfo.icon_base64}`} alt="User Icon" />
								<input type="file" id="userImage" className={styles.imageInput} onChange={handleImageChange} />
              				</label> 
            			</div>
            			<input className={commonStyles.primaryInput} type="text" id="userName" value={userName} maxLength={MAX_LENGTH_NAME} placeholder="ユーザー名を入力してください。" onChange={(e) => {setConfigError(''); handleUserNameChange(e)}} />
            			<textarea className={commonStyles.primaryTextArea} id="userProfile" value={userProfile} maxLength={MAX_LENGTH_PROFILE} placeholder="プロフィールを追加できます。" onChange={handleUserProfileChange} />
            			{configError && <p className={commonStyles.cautionText}>{configError}</p>}  
            			<button className={commonStyles.primaryButton} type="submit">変更</button>
            			{user && user.providerData.some(pd => pd.providerId === 'password') && (
              				<button className={commonStyles.underbarButton} type="button" onClick={() => {setConfigOpen(false); setMailPasswordOpen(true)}}>メールアドレス・パスワードの変更</button>
						)}
            			<button className={commonStyles.underbarButton} type="button" onClick={() => setConfigOpen(false)}>閉じる</button>
          			</form>
        		</div>
      		)}
      		{isMailPasswordOpen && (
        		<div className={styles.modal} onClick={handleClickOutside}>
          			<form onSubmit={handleEmailPasswordConfirm} className={styles.formWrapper} ref={modalRef}>
						<input className={commonStyles.primaryInput} type="email" id="currentEmail" value={currentEmail} placeholder="現在のメールアドレスを入力してください。" onChange={(e) => {setMailPasswordError(''); handleUserEmailChange(e)}}/>
						<input className={commonStyles.primaryInput} type="password" id="currentPassword" placeholder="現在のパスワードを入力してください。" onChange={() => setMailPasswordError('')} />
						<input className={commonStyles.primaryInput} type="email" id="newEmail" placeholder="新しいメールアドレスを入力できます。" onChange={() => setMailPasswordError('')}/>
						<input className={commonStyles.primaryInput} type="password" id="newPassword" placeholder="新しいパスワードを入力できます。" onChange={() => setMailPasswordError('')}/>
						<input className={commonStyles.primaryInput} type="password" id="newPasswordConfirm" placeholder="新しいパスワードの確認" onChange={() => setMailPasswordError('')}/>
						{mailPasswordError && <p className={commonStyles.cautionText}>{mailPasswordError}</p>}
						<button className={commonStyles.primaryButton} type="submit">変更</button>
						<button className={commonStyles.underbarButton} type="button" onClick={() => {setMailPasswordOpen(false); setConfigOpen(true)}}>プロフィールの変更</button>
						<button className={commonStyles.underbarButton} type="button" onClick={() => setMailPasswordOpen(false)}>閉じる</button>
          			</form>
        		</div>
      		)}
      		{registerAttention && (
        		<div className={styles.modal} onClick={handleClickOutside}>
          			<div className={styles.formWrapper} ref={modalRef}>
            			<p>新規登録がされていません。</p>
            			<button className={commonStyles.underbarButton} type="button" onClick={() => setRegisterAttention(false)}>閉じる</button>
          			</div>
       			</div>
      		)}
      		{deleteGoogleReauthenticate && (
        		<div className={styles.modal}>
          			<div className={styles.formWrapper}>
						<p>選択したアカウントが違います。ログインして再度実行してください。</p>  
						<button className={commonStyles.underbarButton} onClick={() => {setDeleteGoogleReauthenticate(false); handleLogout();}}>閉じる</button>
          			</div>
       			</div>
      		)}

      		<div className={`${commonStyles.overlay} ${isLoading ? commonStyles.active : ''}`}>
        		{isLoading && <Loading />}
      		</div>

			<Router>
				<Header isLogin={isLogin} userInfo={userInfo} handleLogin={() => setLoginOpen(true)} handleSignin={()=>setRegisterOpen(true)} handleLogout={handleLogout} handleDeleteAccount={() => setDeleteConfirmOpen(true)} setConfigOpen={setConfigOpen}/>
				<Routes>
					<Route exact path="/" element={ <First />} />
					<Route path="/userPage" element={user ? <UserPage userInfo={userInfo} /> : <Page404 />} />
					<Route path="/userPage/*" element={<UserPage userInfo={userInfo} />} />
					<Route path="/editor" element={user ? <Editor /> : <Page404 />} />
					<Route path="/usecase" element={user ? <Usecase userInfo={userInfo} /> : <Page404 />} />
					<Route path="/services/*" element={<Article setReviewOpen={setReviewOpen} setLoginOpen={setLoginOpen} />}/>
					<Route path="tags/*" element={<Tag/>}/>
					{/*<Route path="preview/article" element={<ArticlePreview/>}/>*/}
					<Route path="/search" element={<Search/>}/>
					<Route path="/test" element={<Test/>}/>
					<Route path='/terms' element={<Terms/>}/>
					<Route path='/privacy' element={<Privacy/>}/>
					<Route path='/product' element={<Product/>}/>
					<Route path='/use' element={<Use/>}/>
					<Route path='/all' element={<All/>}/>
					<Route path='/*' element={<Page404/>}/>
					<Route path='/mailRedirect__/auth/action' element={<MailRedirect/>}/>
				</Routes>
				<Footer/>
			</Router>
    	</>
  	);
}

export default App;