import commonStyles from './common_styles.module.css';
import footerStyles from './footer_styles.module.css';
import { Link } from 'react-router-dom';


const Footer = () => {

    return (
        <footer className={footerStyles.siteFooter}>
            <div className={footerStyles.footerLinksWrapper}>
                <div>
                    <p>About</p>
                    <ul>
                        <li><Link className={commonStyles.primaryA} to='/product' onClick={()=>window.scrollTo({top: 0})}>AI TechBaseとは</Link></li>
                        <li><Link className={commonStyles.primaryA} to='/use' onClick={()=>window.scrollTo({top: 0})}>使い方</Link></li>
                        {/*<li><Link className={commonStyles.primaryA}>よくある質問</Link></li>*/}
                    </ul>
                </div>
                <div>
                    <p>Legal</p>
                    <ul>
                        <li><Link className={commonStyles.primaryA} to='/terms' onClick={()=>window.scrollTo({top: 0})}>利用規約</Link></li>
                        <li><Link className={commonStyles.primaryA} to='/privacy' onClick={()=>window.scrollTo({top: 0})}>プライバシーポリシー</Link></li>
                    </ul>
                </div>
            </div>
            <p className={footerStyles.copyRight}>&#169; 2023 AI TechBase.</p>
        </footer>
    );
};

export default Footer;