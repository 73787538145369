import { useState, useLayoutEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../database/firebaseConfig';
import Loading from '../common/loading';
import ServiceCards from "../common/servicesCards";
import commonStyles from '../common/common_styles.module.css';
import styles from './styles.module.css';

const FavoriteExample = () => {
    const [favoriteServices, setFavoriteServices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [lastServiceId, setLastServiceId] = useState("");
    const [hasMore, setHasMore] = useState(true);

    const loadMoreServices = () => {
        const getFavoriteServices = httpsCallable(functions, 'getFavoriteServices');
        getFavoriteServices({
            limit: 10,
            service_id_start_after: lastServiceId
            }).then((result) => {
                const data = result.data;
                if (data.status != 200){
                    return;
                }
                setFavoriteServices(prevServices => [...prevServices, ...data.favorite_services]);

                if (data.favorite_services.length < 10) {
                    setHasMore(false);
                }

                setLastServiceId(data.favorite_services[data.favorite_services.length - 1].service_id);
                setIsLoaded(true);
            }).catch((error) => {
                setIsLoaded(true);
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        }
    
    useLayoutEffect(()=>{
        loadMoreServices();
    }, []);

    return (
        <>
            {isLoaded ? (
                <>
                    <ServiceCards services={favoriteServices}/>
                    {hasMore && <button onClick={loadMoreServices} className={`${commonStyles.primaryButton} ${styles.moreButton}`}>もっと見る</button>}
                </>
            ) : (
                <Loading/>
            )}
        </>
    );
}

export default FavoriteExample;