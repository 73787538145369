import { useState, useLayoutEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions, auth } from '../database/firebaseConfig';
import Loading from '../common/loading';
import ServiceCards from "../common/servicesCards";
import commonStyles from '../common/common_styles.module.css';
import styles from './styles.module.css';

const PostExample = () => {
    const [revisionedServices, setRevisionedServices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [lastServiceId, setLastServiceId] = useState("");
    const [hasMore, setHasMore] = useState(true);

    const loadMoreServices = () => {
        const getAddedOrRevisionedServices = httpsCallable(functions, 'getAddedOrRevisionedServices');
        getAddedOrRevisionedServices({
            limit: 10,
            service_id_start_after: lastServiceId,
            uid: auth.uid,
        }).then((result) => {
            const data = result.data;
            console.log(data)
            if (data.status != 200){
                return;
            }

            setRevisionedServices(prevServices => [...prevServices, ...data.services]);

            if (data.services.length < 10) {
                setHasMore(false);
            }
            
            setLastServiceId(data.services[data.services.length - 1].service_id);
            setIsLoaded(true);
        }).catch((error) => {
            setIsLoaded(true);
            console.log('Error', error);
        });
    }

    useLayoutEffect(()=>{
        loadMoreServices();
    }, []);

    return (
        <>
            {isLoaded ? (
                <>
                    <ServiceCards services={revisionedServices}/>
                    {hasMore && <button onClick={loadMoreServices} className={`${commonStyles.primaryButton} ${styles.moreButton}`}>もっと見る</button>}
                </>
            ) : (
                <Loading/>
            )}
        </>
    );
}

export default PostExample;
