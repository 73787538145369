export const serviceDetailsTemplate = {
    service_id: '',
    language: 'jpn',
    url: '',
    name: '',
    thumbnail_base64: '',
    long_description: '',
    short_description: '',
    publishers: [],
    environments: [],
    payments: [],
    published_at: '',
    revisioned_at: '',
    primary_usage: [],
    how_to_install: [],
    how_to_execute: [],
    payment_detail: [],
    tags: [],
    models: [],
    good_count: 0,
    view_count: 0,
    favorite_count: 0,
    user_unique: {
        set_good: false,
        set_favorite: false
    }
}

export const tagAndLabel = {
    work: '仕事',
    play: '遊び',
    video: '動画',
    image: '画像',
    painting: '絵',
    writing: '文章',
    sound: '音声',
    music: '音楽',
    data_analysis: 'データ分析',
    programming: 'プログラミング',
    search: '検索',
    summarizing: '要約',
    presentation: 'プレゼンテーション',
    task_management: 'タスク管理',
    education: '教育',
    research: '研究',
}

export const tags = [
    'work',
    'play',
    'video',
    'image',
    'painting',
    'writing',
    'sound',
    'music',
    'data_analysis',
    'programming',
    'search',
    'summarizing',
    'presentation',
    'task_management',
    'education',
    'research'
]

export const paymentAndLabel = {
    free: '無料あり',
    flat: '定額課金',
    quantity: '従量課金',
    once: '買い切り'
}

export const environmentAndLabel = {
    web: 'web',
    chrome: 'Chrome',
    firefox: 'Firefox',
    edge: 'Edge',
    safari: 'Safari',
    windows: 'Windows',
    windows10: 'Windows10',
    windows11: 'Windows11',
    mac: 'mac',
    unix: 'Unix',
    linux: 'Linux',
    ios: 'iOS',
    android: 'Android'
}

export const equipmentAndLabel = {
    ram8gb: '8GB RAM',
    ram16gb: '16GB RAM',
    ram32gb: '32GB RAM',
    ram64gb: '64GB RAM',
    intel_corei3: 'Intel Core i3',
    intel_corei5: 'Intel Core i5',
    intel_corei7: 'Intel Core i7',
    intel_corei9: 'Intel Core i9',
    amd_ryzen3: 'AMD Ryzen 3',
    amd_ryzen5: 'AMD Ryzen 5',
    amd_ryzen7: 'AMD Ryzen 7',
    amd_ryzen9: 'AMD Ryzen 9',
    nvidia_geforce_600series: 'NVIDIA Geforce 600シリーズ',
    nvidia_geforce_700series: 'NVIDIA Geforce 700シリーズ',
    nvidia_geforce_900series: 'NVIDIA Geforce 900シリーズ',
    nvidia_geforce_gt_1030: 'NVIDIA Geforce GT 1030',
    nvidia_geforce_gtx_1050_2gb: 'NVIDIA Geforce GT 1050 2GB',
    nvidia_geforce_gtx_1050_3gb: 'NVIDIA Geforce GT 1050 3GB',
    nvidia_geforce_gtx_1050_ti: 'NVIDIA Geforce GT 1050 Ti',
    nvidia_geforce_gtx_1060_3gb: 'NVIDIA Geforce GT 1060 3GB',
    nvidia_geforce_gtx_1050_6gb: 'NVIDIA Geforce GT 1060 6GB',
    nvidia_geforce_gtx_1070: 'NVIDIA GeForce GTX 1070',
    nvidia_geforce_gtx_1070_ti: 'NVIDIA GeForce GTX 1070 Ti',
    nvidia_geforce_gtx_1080: 'NVIDIA GeForce GTX 1080',
    nvidia_geforce_gtx_1080_ti: 'NVIDIA GeForce GTX 1080 Ti',
    nvidia_geforce_gtx_1650: 'NVIDIA GeForce GTX 1650',
    nvidia_geforce_gtx_1650_super: 'NVIDIA GeForce GTX 1650 Super',
    nvidia_geforce_gtx_1660: 'NVIDIA GeForce GTX 1660',
    nvidia_geforce_gtx_1660_super: 'NVIDIA GeForce GTX 1660 Super',
    nvidia_geforce_gtx_1660_ti: 'NVIDIA GeForce GTX 1660 Ti',
    nvidia_geforce_rtx_2060: 'NVIDIA GeForce RTX 2060',
    nvidia_geforce_rtx_2060_12gb: 'NVIDIA GeForce RTX 2060 12GB',
    nvidia_geforce_rtx_2060_super: 'NVIDIA GeForce RTX 2060 Super',
    nvidia_geforce_rtx_2070: 'NVIDIA GeForce RTX 2070',
    nvidia_geforce_rtx_2070_super: 'NVIDIA GeForce RTX 2070 Super',
    nvidia_geforce_rtx_2080: 'NVIDIA GeForce RTX 2080',
    nvidia_geforce_rtx_2080_super: 'NVIDIA GeForce RTX 2080 Super',
    nvidia_geforce_rtx_2080_ti: 'NVIDIA GeForce RTX 2080 Ti',
    nvidia_geforce_rtx_3050: 'NVIDIA GeForce RTX 3050',
    nvidia_geforce_rtx_3060: 'NVIDIA GeForce RTX 3060',
    nvidia_geforce_rtx_3060_ti: 'NVIDIA GeForce RTX 3060 Ti',
    nvidia_geforce_rtx_3070: 'NVIDIA GeForce RTX 3070',
    nvidia_geforce_rtx_3070_ti: 'NVIDIA GeForce RTX 3070 Ti',
    nvidia_geforce_rtx_3080: 'NVIDIA GeForce RTX 3080',
    nvidia_geforce_rtx_3080_12gb: 'NVIDIA GeForce RTX 3080 12GB',
    nvidia_geforce_rtx_3080_ti: 'NVIDIA GeForce RTX 3080 Ti',
    nvidia_geforce_rtx_3090: 'NVIDIA GeForce RTX 3090',
    nvidia_geforce_rtx_3090_ti: 'NVIDIA GeForce RTX 3090 Ti',
    nvidia_geforce_rtx_4070: 'NVIDIA GeForce RTX 4070',
    nvidia_geforce_rtx_4070_ti: 'NVIDIA GeForce RTX 4070 Ti',
    nvidia_geforce_rtx_4080: 'NVIDIA GeForce RTX 4080',
    nvidia_geforce_rtx_4090: 'NVIDIA GeForce RTX 4090',
    nvidia_titan_x: 'NVIDIA Titan X',
    nvidia_titan_xp: 'NVIDIA Titan Xp',
    nvidia_titan_rtx: 'NVIDIA Titan RTX',
    nvidia_titan_v: 'NVIDIA Titan V',
    amd_radeon_r5_200series: 'AMD Radeon R5 200シリーズ',
    amd_radeon_r7_200series: 'AMD Radeon R7 200シリーズ',
    amd_radeon_r9_200series: 'AMD Radeon R9 200シリーズ',
    amd_radeon_r7_300series: 'AMD Radeon R7 300シリーズ',
    amd_radeon_r9_300series: 'AMD Radeon R9 300シリーズ',
    amd_radeon_rx_460: 'AMD Radeon RX 460',
    amd_radeon_rx_470: 'AMD Radeon RX 470',
    amd_radeon_rx_480: 'AMD Radeon RX 480',
    amd_radeon_rx_550: 'AMD Radeon RX 550',
    amd_radeon_rx_560: 'AMD Radeon RX 560',
    amd_radeon_rx_570: 'AMD Radeon RX 570',
    amd_radeon_rx_580: 'AMD Radeon RX 580',
    amd_radeon_rx_590: 'AMD Radeon RX 590',
    amd_radeon_rx_vega_56: 'AMD Radeon RX Vega 56',
    amd_radeon_rx_vega_64: 'AMD Radeon RX Vega 64',
    amd_radeon_rx_vega_64_lc: 'AMD Radeon RX Vega 64 Liquid Cooled',
    amd_radeon_VII: 'AMD Radeon VII',
    amd_radeon_rx_5500_xt: 'AMD Radeon RX 5500 XT',
    amd_radeon_rx_5600_xt: 'AMD Radeon RX 5600 XT',
    amd_radeon_rx_5700: 'AMD Radeon RX 5700',
    amd_radeon_rx_5700_xt: 'AMD Radeon RX 5700 XT',
    amd_radeon_rx_6400: 'AMD Radeon RX 6400',
    amd_radeon_rx_6500_xt: 'AMD Radeon RX 6500 XT',
    amd_radeon_rx_6600: 'AMD Radeon RX 6600',
    amd_radeon_rx_6600_xt: 'AMD Radeon RX 6600 XT',
    amd_radeon_rx_6650_xt: 'AMD Radeon RX 6650 XT',
    amd_radeon_rx_6700_xt: 'AMD Radeon RX 6700 XT',
    amd_radeon_rx_6750_xt: 'AMD Radeon RX 6750 XT',
    amd_radeon_rx_6800: 'AMD Radeon RX 6800',
    amd_radeon_rx_6800_xt: 'AMD Radeon RX 6800 XT',
    amd_radeon_rx_6900_xt: 'AMD Radeon RX 6900 XT',
    amd_radeon_rx_6950_xt: 'AMD Radeon RX 6950 XT',
    amd_radeon_rx_7900_xt: 'AMD Radeon RX 7900 XT',
    amd_radeon_rx_7900_xtx: 'AMD Radeon RX 7900 XTX',
}