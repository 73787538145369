import errorMessageStyles from './errorMessage_styles.module.css';


const ErrorMessage = ({message}) => {

    return (
        <div className={errorMessageStyles.messageWrapper}>
            <p className={errorMessageStyles.logo}>Error</p>
            <p>{message}</p>
        </div>
    );
};

export default ErrorMessage;