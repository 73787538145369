import usecaseCardsStyles from "./usecaseCards_styles.module.css";
import commonStyles from "../common/common_styles.module.css";
import { getDiffDays } from "../common/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faHashtag, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { tagAndLabel } from "../common/constants";

const UseCasesCards = ({sendUsecases, isEdit}) => {
    const maxContentLength = 100;  

    return (
        <ul className={usecaseCardsStyles.appList}>
            {
                sendUsecases.map((usecase, i) => {
                    let contentsAll = usecase.contents.reduce((prev, current, index) => {
                        if (current.content_type !== 'paragraph') {
                            return prev; 
                        }
                        let contentToAdd = index > 0 && current.content_type ? "\n" + current.content : current.content;
                        return prev + contentToAdd;
                    }, "");

                    let contentsPreview = contentsAll.length > maxContentLength
                        ? contentsAll.slice(0, maxContentLength) + "..."
                        : contentsAll;

                    return  <li key={i}>
                                <div className={usecaseCardsStyles.card}>
                                    {
                                        usecase.service_info.map((service, j) => (
                                            <div key={j} className={usecaseCardsStyles.imageName}> 
                                                <Link to={`/services/${service.service_name}?id=${usecase.usecase_id}`} onClick={() => window.scrollTo({top: 300, behavior: 'smooth'})}>
                                                    <img className={usecaseCardsStyles.thumbnail} src={service.thumbnail_base64 ? (service.thumbnail_base64.startsWith('data:image') ? service.thumbnail_base64 : `data:image/jpeg;base64,${service.thumbnail_base64}`) : ''}></img>
                                                </Link>
                                                <Link to={`/services/${service.service_name}?id=${usecase.usecase_id}`} onClick={() => window.scrollTo({top: 300, behavior: 'smooth'})}>
                                                    <p className={usecaseCardsStyles.appName}>{service.service_name}</p>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={usecaseCardsStyles.appEdit}>
                                    <div className={usecaseCardsStyles.appBaseInfo}>
                                        <span><b className={usecaseCardsStyles.strong}>{usecase.usecase_view}</b> views</span>
                                        <span><FontAwesomeIcon className={usecaseCardsStyles.thumbUp} icon={faThumbsUp}/> {usecase.usecase_good}</span>
                                    </div>
                                    {isEdit && <Link to={`/usecase?id=${usecase.usecase_id}`} onClick={window.scrollTo({top: 0})}>
                                        <FontAwesomeIcon icon={faEdit} size="lg"/>
                                    </Link>}
                                </div>
                                {
                                    usecase.tags ?
                                    <div className={usecaseCardsStyles.tags}>
                                        {
                                            usecase.tags.map((tag, j)=>{
                                                return <Link key={j} to={`/tags/${tag}`} className={commonStyles.tagPrimaryColor} onClick={()=>window.scrollTo({top: 0})}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</Link>
                                            })
                                        }
                                    </div>
                                    :
                                    <></>
                                }  
                                <div className={usecaseCardsStyles.contentsPreview}>
                                    <p className={usecaseCardsStyles.strong}>{usecase.title}</p>
                                    {contentsPreview}
                                </div>
                            </li>
                })
            }
        </ul>
    );
}

export default UseCasesCards;
