import React, { useState, useEffect } from 'react'; 
import PostExample from "./postExample.js";
//import QuestionAnswerExample from "./QuestionAnswerExample.js";
import FavoriteExample from "./favoriteExample.js";
import UsecaseExample from "./usecaseExample.js";
import FavoriteUsecaseExample from "./favoriteUsecaseExample.js";
import OtherPostExample from "./otherPostExample.js";
import OtherUsecaseExample from "./otherUsecaseExample.js";
import SideBar from '../common/sidebar';
import Loading from '../common/loading';
import commonStyles from '../common/common_styles.module.css';
import styles from "./styles.module.css";
import { useLocation, useSearchParams } from 'react-router-dom'; 
import { functions } from '../database/firebaseConfig'; 
import { httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
  
const UserPage = ({ userInfo }) => {
    const myTabs = ['favorite_service', 'favorire_usecase', 'posted_service', 'posted_usecase'];
    const [query] = useSearchParams(); 
    let tab = query.get('tab');
    tab = myTabs.includes(tab) ? tab : 'favorite_service';
    const [myActiveTab, setMyActiveTab] = useState(tab);
    const [otherActiveTab, setOtherActiveTab] = useState('posted_service');
    const otherTabs = ['posted_service', 'posted_usecase'];
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({ name: null, profile: null, icon: null });
    const [userRanking, setUserRanking] = useState(null);
    const [userCount, setUserCount] = useState(null);
    const [viewCount, setViewCount] = useState(null);
    const paths = useLocation().pathname.split('/').filter(Boolean);
   
    useEffect(() => {
        if (paths.length == 2) {
            const getUserPublicData = httpsCallable(functions, 'getUserPublicData');
            getUserPublicData({uid: decodeURI(paths[1])})
            .then((result) => {
                setUser({
                    name: result.data.user_name,
                    profile: result.data.profile,
                    icon: result.data.icon_base64,
                });
                const getTotalViewCountRankingByUserId = httpsCallable(functions, 'getTotalViewCountRankingByUserId');
                getTotalViewCountRankingByUserId({uid: decodeURI(paths[1])})
                .then((result) => {
                    const viewData = result.data;
                    if (viewData.status !== 200) {
                        return;
                    }
                    const getUserCount = httpsCallable(functions, 'getUserCount');
                    getUserCount()
                    .then((res) => {
                        const countData = res.data;
                        if (countData.status !== 200) {
                            return;
                        }
                        setUserRanking(viewData.ranking);
                        setViewCount(viewData.total_usecase_view_count);
                        setUserCount(countData.user_count);
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {                  
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }).catch((error) => {
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {                  
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
            }).catch((error) => {
                console.log(error);
            });
        } else if (userInfo) {
            setUser({
                name: userInfo.user_name,
                profile: userInfo.profile,
                icon: userInfo.icon_base64,
            });
            const getTotalViewCountRanking = httpsCallable(functions, 'getTotalViewCountRanking');
            getTotalViewCountRanking()
            .then((result) => {
                const viewData = result.data;
                if (viewData.status !== 200) {
                    return;
                }
                const getUserCount = httpsCallable(functions, 'getUserCount');
                getUserCount()
                .then((res) => {
                    const countData = res.data;
                    if (countData.status !== 200) {
                        return;
                    }
                    setUserRanking(viewData.ranking);
                    setViewCount(viewData.total_usecase_view_count);
                    setUserCount(countData.user_count);
                }).catch((error) => {
                    if (error.response) {
                        console.log('Error response data:', error.response.data);
                        console.log('Error response status:', error.response.status);
                        console.log('Error response headers:', error.response.headers);
                    } else if (error.request) {                  
                        console.log('Error request:', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }).catch((error) => {
                if (error.response) {
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {                  
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        } else {
            setIsLoading(true);
        }
        if (!user.name) {
            setIsLoading(true);
        }
    }, [user.name, userInfo]);
    
    const myRenderContent = () => {
        switch(myActiveTab) {
            case 'favorite_service':
                return <FavoriteExample />
            case 'posted_service':
                return <PostExample />
            case 'posted_usecase':
                return <UsecaseExample />
            case 'favorire_usecase':
                return <FavoriteUsecaseExample/>
            /*case 'Q&A':
                return <QuestionAnswerExample />*/
        default:
            return null;
        }
    };

    const otherRenderContent = () => {
        const uid = decodeURI(paths[1])
        switch(otherActiveTab) {
            case 'posted_service':
                return <OtherPostExample uid={uid}/>
            case 'posted_usecase':
                return <OtherUsecaseExample uid={uid}/>
            /*case 'Q&A':
                return <QuestionAnswerExample />*/
        default:
            return null;
        }
    };

    return (
        !user.name 
        ? (
            <div className={`${commonStyles.overlay} ${isLoading ? commonStyles.active : ''}`}>
                {isLoading && <Loading />}
            </div>
        )
        : paths.length == 2 
            ? (
                <> 
                    <div className={styles.userInfo}>
                        <img className={styles.icon} src={`data:image/jpg;base64,${user.icon}`} alt="User Icon" />
                        <div>
                            <div className={styles.userName}>{user.name}</div>
                            <div className={styles.userProfile}>{user.profile}</div>
                            <div className={styles.viewAndRanking}>
                                <div className={`${styles.userProfile} ${styles.viewCount}`}>閲覧数 ：<span className={styles.userName}>{viewCount} </span> view</div>
                                <div className={`${styles.userProfile} ${styles.viewCount}`}>順位 ： <span className={styles.userName}>{userRanking}</span> 位/ {userCount}人</div>
                            </div>
                        </div>
                        <div className={styles.userRanking}>
                            <div className={styles.userProfile}>閲覧数 ： <span className={styles.userName}>{viewCount}</span> view</div>
                            <div className={styles.userProfile}>順位 ： <span className={styles.userName}>{userRanking}</span> 位/ {userCount}人</div>
                        </div>
                    </div>
                    <main className={`${commonStyles.mainWithSideBarR} ${commonStyles.primaryMain}`}>
                        <div className={commonStyles.sectionWrapper}>    
                            <section className={commonStyles.secondarySection}>
                                <div className={`${commonStyles.tabContainer} ${commonStyles.marginBottomMiddle}`}>
                                {otherTabs.map(tab => {
                                        let tabContent;
                                        switch (tab) {
                                            case 'posted_service':
                                                tabContent = 
                                                <>
                                                    <span className={`${styles.tabIcon} ${otherActiveTab === tab ? commonStyles.tabItemActive : ''}`}>サービス</span>
                                                    <FontAwesomeIcon icon={faEdit} className={`${styles.iconSize} ${otherActiveTab === tab ? styles.activeIcon : ''}`}/>
                                                </>
                                                break;
                                            case 'posted_usecase':
                                                tabContent = 
                                                <>
                                                    <span className={`${styles.tabIcon} ${otherActiveTab === tab ? commonStyles.tabItemActive : ''}`}>使用例</span>
                                                    <FontAwesomeIcon icon={faEdit} className={`${styles.iconSize} ${otherActiveTab === tab ? styles.activeIcon : ''}`}/>
                                                </>
                                                break;
                                            default:
                                                tabContent = tab;
                                        }

                                        return (
                                            <div 
                                                key={tab} 
                                                className={`${commonStyles.tabItem} ${otherActiveTab === tab ? commonStyles.tabItemActive : ''}`} 
                                                onClick={() => setOtherActiveTab(tab)}
                                            >
                                                {tabContent}
                                            </div>
                                        );
                                    })}
                                </div> 
                                {otherRenderContent()}
                            </section>
                        </div>
                        <div className={styles.sideWrapper}>
                            <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
                        </div>
                    </main>
                </>
            )
            : (
                <>
                    <div className={styles.userInfo}>
                        <img className={styles.icon} src={`data:image/jpg;base64,${user.icon}`} alt="User Icon" />
                        <div>
                            <div className={styles.userName}>{user.name}</div>
                            <div className={styles.userProfile}>{user.profile}</div>
                            <div className={styles.viewAndRanking}>
                                <div className={`${styles.userProfile} ${styles.viewCount}`}>閲覧数 ：<span className={styles.userName}>{viewCount} </span> view</div>
                                <div className={`${styles.userProfile} ${styles.viewCount}`}>順位 ： <span className={styles.userName}>{userRanking}</span> 位/ {userCount}人</div>
                            </div>
                        </div>
                        <div className={styles.userRanking}>
                            <div className={styles.userProfile}>閲覧数 ： <span className={styles.userName}>{viewCount}</span> view</div>
                            <div className={styles.userProfile}>順位 ： <span className={styles.userName}>{userRanking}</span> 位/ {userCount}人</div>
                        </div>
                    </div>
                    <main className={`${commonStyles.mainWithSideBarR} ${commonStyles.primaryMain}`}>
                        <div className={commonStyles.sectionWrapper}>    
                            <section className={commonStyles.secondarySection}>
                                <div className={`${commonStyles.tabContainer} ${commonStyles.tabContainerSize} ${commonStyles.marginBottomMiddle}`}>
                                    {myTabs.map(tab => {
                                        let tabContent;
                                        switch (tab) {
                                            case 'favorite_service':
                                                tabContent = 
                                                <>
                                                    <span className={`${styles.tabIcon} ${myActiveTab === tab ? commonStyles.tabItemActive : ''}`}>サービス</span>
                                                    <FontAwesomeIcon icon={faBookmark} className={`${styles.iconSize} ${myActiveTab === tab ? styles.activeIcon : ''}`}/>
                                                </>
                                                break;
                                            case 'favorire_usecase':
                                                tabContent = 
                                                <>
                                                    <span className={`${styles.tabIcon} ${myActiveTab === tab ? commonStyles.tabItemActive : ''}`}>使用例</span>
                                                    <FontAwesomeIcon icon={faBookmark} className={`${styles.iconSize} ${myActiveTab === tab ? styles.activeIcon : ''}`}/>
                                                </>
                                                break;
                                            case 'posted_service':
                                                tabContent = 
                                                <>
                                                    <span className={`${styles.tabIcon} ${myActiveTab === tab ? commonStyles.tabItemActive : ''}`}>サービス</span>
                                                    <FontAwesomeIcon icon={faEdit} className={`${styles.iconSize} ${myActiveTab === tab ? styles.activeIcon : ''}`}/>
                                                </>
                                                break;
                                            case 'posted_usecase':
                                                tabContent = 
                                                <>
                                                    <span className={`${styles.tabIcon} ${myActiveTab === tab ? commonStyles.tabItemActive : ''}`}>使用例</span>
                                                    <FontAwesomeIcon icon={faEdit} className={`${styles.iconSize} ${myActiveTab === tab ? styles.activeIcon : ''}`}/>
                                                </>
                                                break;
                                            default:
                                                tabContent = tab;
                                        }

                                        return (
                                            <div 
                                                key={tab} 
                                                className={`${commonStyles.tabItem} ${myActiveTab === tab ? commonStyles.tabItemActive : ''}`} 
                                                onClick={() => setMyActiveTab(tab)}
                                            >
                                                {tabContent}
                                            </div>
                                        );
                                    })}
                                </div>
                                {myRenderContent()}
                            </section>
                        </div>
                        <div className={styles.sideWrapper}>
                            <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
                        </div>
                    </main>
                </>
            )
    );    
} 

export default UserPage;