import serviceCardsStyles from "./serviceCards_styles.module.css";
import commonStyles from "../common/common_styles.module.css";
import { getDiffDays } from "../common/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { tagAndLabel } from "../common/constants";

const ServiceCards = ({services}) => {

    return (
        <ul className={`${serviceCardsStyles.appList}`}>
            {
                services.map((service, i)=>{
                    return <li key={i}>
                                <Link to={`/services/${service.service_name}`} onClick={()=>window.scrollTo({top: 0})}>
                                    <img className={serviceCardsStyles.thumbnail} src={service.thumbnail_base64 ? (service.thumbnail_base64.startsWith('data:image') ? service.thumbnail_base64 : `data:image/jpeg;base64,${service.thumbnail_base64}`) : ''}></img>
                                </Link>
                                <p className={serviceCardsStyles.publishedAt}>{getDiffDays(new Date(service.revisioned_at))}日前</p>
                                <p className={serviceCardsStyles.appName}><Link to={`/services/${service.service_name}`} onClick={()=>window.scrollTo({top: 0})}>{service.service_name}</Link></p>
                                <div className={serviceCardsStyles.appBaseInfo}>
                                    <span><b className={serviceCardsStyles.count}>{service.view_count}</b><FontAwesomeIcon /> views</span>
                                    <span><FontAwesomeIcon className={serviceCardsStyles.thumbUp} icon={faThumbsUp}/> {service.good_count}</span>
                                    {service.added === true && <span className={serviceCardsStyles.new}>新規</span>}
                                    {service.added === false && <span className={serviceCardsStyles.edit}>編集</span>}
                                </div>
                                {
                                    service.tags ?
                                    <div className={serviceCardsStyles.tags}>
                                        {
                                            service.tags.map((tag, j)=>{
                                                return <Link key={j} to={`/tags/${tag}`} className={commonStyles.tagPrimaryColor} onClick={()=>window.scrollTo({top: 0})}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</Link>
                                            })
                                        }
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    service.short_description_jpn ?
                                    <div className={serviceCardsStyles.shortDescription}>{service.short_description_jpn}</div>
                                    :
                                    <></>
                                }
                            </li>
                })
            }
        </ul>
    );
}

export default ServiceCards;