import React, { useEffect, useState, useRef } from 'react';
import { applyActionCode, confirmPasswordReset } from "firebase/auth"; 
import { auth } from '../database/firebaseConfig'; 
import styles from './mailRedirect_styles.module.css';
import AppStyles from '../App_styles.module.css';
import commonStyles from '../common/common_styles.module.css';

const MailRedirect = () => {
    const [message, setMessage] = useState('確認中...');
    const verification = useRef(false);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const verifyEmail = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const mode = urlParams.get('mode');
            const actionCode = urlParams.get('oobCode');

            if (mode === 'verifyEmail' && actionCode) {
                try {
                    await applyActionCode(auth, actionCode);
                    verification.current = true;
                    setMessage(<span>認証されました。<a href="/" className={styles.strong}>こちら</a>をクリックし、ログインしてください。</span>);
                } catch (error) {
                    if (!verification.current) {
                        setMessage('認証エラーが発生しました。再度実行してください。');
                    }
                    else {
                        setMessage(<span>認証されました。<a href="/" className={styles.strong}>こちら</a>をクリックし、ログインしてください。</span>);
                    }
                }
            }
            if (mode === 'recoverEmail' && actionCode) {
                try {
                    await applyActionCode(auth, actionCode);
                    verification.current = true;
                    setMessage(<span>メールアドレスを元に戻しました。引き続きご利用になるには、<a href="/" className={styles.strong}>こちら</a>をクリックしログインしてください。</span>);
                } catch (error) {
                    if (!verification.current) {
                        setMessage('認証エラーが発生しました。再度実行してください。');
                    }
                    else {
                        setMessage(<span>メールアドレスを元に戻しました。引き続きご利用になるには、<a href="/" className={styles.strong}>こちら</a>をクリックしログインしてください。</span>);
                    }
                }
            }
            if (mode === 'resetPassword' && actionCode) {
                setShowResetPasswordForm(true);
                verification.current = true;
                setMessage(<span>パスワードの再設定をしてください。</span>);
                if (!verification.current) {
                    setMessage('認証エラーが発生しました。再度実行してください。');
                }
                else {
                    setMessage(<span>パスワードの再設定をしてください。</span>);
                }
            }
        };
        verifyEmail();
    }, []);

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        const newPassword = event.target.elements.newPassword.value;
        const confirmNewPassword = event.target.elements.confirmNewPassword.value;

        if (newPassword !== confirmNewPassword) {
            setErrorMessage('パスワードが一致しません。');
            return;
        }
        else if (newPassword.length < 6) {
            setErrorMessage('パスワードは6文字以上で記入してください。');
            return;
        }

        try {
            const urlParams = new URLSearchParams(window.location.search);
            const actionCode = urlParams.get('oobCode');
            await confirmPasswordReset(auth, actionCode, newPassword);
            setMessage(<span>パスワードを再設定しました。引き続きご利用になるには、<a href="/" className={styles.strong}>こちら</a>をクリックしログインしてください。</span>);
            setShowResetPasswordForm(false);
        } catch (error) {
            setErrorMessage('エラーが発生しました。再度実行してください。');
        }
    };

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.subject}>ユーザー認証</h1>
            <p id="message" className={styles.verifyEmail}>{message}</p>
            {showResetPasswordForm && (
                <form onSubmit={handlePasswordReset} className={`${AppStyles.formWrapper} ${styles.formWrapper}`}>
                    <input className={commonStyles.primaryInput} type="password" name="newPassword" placeholder="新しいパスワード" onChange={() => setErrorMessage('')}/>
                    <input className={commonStyles.primaryInput} type="password" name="confirmNewPassword" placeholder="新しいパスワードの確認" onChange={() => setErrorMessage('')}/>
                    {errorMessage && <p className={commonStyles.cautionText}>{errorMessage}</p>}
                    <button className={commonStyles.primaryButton} type="submit">変更</button>
                </form>
            )}
        </div>
    );
};

export default MailRedirect;


