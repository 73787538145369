import React, { useState, useLayoutEffect, useRef } from 'react';
import usecaseStyles from './useCase_styles.module.css';
import articleStyles from '../article/article_styles.module.css';
import commonStyles from '../common/common_styles.module.css';
import P from './p';
import { functions, auth } from '../database/firebaseConfig'; 
import { httpsCallable } from "firebase/functions";
import Loading from '../common/loading';
import { useSearchParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Page404 from '../common/page404';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faHashtag, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp, faBookmark } from '@fortawesome/free-regular-svg-icons';
import { tagAndLabel } from '../common/constants';
import { Helmet } from 'react-helmet-async';
import PreviewImg from '../img/preview.png';

const Usecase = ({ userInfo }) => {
    const [usecaseDetails, setUsecaseDetails] = useState({
        title: '',
        serviceNames: [],
        tags: [],
        contents: [
            {content_type: 'headline', content: '使用例１'},
            {content_type: 'paragraph', content: '方法、手順など'},
            {content_type: 'small_headline', content: '作品'},
            {content_type: 'image', content: ''},
        ]
    });

    //const [initialUsecaseDetails, setInitialUsecaseDetails] = useState({});

    const [cautions, setCautions] = useState({
        title: '',
        serviceName: '',
        tag: '',
    });

    //編集履歴
    const [editorHistory, setEditorHistory] = useState([]);
    const [currentEditorHistory, setCurrentHistory] = useState(0);
    const EDITOR_HISTORY_LIMIT = 50; //履歴の最大数

    const inputTitle = useRef();
    const inputServiceName = useRef();
    const selectTag = useRef();

    const MAX_LENGTH_TITLE = 256;
    const MAX_LENGTH_SERVICE_NAME = 256;
    const MAX_LENGTH_SERVICE_NAMES = 5;
    const MAX_LENGTH_TAGS = 4;
    const MAX_LENGTH_DOCUMENT_CONTENTS = 100;
    const MAX_BYTES_DOCUMENT_IMAGE = 2 * 1024 * 1024;

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const queryUsecaseId = searchParams.get('id');
    const queryApp = searchParams.get('app');
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    //新規投稿か更新か
    const [isNew, setIsNew] = useState(false);

    const [submissionMessage, setSubmissionMessage] = useState("");
    const [submissionError, setSubmissionError] = useState(false);
    const [successCheck, setSuccessCheck] = useState(false);
    const [predictServiceNames, setPredictServiceNames] = useState([]);
    const [inputName, setInputName] = useState("");
    const [isOpenPreview, setIsOpenPreview] = useState(false);

    useLayoutEffect(() => {
        if (queryUsecaseId == null) {
            setIsError(false);
            setIsInitialized(true);
            setIsNew(true);
            addEditorHistory(usecaseDetails);
            let serviceNames = [];
            if (queryApp) {
                serviceNames = [queryApp];
            }
            setUsecaseDetails({
                title: '',
                serviceNames: serviceNames,
                tags: [],
                contents: [
                    {content_type: 'headline', content: '使用例1'},
                    {content_type: 'paragraph', content: '方法、手順など'},
                    {content_type: 'small_headline', content: '作品'},
                    {content_type: 'image', content: ''},
                ]
            });
            return;
        }
        const getUsecaseByUsecaseId = httpsCallable(functions, 'getUsecaseByUsecaseId');
        getUsecaseByUsecaseId({
            usecase_id: queryUsecaseId,
        }).then (async(result) => {
            const data = result.data;
            if ((data.status != 200) || (data.poster_uid != auth.currentUser.uid)) {
                setIsNew(false);
                setIsInitialized(true);
                setIsError(true);
                return;
            }
            const getImageAsBase64 = httpsCallable(functions, 'getImageAsBase64');
            const contents = await Promise.all(data.contents.map(async(p)=>{
                if (p.content_type=='image'){
                    return await getImageAsBase64({
                        file_name: p.file_name,
                        image_type: 'usecase_contents'
                        }).then ((result) => {
                            if (result.data.status != 200){
                                setIsError(true);
                                return p;
                            }
                            return {...p, content: result.data.image_base64};
                        }).catch((error) => {
                            setIsError(true);
                            return p;
                        });
                } else {
                    return p;
                }
            }));

            setIsNew(false);
            setIsInitialized(true);
            setIsError(false);
            setUsecaseDetails({
                title: data.title,
                serviceNames: data.service_names,
                tags: data.tags,
                contents: contents
            })
            addEditorHistory(data);

            /*setInitialUsecaseDetails({
                contents: contents,
            });*/

        }).catch((error) => {
            setIsInitialized(true);
            setIsError(true);
            if (error.response) {
                console.log('Error response data:', error.response.data);
                console.log('Error response status:', error.response.status);
                console.log('Error response headers:', error.response.headers);
            } else if (error.request) {
                console.log('Error request:', error.request);
            } else {
                console.log('Error', error.message);
            }
        });
        
    }, [location]);

    const resetAtAction = () => {
        setSubmissionMessage('');
    }

    const backEditorHistory = () => {
        if(currentEditorHistory<=1){
            return;
        }
        setUsecaseDetails(editorHistory[currentEditorHistory-2]);
        setCurrentHistory(currentEditorHistory-1);
        resetAtAction();
    }

    const forwardEditorHistory = () => {
        if(currentEditorHistory>EDITOR_HISTORY_LIMIT || currentEditorHistory>=editorHistory.length){
            return;
        }
        setUsecaseDetails(editorHistory[currentEditorHistory]);
        setCurrentHistory(currentEditorHistory+1);
        resetAtAction();
    }

    const addEditorHistory = (history) => {
        if(currentEditorHistory>EDITOR_HISTORY_LIMIT){
            return;
        }
        setEditorHistory([...editorHistory.slice(0, currentEditorHistory), history]);
        setCurrentHistory(currentEditorHistory+1);
    }

    const handleChangeTitle = () => {
        setCautions({...cautions, title: ''});
        setSubmissionMessage('');
        const currentUsecaseDetails = {...usecaseDetails, title: inputTitle.current.value};
        setUsecaseDetails(currentUsecaseDetails);
        addEditorHistory(currentUsecaseDetails);
    }

    const handleChangeName = () => {
        setCautions({...cautions, serviceName: ''});
        setSubmissionMessage('');
        const currentUsecaseDetails = {...usecaseDetails, name: inputServiceName.current.value};
        setUsecaseDetails(currentUsecaseDetails);
        addEditorHistory(currentUsecaseDetails);
        if(inputServiceName.current.value!=''){
            const getServiceNamesByKeyword = httpsCallable(functions, 'getServiceNamesByKeyword');
            getServiceNamesByKeyword({
                keyword: inputServiceName.current.value,
                limit: 5
            }).then(response => {
                if(response.data.status === 200) {
                    setPredictServiceNames(response.data.service_names);
                } else {
                    setPredictServiceNames([]);
                }
            }).catch(error => {
                setPredictServiceNames([]);
            })
        } else {
            setPredictServiceNames([]);
        }
        resetAtAction();
    }

    const isInputName = (event) => {
        setInputName(event.target.value);
    }

    const handleClickAddServiceName = (event, serviceName) => {
        event.preventDefault();

        setPredictServiceNames([]);
        setInputName("")
    
        if (!serviceName) {
            serviceName = inputServiceName.current.value;
        }
    
        if (serviceName == '') {
            setCautions({...cautions, serviceName: 'サービス名を入力してください。'});
            return;
        }
    
        if ((!usecaseDetails.serviceNames.includes(serviceName)) && (usecaseDetails.serviceNames.length < MAX_LENGTH_SERVICE_NAMES)) {
            const currentUsecaseDetails = {...usecaseDetails, serviceNames: [...usecaseDetails.serviceNames, serviceName]};
            setUsecaseDetails(currentUsecaseDetails)
            addEditorHistory(currentUsecaseDetails);
        }
        
        inputServiceName.current.value = '';
    
        resetAtAction();
    };    

    const handleRemoveServiceName = (serviceNameToRemove) => {
        const currentUsecaseDetails = {...usecaseDetails, serviceNames: usecaseDetails.serviceNames.filter(serviceName => serviceName !== serviceNameToRemove)};
        setUsecaseDetails(currentUsecaseDetails);
        addEditorHistory(currentUsecaseDetails);
        resetAtAction();
    }

    const handleClickAddTag = (event) => {
        event.preventDefault();
        const selectedTag = selectTag.current.value;
        if((!usecaseDetails.tags.includes(selectedTag)) && (usecaseDetails.tags.length < MAX_LENGTH_TAGS)) {
            const currentUsecaseDetails = {...usecaseDetails, tags: [...usecaseDetails.tags, selectedTag]};
            setUsecaseDetails(currentUsecaseDetails)
            addEditorHistory(currentUsecaseDetails);
        }
        resetAtAction();
    }

    const handleRemoveTag = (tagToRemove) => {
        const currentUsecaseDetails = {...usecaseDetails, tags: usecaseDetails.tags.filter(tag => tag !== tagToRemove)};
        setUsecaseDetails(currentUsecaseDetails);
        addEditorHistory(currentUsecaseDetails);
        resetAtAction();
    }

    const handleChangeTextAreaAsP = (textarea, type, index) => {
        const newContents = [...usecaseDetails.contents.slice(0,index), {content_type: type, content: textarea.value}, ...usecaseDetails.contents.slice(index+1)];
        setUsecaseDetails({...usecaseDetails, contents: newContents});
    }

    const handleChangeTable = (table, index) => {
        const newContents = [...usecaseDetails.contents.slice(0,index), {...usecaseDetails.contents[index], content: table}, ...usecaseDetails.contents.slice(index+1)];
        setUsecaseDetails({...usecaseDetails, contents: newContents});
        resetAtAction();
    }

    const handleChangeTableType = (type, index) => {
        const newContents = [...usecaseDetails.contents.slice(0,index), {...usecaseDetails.contents[index], table_type: type}, ...usecaseDetails.contents.slice(index+1)];
        setUsecaseDetails({...usecaseDetails, contents: newContents});
        resetAtAction();
    }
    
    const handleChangeSelectTypeOfP = (select, content, index) => {
        const newContents = [...usecaseDetails.contents.slice(0,index), {}, ...usecaseDetails.contents.slice(index+1)];
        switch(select.value){
            case 'image':
                newContents[index] = {content_type: select.value, content: ''};
                break;
            case 'table':
                newContents[index] = {content_type: select.value, content: '', table_type: 'no_th'};
                break;
            default:
                newContents[index] = {content_type: select.value, content: content || ''}; 
                break;
        }
        setUsecaseDetails(prevState => {
            return {...prevState, contents: newContents};
        });
    }
    
    const handleChangeInputFile = (input, type, index) => {
        if(!input.files || input.files.length===0){
            return;
        }
    
        const file = input.files[0]; 
        if (file.size > MAX_BYTES_DOCUMENT_IMAGE) {
            alert('2MB以下のファイルを選択してください。');
            return; 
        }
    
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
            const newContents = [...usecaseDetails.contents.slice(0,index), {content_type: type, content: e.target.result}, ...usecaseDetails.contents.slice(index+1)];
            setUsecaseDetails({...usecaseDetails, contents: newContents});
        };
    }
    
    const handleClickAddPButton = (index) => {
        if(usecaseDetails.contents.length < MAX_LENGTH_DOCUMENT_CONTENTS) {
            const newContents = [...usecaseDetails.contents.slice(0,index+1), {content_type: 'paragraph', content: ''}, ...usecaseDetails.contents.slice(index+1)]
            setUsecaseDetails({...usecaseDetails, contents: newContents});
        }
    }    
    
    const handleClickDeleteButton = (index) => {
        const newContents = [...usecaseDetails.contents.slice(0,index), ...usecaseDetails.contents.slice(index+1)]
        setUsecaseDetails({...usecaseDetails, contents: newContents});
    }
    
    const handleClickMoveButton = (index, direction) => {
        const newContents = [...usecaseDetails.contents];
        if (direction === 'up' && index > 0) {
            [newContents[index], newContents[index - 1]] = [newContents[index - 1], newContents[index]];
        } else if (direction === 'down' && index < newContents.length - 1) {
            [newContents[index], newContents[index + 1]] = [newContents[index + 1], newContents[index]];
        }
        setUsecaseDetails({...usecaseDetails, contents: newContents});
    };

    const formatPList = (sectionName) => {
        const data = usecaseDetails[sectionName].map(p => {
            if (p.content_type == 'image'){
                if (p.file_name){
                    return {...p, content: ''};
                }
                if (!p.file_name && !p.content){
                    return undefined;
                }
                return p;
            } else {
                if (p.content){
                    return p;
                }
                return undefined;
            }
        });
        return data.filter(p => p);
    }
    
    const handleAddNewUsecase = () =>{
        setIsInitialized(false);
        window.scrollTo(0, 0);

        const title = usecaseDetails.title;
        const serviceNames = usecaseDetails.serviceNames;
        const tags = usecaseDetails.tags;
        const contents = usecaseDetails.contents.filter(item => item.content !== '');

        if (!title) {
            setIsInitialized(true);
            setSubmissionMessage('');
            setCautions({...cautions, title: 'タイトルを入力してください。'});
            return;
        }

        const addUsecase = httpsCallable(functions, 'addUsecase');
        addUsecase({
            title: title,
            service_names: serviceNames,
            tags: tags,
            contents: contents,
        }).then(response => {
            if(response.data.status === 200) {
                window.scrollTo(0, 0);
                navigate(`/userPage?tab=posted_usecase`);
                setIsInitialized(true)
                /*setSuccessCheck(true);
                setIsInitialized(true);
                setSubmissionMessage('公開しました。')*/
            } else {
                setSubmissionError(true);
                setIsInitialized(true);
                setSubmissionMessage('投稿に失敗しました。存在しないサービス名を含んでいます。');
                alert('投稿に失敗しました。');
            }
        }).catch(error => {
            setIsInitialized(true);
            setSubmissionMessage('');
            console.error(error);
        })
    };

    const handleUpdateUsecase = (event) =>{
        event.preventDefault();
        resetAtAction();
        setIsInitialized(false);
        window.scrollTo(0, 0); 

        const title = usecaseDetails.title;
        const serviceNames = usecaseDetails.serviceNames;
        const tags = usecaseDetails.tags;
        const contents = formatPList('contents');

        const updateUsecase = httpsCallable(functions, 'updateUsecase');
        updateUsecase({
            usecase_id: queryUsecaseId,
            title: title,
            service_names: serviceNames,
            tags: tags,
            contents: contents,
        }).then(response => {
            if(response.data.status === 200) {
                window.scrollTo(0, 0);
                navigate(`/userPage?tab=posted_usecase`);
                setIsInitialized(true)
                /*setSuccessCheck(true);
                setIsInitialized(true);
                setSubmissionMessage('公開しました。');*/
            } else {
                setSubmissionError(true);
                setIsInitialized(true);
                setSubmissionMessage('投稿に失敗しました。存在しないサービス名を含んでいます。');
                alert('投稿に失敗しました。');
            }
        }).catch(error => {
            setIsInitialized(true);
            setSubmissionMessage(''); 
            console.error(error);
        })
    };

    return(
    <>
        {
            !isError && isInitialized && !isOpenPreview ?
            <>
            <Helmet>
                <title>{`使用例 ${usecaseDetails.title} - AI TechBase`}</title>
                <meta name="robots" content="noindex"/>
                <meta name="description" content='使用例の画面です。'/>
            </Helmet>
            { 
                <main className={`${commonStyles.mainNoSideBar} ${commonStyles.primaryMain}`}>
                    <div className={commonStyles.sectionWrapper}>
                        <section className={commonStyles.primarySection}>
                            {/*{successCheck && <p className={commonStyles.successMessage}>{submissionMessage}</p>}*/}
                            {submissionError && <p className={commonStyles.errorMessage}>{submissionMessage}</p>}
                            {!isNew && <span className={commonStyles.underbarButton}><Link to={`/userPage?tab=posted_usecase`} onClick={()=>window.scrollTo({top: 0})}>マイページへ</Link></span>}
                            {/*{isNew && successCheck && <span className={commonStyles.underbarButton}><Link to={`/userPage?tab=posted_usecase`} onClick={()=>window.scrollTo({top: 0})}>マイページへ</Link></span>}*/}
                            <div className={usecaseStyles.editorHistoryButtonWrapper}>
                                <button className={currentEditorHistory<=1 ? usecaseStyles.invalidButton : usecaseStyles.validButton} onClick={backEditorHistory}><FontAwesomeIcon icon={faArrowLeft}/></button>
                                <button className={currentEditorHistory>EDITOR_HISTORY_LIMIT || currentEditorHistory>=editorHistory.length ? usecaseStyles.invalidButton : usecaseStyles.validButton} onClick={forwardEditorHistory}><FontAwesomeIcon icon={faArrowRight}/></button>
                            </div>
                            <div className={usecaseStyles.articleArea}>
                                <div className={usecaseStyles.attributeWrapper}>
                                    <label htmlFor='title'>タイトル</label>
                                    <span className={usecaseStyles.requiredMark}>必須</span>
                                    <p className={commonStyles.cautionText}>{cautions.title}</p>
                                    <div className={usecaseStyles.inputWrapper}>
                                        <input className={commonStyles.primaryInput} name='title' type='text' value={usecaseDetails.title} maxLength={MAX_LENGTH_TITLE} ref={inputTitle} onChange={handleChangeTitle}></input>
                                    </div>
                                </div>
                                <div className={usecaseStyles.attributeWrapper}>
                                    <label htmlFor='serviceNames'>関連するサービス名</label>
                                    <span className={usecaseStyles.anyMark}>任意</span>
                                    <ul style={{display: usecaseDetails.serviceNames.length ? 'flex' : 'none'}}>
                                        {usecaseDetails.serviceNames.map((serviceName, i)=>(
                                            <li key={i}>
                                                <span className={`${usecaseStyles.tag} ${commonStyles.tag}`}>
                                                    {serviceName}
                                                    <button className={usecaseStyles.removeButton} onClick={() => handleRemoveServiceName(serviceName)}>×</button>
                                                </span>
                                            </li>
                                        ))} 
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.serviceName}</p>
                                    <div className={usecaseStyles.inputNameWrapper}>
                                        <div className={usecaseStyles.inputWrapper}>
                                            <input className={commonStyles.primaryInput} name='serviceNames' type='text' maxLength={MAX_LENGTH_SERVICE_NAME} ref={inputServiceName} onChange={(event) => {handleChangeName(); isInputName(event);}}></input>
                                            <button className={commonStyles.primaryButton} onClick={handleClickAddServiceName}>追加</button>
                                        </div>
                                        {
                                            predictServiceNames.length ? 
                                                <ul className={usecaseStyles.predictServiceNamesWrapper}>
                                                    <p>こちらではありませんか？</p>
                                                    {
                                                        predictServiceNames.map((name)=>{
                                                            return <div key={name} onClick={(event) => handleClickAddServiceName(event, name)}><span className={usecaseStyles.predictName}>{name}</span></div>
                                                        })
                                                    }
                                                </ul>
                                            :
                                            inputName ?
                                                <div className={usecaseStyles.isServiceNamesWrapper}>
                                                    <p className={commonStyles.errorMessage}>そのサービスは登録されていません。</p>
                                                    <Link to = {`/editor`} className={usecaseStyles.link} onClick={() => setPredictServiceNames([])}>サービスを登録する場合はこちら</Link>
                                                </div>  
                                            :
                                            <></> 
                                        }
                                    </div>
                                </div>
                                <div className={usecaseStyles.attributeWrapper}>
                                    <label htmlFor={'tag'}>タグ</label>
                                    <span className={usecaseStyles.anyMark}>任意</span>
                                    <ul style={{display: usecaseDetails.tags.length ? 'flex' : 'none'}}>
                                        {usecaseDetails.tags.map((tag, i)=>(
                                            <li key={i}>
                                                <span className={`${usecaseStyles.tag} ${commonStyles.tag}`}>
                                                    {tagAndLabel[tag]}
                                                    <button className={usecaseStyles.removeButton} onClick={() => handleRemoveTag(tag)}>×</button>
                                                </span>
                                            </li>
                                        ))} 
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.tag}</p>
                                    <div className={usecaseStyles.inputWrapper}>
                                        <select className={commonStyles.primarySelect} name='tag' ref={selectTag} onChange={()=>{setCautions({...cautions, tag: ''})}}>
                                            {
                                                Object.keys(tagAndLabel).map((key, i)=>{
                                                    return <option value={key} key={i}>{tagAndLabel[key]}</option>
                                                })
                                            }
                                        </select>
                                        <button className={commonStyles.primaryButton} onClick={(event) => {handleClickAddTag(event)}}>追加</button>
                                    </div>
                                </div>
                            </div>
                            <div className={usecaseStyles.articleArea}>
                                <div className={`${usecaseStyles.articleWrapper} ${usecaseStyles.articleWrapperWrite}`}>
                                    {
                                        usecaseDetails.contents.map((p, index)=>{
                                            return (
                                                <P key={index} handleChangeTextAreaAsP={(e)=>handleChangeTextAreaAsP(e.target, p.content_type, index)} 
                                                    handleChangeSelectTypeOfP={(e)=>handleChangeSelectTypeOfP(e.target, p.content, index)}
                                                    handleChangeTable={(table)=>handleChangeTable(table, index)}
                                                    handleChangeTableType={(type)=>handleChangeTableType(type, index)}
                                                    id = {index}
                                                    handleChangeInputFile={(e)=>handleChangeInputFile(e.target, p.content_type, index)}
                                                    handleClickMoveButton={(direction)=>handleClickMoveButton(index, direction)}
                                                    handleClickDeleteButton={()=>(handleClickDeleteButton(index))}
                                                    handleClickAddPButton={()=>(handleClickAddPButton(index))}
                                                    setSubmissionMessage={setSubmissionMessage}
                                                    p={p}
                                                />
                                            );
                                        })
                                    }
                                    {!usecaseDetails.contents.length && 
                                        <button type="button" className={usecaseStyles.buttonAddP} onClick={() => handleClickAddPButton(usecaseDetails.length)}>+</button>
                                    }
                                </div>
                            </div>
                            <div className={usecaseStyles.buttonsWrapper}>
                                <button className={commonStyles.primaryButton} onClick={()=>{setIsOpenPreview(true); window.scrollTo({top: 0});}}>プレビュー</button>
                                <button 
                                    className={commonStyles.primaryButton} 
                                    onClick={(event) => {
                                        if (isNew) {
                                            event.preventDefault();
                                            handleAddNewUsecase(event);
                                            setPredictServiceNames([]);
                                        } else {
                                            handleUpdateUsecase(event);
                                        }
                                    }}>投稿</button>
                            </div>           
                        </section>
                    </div>
                </main>
            }
            </>
            :
            <></>
        }
        {
            isOpenPreview ?
                <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
                    <div className={commonStyles.sectionWrapper}>
                        <section className={commonStyles.secondarySection}>
                            <button className={commonStyles.primaryButton} onClick={()=>setIsOpenPreview(false)}>投稿画面に戻る</button>
                        </section>
                        <section className={commonStyles.primarySection}>
                            <div className={articleStyles.articleArea}>
                                <div className={articleStyles.user}>
                                    <img className={articleStyles.userIcon} src={`data:image/jpg;base64,${userInfo.icon_base64}`} alt="user icon" />
                                    <p className={articleStyles.userName}>{userInfo.user_name}</p>
                                </div>
                                <p className={articleStyles.viewWrapper}>
                                <span className={articleStyles.viewCount}>n</span><span className={articleStyles.viewsText}>views</span>                                </p>
                                <div className={`${articleStyles.evaluateWrapper} ${articleStyles.evaluateBottomWrapper}`}>
                                    <button>
                                        <FontAwesomeIcon className={articleStyles.thumbUp} icon={faThumbsUp}/>
                                    </button>
                                    <button className={articleStyles.favoriteButton}>
                                        <FontAwesomeIcon className={articleStyles.favorite} icon={faBookmark}/>
                                    </button>
                                </div>
                                {usecaseDetails.tags.length ?
                                    <div className={articleStyles.iconWrapper}>
                                        <FontAwesomeIcon icon={faTag} />
                                        <ul className={articleStyles.iconsUl}>
                                            {
                                                usecaseDetails.tags.map((tag, i)=>{
                                                    return <div key={i} className={`${commonStyles.tagPrimaryColor} ${articleStyles.tag}`}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</div>
                                                })
                                            }
                                        </ul>
                                    </div>
                                :
                                    <></>
                                }
                                <h2 className={articleStyles.title}>{usecaseDetails.title}</h2>
                                {usecaseDetails.contents.map((c, i) => {
                                    switch(c.content_type) {
                                        case 'paragraph':
                                            return <p key={i} className={`${commonStyles.primaryP} ${articleStyles.para}`}>{c.content}</p>
                                        case 'headline':
                                            return <h3 key={i} className={`${commonStyles.primaryH3} ${i === 0 ? "" : articleStyles.head}`}>{c.content}</h3>
                                        case 'small_headline':
                                            return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{c.content}</span></h4>
                                        case 'image':
                                            return <img src={PreviewImg} className={articleStyles.img} />
                                        case 'table':
                                            if (c.content && c.table_type=='no_th'){
                                                return <div className={commonStyles.primaryTableWrapper}>
                                                            <table className={commonStyles.primaryTable}>
                                                                <tbody>
                                                                {
                                                                    c.content.map((row, i)=>{
                                                                        return <tr key={i} className={commonStyles.primaryTr}>
                                                                                {
                                                                                    row.map((column, j)=>{
                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                    })
                                                                                }
                                                                                </tr>
                                                                        })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                } else if (c.content && c.table_type=='row_th') {
                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                <table className={commonStyles.primaryTable}>
                                                                    <tbody>
                                                                    {
                                                                        c.content.map((row, i)=>{
                                                                            if (i == 0) {
                                                                                return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                {
                                                                                    row.map((column, j)=>{
                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                    })
                                                                                }
                                                                                </tr>
                                                                            } else {
                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                        {
                                                                                            row.map((column, j)=>{
                                                                                                return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                            })
                                                                                        }
                                                                                        </tr>
                                                                            }
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                } else if (c.content && c.table_type=='col_th') {
                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                <table className={commonStyles.primaryTable}>
                                                                    <tbody>
                                                                    {
                                                                        c.content.map((row, i)=> {
                                                                            return <tr key={i} className={commonStyles.primaryTr}>
                                                                                    {
                                                                                        row.map((column, j)=>{
                                                                                            if (j==0){
                                                                                                return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                            }
                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                        })
                                                                                    }
                                                                                    </tr>
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                } else if (c.content && c.table_type=='col_row_th') {
                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                <table className={commonStyles.primaryTable}>
                                                                    <tbody>
                                                                    {
                                                                        c.content.map((row, i)=>{
                                                                            if (i == 0){
                                                                                return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                {
                                                                                    row.map((column, j)=>{
                                                                                        return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                    })
                                                                                }
                                                                                </tr>
                                                                            } else {
                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                {
                                                                                    row.map((column, j)=>{
                                                                                        if (j==0) {
                                                                                            return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                        }
                                                                                        return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                    })
                                                                                }
                                                                                </tr>
                                                                            }
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                }
                                        default:
                                            return null;
                                    }
                                })}
                            </div>
                        </section>
                    </div>
                </main>
            :
            <></>
        }
        {
            isInitialized && isError ?
            <Page404/>
            :
            <></>
        }
        {
            !isInitialized && !isError ?
            <main className={commonStyles.primaryMain}>
                <section className={commonStyles.transparentBgColor}>
                    <div className={commonStyles.sectionInner}>
                        <Loading/>
                    </div>
                </section>
            </main>
            :
            <></>
        }
    </>
    );
};

export default Usecase;
