import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import editorStyles from './editor_styles.module.css';
import articleStyles from '../article/article_styles.module.css';
import commonStyles from '../common/common_styles.module.css';
import P from './p';
import SideBar from '../common/sidebar';
import { functions } from '../database/firebaseConfig'; 
import { httpsCallable } from "firebase/functions";
import Loading from '../common/loading';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Page404 from '../common/page404';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faTag, faHashtag, faLaptop, faRocket, faArrowsRotate, faAt, faBrain, faCreditCard, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp, faBookmark } from '@fortawesome/free-regular-svg-icons';
import { tagAndLabel, paymentAndLabel, environmentAndLabel } from '../common/constants';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { isURL } from '../common/functions';
import Image from '../article/image';
import PreviewImg from '../img/preview.png';

const Editor = () => {
    const [appDetails, setAppDetails] = useState({
        name: '',
        url: '',
        language: '',
        short_description: '',
        long_description: '',
        thumbnail_base64: '',
        published_at: '',
        revisioned_at: '',
        tags: [],
        environments: [],
        equipments: [],
        payments: [],
        publishers: [],
        models: [],
        datasets: [],
        primary_usage: [],
        how_to_install: [],
        how_to_execute: [],
        payment_detail: [],
    });

    const [initialAppDetails, setInitialAppDetails] = useState({});

    const [cautions, setCautions] = useState({
        name: '',
        language: '',
        short_description: '',
        long_descriptpion: '',
        published_at: '',
        environment: '',
        equipment: '',
        payment: '',
        publisher: '',
        url: '',
        model: '',
        dataset: '',
        thumbnail: '',
        tag: '',
    });

    const [activeTab, setActiveTab] = useState();
    const tabs = ['追加情報', '開発者向け'];

    //編集履歴
    const [editorHistory, setEditorHistory] = useState([]);
    const [currentEditorHistory, setCurrentHistory] = useState(0);
    const EDITOR_HISTORY_LIMIT = 50; //履歴の最大数

    const inputAppName = useRef();
    const selectLanguage = useRef();
    const textAreaShortDescription = useRef();
    const textAreaLongDescription = useRef();
    const selectTag = useRef();
    const selectEnvironment = useRef();
    //const selectEquipment = useRef();
    const selectPayment = useRef();
    const inputPublisher = useRef();
    const inputPublishedAt = useRef();
    const inputThumbnail = useRef();
    const inputURL = useRef();
    const inputModel = useRef();
    //const inputDataset = useRef();

    const MAX_LENGTH_NAME = 256;
    const MAX_LENGTH_URL = 2048;
    const MAX_LENGTH_SHORT_DESCRIPTION = 60;
    const MAX_LENGTH_LONG_DESCRIPTION = 200;
    const MAX_LENGTH_TAGS = 4;
    const MAX_LENGTH_ENVIRONMENTS = 8;
    //const MAX_LENGTH_EQUIPMENTS = 5;
    const MAX_LENGTH_PUBLISHER_CONTENTS = 100;
    const MAX_LENGTH_PUBLISHERS = 5;
    const MAX_LENGTH_MODEL_CONTENT = 64;
    const MAX_LENGTH_MODELS = 5;
    //const MAX_LENGTH_DATASET_CONTENTS = 100;
    const MAX_LENGTH_DATASETS = 5;
    const MAX_LENGTH_DOCUMENT_CONTENTS = 100;
    const MAX_BYTES_THUMBNAIL = 2 * 1024 * 1024;
    const MAX_BYTES_DOCUMENT_IMAGE = 2 * 1024 * 1024;

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const queryApp = searchParams.get('app');
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    //新規投稿か更新か
    const [isNew, setIsNew] = useState(false);

    const [submissionMessage, setSubmissionMessage] = useState("");
    const [submissionError, setSubmissionError] = useState("");
    const [successCheck, setSuccessCheck] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [predictServiceNames, setPredictServiceNames] = useState([]);

    const [isOpenPreview, setIsOpenPreview] = useState(false);

    const [activePreviewTab, setActivePreviewTab] = useState('使い方');
    const [activePreviewInfoTab, setActivePreviewInfoTab] = useState('基本');
    const previewTabs = ['使い方', '料金', '使用例'];
    const previewInfoTabs = ['基本', '開発'];

    useLayoutEffect(() => {
        if (queryApp == null) {
            setIsError(false);
            setIsInitialized(true);
            setIsNew(true);
            addEditorHistory(appDetails);
            setAppDetails({
                name: '',
                url: '',
                language: '',
                short_description: '',
                long_description: '',
                thumbnail_base64: '',
                published_at: '',
                revisioned_at: '',
                tags: [],
                environments: [],
                equipments: [],
                payments: [],
                publishers: [],
                models: [],
                datasets: [],
                primary_usage: [],
                how_to_install: [],
                how_to_execute: [],
                payment_detail: [],
            });
           return;
        }
        const getDetailsFromName = httpsCallable(functions, 'getDetailsFromName');
        getDetailsFromName({
            name: queryApp,
            language: 'jpn'
            }).then(async(result) => {
                const data = result.data;
                if (data.status != 200){
                    setIsNew(false);
                    setIsInitialized(true);
                    setIsError(true);
                    return;
                }

                const getImageAsBase64 = httpsCallable(functions, 'getImageAsBase64');

                const primaryUsage = await Promise.all(data.primary_usage.map(async(p)=>{
                    if(p.content_type=='image'){
                        return await getImageAsBase64({
                            file_name: p.file_name,
                            image_type: 'service_primary_usage'
                            }).then((result) => {
                                if (result.data.status != 200){
                                    setIsError(true);
                                    return p;
                                }
                                return {...p, content: result.data.image_base64};
                            }).catch((error) => {
                                setIsError(true);
                                return p;
                            });
                    }else{
                        return p;
                    }
                }));

                const howToInstall = await Promise.all(data.how_to_install.map(async(p)=>{
                    if(p.content_type=='image'){
                        return await getImageAsBase64({
                            file_name: p.file_name,
                            image_type: 'service_how_to_install'
                            }).then((result) => {
                                if (result.data.status != 200){
                                    setIsError(true);
                                    return p;
                                }
                                return {...p, content: result.data.image_base64};
                            }).catch((error) => {
                                setIsError(true);
                                return p;
                            });
                    }else{
                        return p;
                    }
                }));

                const howToExecute = await Promise.all(data.how_to_execute.map(async(p)=>{
                    if(p.content_type=='image'){
                        return await getImageAsBase64({
                            file_name: p.file_name,
                            image_type: 'service_how_to_execute'
                            }).then((result) => {
                                if (result.data.status != 200){
                                    setIsError(true);
                                    return p;
                                }
                                return {...p, content: result.data.image_base64};
                            }).catch((error) => {
                                setIsError(true);
                                return p;
                            });
                    }else{
                        return p;
                    }
                }));

                const paymentDetail = await Promise.all(data.payment_detail.map(async(p)=>{
                    if(p.content_type=='image'){
                        return await getImageAsBase64({
                            file_name: p.file_name,
                            image_type: 'service_payment_detail'
                            }).then((result) => {
                                if (result.data.status != 200){
                                    setIsError(true);
                                    return p;
                                }
                                return {...p, content: result.data.image_base64};
                            }).catch((error) => {
                                setIsError(true);
                                return p;
                            });
                    }else{
                        return p;
                    }
                }));


                setIsNew(false);
                setIsInitialized(true);
                setIsError(false);
                setAppDetails({...data, primary_usage: primaryUsage, how_to_install: howToInstall, how_to_execute: howToExecute, payment_detail: paymentDetail});
                addEditorHistory(data);

                setInitialAppDetails({
                    primary_usage: primaryUsage,
                    how_to_install: howToInstall,
                    how_to_execute: howToExecute,
                    payment_detail: paymentDetail,
                    thumbnail_base64: data.thumbnail_base64
                });

                
            }).catch((error) => {
                setIsInitialized(true);
                setIsError(true);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error response data:', error.response.data);
                    console.log('Error response status:', error.response.status);
                    console.log('Error response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }, [location]);

    const resetAtAction = () => {
        setSubmissionMessage('');
    }

    const backEditorHistory = () => {
        if(currentEditorHistory<=1){
            return;
        }
        setAppDetails(editorHistory[currentEditorHistory-2]);
        setCurrentHistory(currentEditorHistory-1);
        resetAtAction();
    }

    const forwardEditorHistory = () => {
        if(currentEditorHistory>EDITOR_HISTORY_LIMIT || currentEditorHistory>=editorHistory.length){
            return;
        }
        setAppDetails(editorHistory[currentEditorHistory]);
        setCurrentHistory(currentEditorHistory+1);
        resetAtAction();
    }

    const addEditorHistory = (history) => {
        if(currentEditorHistory>EDITOR_HISTORY_LIMIT){
            return;
        }
        setEditorHistory([...editorHistory.slice(0, currentEditorHistory), history]);
        setCurrentHistory(currentEditorHistory+1);
    }

    const handleChangeName = () => {
        setCautions({...cautions, name: ''});
        setSubmissionMessage('');
        setSubmissionError('');
        const currentAppDetails = {...appDetails, name: inputAppName.current.value};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        if(inputAppName.current.value!=''){
            const getServiceNamesByKeyword = httpsCallable(functions, 'getServiceNamesByKeyword');
            getServiceNamesByKeyword({
                keyword: inputAppName.current.value,
                limit: 5
            }).then(response => {
                if(response.data.status === 200) {
                    setPredictServiceNames(response.data.service_names);
                } else {
                    setPredictServiceNames([]);
                }
            }).catch(error => {
                setPredictServiceNames([]);
            })
        } else {
            setPredictServiceNames([]);
        }
        resetAtAction();
    }

    const handleChangeLanguage = () => {
        setSubmissionMessage('');
        const currentAppDetails = {...appDetails, language: selectLanguage.current.value};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    const handleChangeURL = () => {
        setSubmissionMessage('');
        setCautions({...cautions, url: ''});
        const currentAppDetails = {...appDetails, url: inputURL.current.value};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    const handleClickAddTag = (event) => {
        event.preventDefault();
        const selectedTag = selectTag.current.value;
        if((!appDetails.tags.includes(selectedTag)) && (appDetails.tags.length < MAX_LENGTH_TAGS)) {
            const currentAppDetails = {...appDetails, tags: [...appDetails.tags, selectedTag]};
            setAppDetails(currentAppDetails)
            addEditorHistory(currentAppDetails);
        }
        resetAtAction();
    }

    const handleRemoveTag = (tagToRemove) => {
        const currentAppDetails = {...appDetails, tags: appDetails.tags.filter(tag => tag !== tagToRemove)};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    const handleClickAddEnvironment = (event) => {
        event.preventDefault();
        const selectedEnvironment = selectEnvironment.current.value;
        if ((!appDetails.environments.includes(selectedEnvironment)) && (appDetails.environments.length < MAX_LENGTH_ENVIRONMENTS)) {
            const currentAppDetails = {...appDetails, environments: [...appDetails.environments, selectedEnvironment]};
            setAppDetails(currentAppDetails)
            addEditorHistory(currentAppDetails);
        }
        resetAtAction();
    }

    const handleRemoveEnvironment = (environmentToRemove) => {
        const currentAppDetails = {...appDetails, environments: appDetails.environments.filter(environment => environment !== environmentToRemove)};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    /*const handleClickAddEquipment = (event) => {
        event.preventDefault();
        const selectedEquipment = selectEquipment.current.value;
    
        if ((!appDetails.equipments.includes(selectedEquipment)) && (appDetails.equipments.length < MAX_LENGTH_EQUIPMENTS)) {
            setAppDetails({...appDetails, equipments: [...appDetails.equipments, selectedEquipment]})
        }
    }
    
    const handleRemoveEquipment = (equipmentToRemove) => {
        setAppDetails({...appDetails, equipments: appDetails.equipments.filter(equipment => equipment !== equipmentToRemove)});
    }*/
    
    const handleClickAddPayment = (event) => {
        event.preventDefault();
        const selectedPayment = selectPayment.current.value;
    
        if (!appDetails.payments.includes(selectedPayment)) {
            const currentAppDetails = {...appDetails, payments: [...appDetails.payments, selectedPayment]};
            setAppDetails(currentAppDetails);
            addEditorHistory(currentAppDetails);
        }

        resetAtAction();
    }

    const handleRemovePayment = (paymentToRemove) => {
        const currentAppDetails = {...appDetails, payments: appDetails.payments.filter(payment => payment !== paymentToRemove)};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }
    
    const handleChangePublishedAt = () => {
        setSubmissionMessage('');
        const currentAppDetails = {...appDetails, published_at: inputPublishedAt.current.value};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }
    /*const handleChangePublishedAt = () => {
        setSubmissionMessage('');
        const yearMonth = inputPublishedAt.current.value;
        const fullDate = yearMonth + '-01'; 
        const currentAppDetails = {...appDetails, published_at: fullDate};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        console.log(appDetails)
        resetAtAction();
    }*/

    const handleChangeShortDescription = () => {
        setSubmissionMessage('');
        const currentAppDetails = {...appDetails, short_description: textAreaShortDescription.current.value}
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    const handleChangeLongDescription = () => {
        setSubmissionMessage('');
        const currentAppDetails = {...appDetails, long_description: textAreaLongDescription.current.value};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    const handleClickAddPublisher = (event) => {
        event.preventDefault();
        const publisher = inputPublisher.current.value;

        if(publisher == '') {
            setCautions({...cautions, publisher: '開発者名を入力してください'});
            return;
        }

        if(appDetails.publishers.length < MAX_LENGTH_PUBLISHERS) {
            const currentAppDetails = {...appDetails, publishers: [...appDetails.publishers, publisher]};
            setAppDetails(currentAppDetails)
            addEditorHistory(currentAppDetails);
        }
        
        /* 入力をクリア */
        inputPublisher.current.value = '';

        resetAtAction();
    }

    const handleRemovePublisher = (publisherToRemove) => {
        const currentAppDetails = {...appDetails, publishers: appDetails.publishers.filter(publisher => publisher !== publisherToRemove)};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    const handleClickAddModel = (event) => {
        event.preventDefault();
        const model = inputModel.current.value;

        if(model == '') {
            setCautions({...cautions, model: 'モデル名を入力してください'});
            return;
        }

        if(appDetails.models.length < MAX_LENGTH_MODELS) {
            const currentAppDetails = {...appDetails, models: [...appDetails.models, model]};
            setAppDetails(currentAppDetails)
            addEditorHistory(currentAppDetails);
        }
    
        /* 入力をクリア */
        inputModel.current.value = '';

        resetAtAction();
    }

    const handleRemoveModel = (modelToRemove) => {
        const currentAppDetails = {...appDetails, models: appDetails.models.filter(model => model !== modelToRemove)};
        setAppDetails(currentAppDetails);
        addEditorHistory(currentAppDetails);
        resetAtAction();
    }

    /*const handleClickAddDataset = (event) => {
        event.preventDefault();
        const dataset = inputDataset.current.value;

        if(dataset == '') {
            setCautions({...cautions, dataset: 'データセット名を入力してください'});
            return;
        }

        if(appDetails.datasets.length < MAX_LENGTH_DATASETS) {
            setAppDetails({...appDetails, datasets: [...appDetails.datasets, dataset]})
        }
    
        inputDataset.current.value = '';
    }

    const handleRemoveDataset = (datasetToRemove) => {
        setAppDetails({...appDetails, datasets: appDetails.datasets.filter(dataset => dataset !== datasetToRemove)});
    }*/

    const handleChangeThumbnail = (event) => {
        event.preventDefault();
        const thumbnail = inputThumbnail.current
        if(!thumbnail.files || thumbnail.files.length==0){
            return;
        }

        if (thumbnail.files[0].size > MAX_BYTES_THUMBNAIL) {
            alert('2MB以下のファイルを選択してください。');
            return; 
        }

        const reader = new FileReader()
        reader.readAsDataURL(thumbnail.files[0])
        reader.onload = (e) => {
            const currentAppDetails = {...appDetails, thumbnail_base64: e.target.result}
            setAppDetails(currentAppDetails);
            addEditorHistory(currentAppDetails);
        };
        resetAtAction();
    }

    const setAppDetailsFromSection = (newSection, sectionName) => {
        let currentAppDetails = {}
        switch(sectionName){
            case 'primary_usage': 
                currentAppDetails = { ...appDetails, primary_usage: newSection };
                setAppDetails(currentAppDetails);
                addEditorHistory(currentAppDetails);
                break;
            case 'how_to_install':
                currentAppDetails = { ...appDetails, how_to_install: newSection };
                setAppDetails(currentAppDetails);
                addEditorHistory(currentAppDetails);
                break;
            case 'how_to_execute':
                currentAppDetails = { ...appDetails, how_to_execute: newSection };
                setAppDetails(currentAppDetails);
                addEditorHistory(currentAppDetails);
                break;
            case 'payment_detail':
                currentAppDetails = { ...appDetails, payment_detail: newSection };
                setAppDetails(currentAppDetails);
                addEditorHistory(currentAppDetails);
                break;
        }
    }

    const handleChangeTextAreaAsP = (textarea, type, index, sectionName) => {
        const newSection = [...appDetails[sectionName].slice(0,index), {content_type: type, content: textarea.value}, ...appDetails[sectionName].slice(index+1)];
        setAppDetailsFromSection(newSection, sectionName);
        resetAtAction();
    }

    const handleChangeTable = (table, index, sectionName) => {
        const newSection = [...appDetails[sectionName].slice(0,index), {...appDetails[sectionName][index], content: table}, ...appDetails[sectionName].slice(index+1)];
        setAppDetailsFromSection(newSection, sectionName);
        resetAtAction();
    }

    const handleChangeTableType = (type, index, sectionName) => {
        const newSection = [...appDetails[sectionName].slice(0,index), {...appDetails[sectionName][index], table_type: type}, ...appDetails[sectionName].slice(index+1)];
        setAppDetailsFromSection(newSection, sectionName);
        resetAtAction();
    }

    const handleChangeSelectTypeOfP = (select, content, index, sectionName) => {
        const newSection = [...appDetails[sectionName].slice(0,index), {}, ...appDetails[sectionName].slice(index+1)];
        switch(select.value){
            case 'image':
                newSection[index] = {content_type: select.value, content: '', file_name: ''};
                break;
            case 'table':
                newSection[index] = {content_type: select.value, content: '', table_type: 'no_th'};
                break;
            default:
                newSection[index] = {content_type: select.value, content: content || ''}; 
                break;
        }
        setAppDetailsFromSection(newSection, sectionName);
        resetAtAction();
    }

    const handleChangeInputFile = (input, type, index, sectionName) => {
        if(!input.files || input.files.length==0){
            return;
        }

        const file = input.files[0]; 
        if (file.size > MAX_BYTES_DOCUMENT_IMAGE) {
            alert('2MB以下のファイルを選択してください。');
            return; 
        }

        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
            const newSection = [...appDetails[sectionName].slice(0,index), {content_type: type, content: e.target.result, file_name: ''}, ...appDetails[sectionName].slice(index+1)];
            setAppDetailsFromSection(newSection, sectionName);
        };
        resetAtAction();
    }

    const handleClickAddPButton = (index, sectionName) => {
        if(appDetails[sectionName].length < MAX_LENGTH_DOCUMENT_CONTENTS) {
            const newSection = [...appDetails[sectionName].slice(0,index+1), {content_type: 'paragraph', content: ''}, ...appDetails[sectionName].slice(index+1)]
            setAppDetailsFromSection(newSection, sectionName);
        }
        resetAtAction();
    }

    const handleClickDeleteButton = (index, sectionName) => {
        const newSection = [...appDetails[sectionName].slice(0,index), ...appDetails[sectionName].slice(index+1)]
        setAppDetailsFromSection(newSection, sectionName);
        resetAtAction();
    }

    const handleClickMoveButton = (index, direction, sectionName) => {
        const newSection = [...appDetails[sectionName]];
        if (direction === 'up' && index > 0) {
          [newSection[index], newSection[index - 1]] = [newSection[index - 1], newSection[index]];
        } else if (direction === 'down' && index < newSection.length - 1) {
          [newSection[index], newSection[index + 1]] = [newSection[index + 1], newSection[index]];
        }
        setAppDetailsFromSection(newSection, sectionName);
        resetAtAction();
    };

    const formatPList = (sectionName) => {
        const data = appDetails[sectionName].map(p => {
            if(p.content_type=='image'){
                if(p.file_name){
                    return {...p, content: ''};
                }
                if(!p.file_name && !p.content){
                    return undefined;
                }
                return p;
            } else {
                if(p.content){
                    return p;
                }
                return undefined;
            }
        });
        return data.filter(p => p);
    }    
    
    const handleAddNewService = (event) =>{
        event.preventDefault();

        resetAtAction();

        const appName = inputAppName.current.value;
        const url = inputURL.current.value;
        const thumbnail = appDetails.thumbnail_base64;
        const language = selectLanguage.current.value;
        const shortDescription = textAreaShortDescription.current.value;
        const longDescription = textAreaLongDescription.current.value;
        const publishedAt = inputPublishedAt.current.value ? inputPublishedAt.current.value + '-01' : '';
        const tags = appDetails.tags;
        const environments = appDetails.environments;
        //const equipments = appDetails.equipments;
        const payments = appDetails.payments;
        const publishers = appDetails.publishers;
        const models = appDetails.models;
        //const datasets = appDetails.datasets;
        const primaryUsage = formatPList('primary_usage');
        const howToInstall = formatPList('how_to_install');
        const howToExecute = formatPList('how_to_execute');
        const paymentDetail = formatPList('payment_detail');

        if (!appName) {
            setSubmissionMessage('');
            setCautions({...cautions, name: 'アプリ・プラグイン名を入力してください。'});
            window.scrollTo(0, 0)
            return;
        } else {
            setIsLoading(true);
        }     

        const addNewService = httpsCallable(functions, 'addNewService');
        addNewService({
            name: appName,
            url: url,
            thumbnail: thumbnail,
            language: language,
            short_description: shortDescription,
            long_description: longDescription,
            published_at: publishedAt,
            tags: tags,
            environments: environments,
            //equipments: equipments,
            payments: payments,
            publishers: publishers,
            models: models,
            //datasets: datasets,
            primary_usage: primaryUsage,
            how_to_install: howToInstall,
            how_to_execute: howToExecute,
            payment_detail: paymentDetail,

        }).then(response => {
            if(response.data.status === 200) {
                window.scrollTo(0, 0);
                navigate(`/services/${appName}`);
                setIsLoading(false);
                /*setSuccessCheck(true);
                setSubmissionMessage('公開しました。');*/
            } else {
                setIsLoading(false);
                window.scrollTo(0, 0);
                setSubmissionError('すでに存在するアプリです。編集する場合はこちらへ。');
                alert('すでに存在するアプリです。');
            }
        }).catch(error => {
            setIsLoading(false);
            setSubmissionMessage('');
            console.error(error);
        })
    };

    const handleUpdateService = (event) =>{
        event.preventDefault();

        resetAtAction();

        const appName = inputAppName.current.value;
        const url = inputURL.current.value;
        const isThumbnailUpdated = initialAppDetails.thumbnail_base64 != appDetails.thumbnail_base64;
        const thumbnail = appDetails.thumbnail_base64;
        const language = selectLanguage.current.value;
        const shortDescription = textAreaShortDescription.current.value;
        const longDescription = textAreaLongDescription.current.value;
        const publishedAt = inputPublishedAt.current.value ? inputPublishedAt.current.value + '-01' : '';
        const tags = appDetails.tags;
        const environments = appDetails.environments;
        //const equipments = appDetails.equipments;
        const payments = appDetails.payments;
        const publishers = appDetails.publishers;
        const models = appDetails.models;
        //const datasets = appDetails.datasets;
        const isPrimaryUsageUpdated = (JSON.stringify(initialAppDetails.primary_usage)!=JSON.stringify(appDetails.primary_usage))
        const isHowToInstallUpdated = (JSON.stringify(initialAppDetails.how_to_install)!=JSON.stringify(appDetails.how_to_install))
        const isHowToExecuteUpdated = (JSON.stringify(initialAppDetails.how_to_execute)!=JSON.stringify(appDetails.how_to_execute))
        const isPaymentDetailUpdated = (JSON.stringify(initialAppDetails.payment_detail)!=JSON.stringify(appDetails.payment_detail))
        const primaryUsage = formatPList('primary_usage');
        const howToInstall = formatPList('how_to_install');
        const howToExecute = formatPList('how_to_execute');
        const paymentDetail = formatPList('payment_detail');

        setIsLoading(true); 

        const updateService = httpsCallable(functions, 'updateService');
        updateService({
            name: appName,
            url: url,
            is_thumbnail_updated: isThumbnailUpdated,
            thumbnail: thumbnail,
            language: language,
            short_description: shortDescription,
            long_description: longDescription,
            published_at: publishedAt,
            tags: tags,
            environments: environments,
            //equipments: equipments,
            payments: payments,
            publishers: publishers,
            models: models,
            //datasets: datasets,
            is_primary_usage_updated: isPrimaryUsageUpdated,
            is_how_to_install_updated: isHowToInstallUpdated,
            is_how_to_execute_updated: isHowToExecuteUpdated,
            is_payment_detail_updated: isPaymentDetailUpdated,
            primary_usage: primaryUsage,
            how_to_install: howToInstall,
            how_to_execute: howToExecute,
            payment_detail: paymentDetail,
        }).then(response => {
            if(response.data.status === 200) {
                window.scrollTo(0, 0);
                navigate(`/services/${appName}`);
                setIsLoading(false);
                /*setSuccessCheck(true);
                setSubmissionMessage('公開しました。');*/
            } else {
                setIsLoading(false);
                setSubmissionMessage('');
                alert('投稿に失敗しました。');
            }
        }).catch(error => {
            setIsLoading(false);
            setSubmissionMessage(''); 
            console.error(error);
        })
    };

    const createNavList = (srcList) => {
        const navList = [];
        srcList.map((p) => {
            if(p.content_type == 'headline'){
                navList.push({key: p.content, label: p.content});
            }
        })
        return navList
    }

    return(
    <>
        {
            !isError && isInitialized ?
            <>
            <Helmet>
                <title>{`編集 ${appDetails.name} - AI TechBase`}</title>
                <meta name="robots" content="noindex"/>
                <meta name="description" content='編集用の画面です。'/>
            </Helmet>
            { 
                !isOpenPreview ?
                <main className={`${commonStyles.mainNoSideBar} ${commonStyles.primaryMain}`}>
                    <div className={commonStyles.sectionWrapper}>
                        <section className={commonStyles.primarySection}>
                            {/*{successCheck && <p className={commonStyles.successMessage}>{submissionMessage}</p>}*/}
                            {submissionError && 
                                <p className={commonStyles.errorMessage}>
                                    {submissionError.split('編集する場合は')[0]}<br/>
                                    <Link to ={`?app=${appDetails.name}`} className={editorStyles.link} onClick={() => setSubmissionError(null)}>
                                        編集する場合は{submissionError.split('編集する場合は')[1]}
                                    </Link>
                                </p>
                            }
                            {!isNew && !successCheck && <span className={commonStyles.underbarButton}><Link to={`/services/${queryApp}`} onClick={()=>window.scrollTo({top: 0})}>記事に戻る</Link></span>}
                            {successCheck && <span className={commonStyles.underbarButton}><Link to={`/services/${appDetails.name}`} onClick={()=>window.scrollTo({top: 0})}>記事に戻る</Link></span>}
                            <div className={editorStyles.editorHistoryButtonWrapper}>
                                <button className={currentEditorHistory<=1 ? editorStyles.invalidButton : editorStyles.validButton} onClick={backEditorHistory}><FontAwesomeIcon icon={faArrowLeft}/></button>
                                <button className={currentEditorHistory>EDITOR_HISTORY_LIMIT || currentEditorHistory>=editorHistory.length ? editorStyles.invalidButton : editorStyles.validButton} onClick={forwardEditorHistory}><FontAwesomeIcon icon={faArrowRight}/></button>
                            </div>
                            <div className={editorStyles.articleArea}>
                                <h1>基本情報</h1>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='appName'>アプリ・プラグイン名</label>
                                    <span className={editorStyles.requiredMark}>必須</span>
                                    <p className={commonStyles.cautionText}>{cautions.name}</p>
                                    <div className={isNew ? editorStyles.nameAndSpecifications : ''}>
                                        <div className={editorStyles.inputNameWrapper}>
                                            <p className={editorStyles.specificationsMobile}>※ ChatGPTのプラグイン名は「Sevice ChatGPT Plugin」のように入力してください。</p>
                                            <input className={commonStyles.primaryInput} readOnly={isNew ? false : true} name='appName' type='text' value={appDetails.name} maxLength={MAX_LENGTH_NAME} ref={inputAppName} onChange={handleChangeName}></input>
                                            {
                                                predictServiceNames.length ? 
                                                <ul className={editorStyles.predictServiceNamesWrapper}>
                                                    <p>こちらではありませんか？クリックするとページが遷移します。</p>
                                                    {
                                                        predictServiceNames.map((name)=>{
                                                            return <Link key={name} to={`/editor?app=${name}`} onClick={() => setPredictServiceNames([])}>{name}</Link>
                                                        })
                                                    }
                                                </ul>
                                                :
                                                <></>
                                            }
                                        </div>
                                        {isNew && (
                                            <div className={editorStyles.specifications}>
                                                ※ ChatGPTのプラグイン名は「Sevice ChatGPT Plugin」のように入力してください。
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='language'>説明の言語</label>
                                    <span className={editorStyles.requiredMark}>必須</span>
                                    <p className={commonStyles.cautionText}>{cautions.language}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <select className={commonStyles.primarySelect} name='language' ref={selectLanguage} onChange={handleChangeLanguage} value={appDetails.language}>
                                            <option value='jpn'>日本語</option>
                                            <option value='eng'>英語</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='url'>URL</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <p className={commonStyles.cautionText}>{cautions.url}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <input className={commonStyles.primaryInput} name='url' type='url' value={appDetails.url} maxLength={MAX_LENGTH_URL} ref={inputURL} onChange={handleChangeURL}></input>
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='thumbnail'>サムネイル</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <p className={commonStyles.cautionText}>{cautions.thumbnail}</p>
                                    <div className={editorStyles.thumbnailWrapper}>
                                    <img src={appDetails.thumbnail_base64 ? (appDetails.thumbnail_base64.startsWith('data:image') ? appDetails.thumbnail_base64 : `data:image/jpg;base64,${appDetails.thumbnail_base64}`) : ''} />
                                        <button type="button" className={commonStyles.whiteButton} onClick={() => inputThumbnail.current.click()} >
                                            ファイルを選択
                                        </button>
                                        <input type='file' accept='image/png, image/jpeg' ref={inputThumbnail} style={{ display: 'none' }} onChange={(e)=>{handleChangeThumbnail(e)}}/>
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor={'tag'}>タグ</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <ul style={{display: appDetails.tags.length ? 'flex' : 'none'}}>
                                        {appDetails.tags.map((tag, i)=>(
                                            <li key={i}>
                                                <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                    {tagAndLabel[tag]}
                                                    <button className={editorStyles.removeButton} onClick={() => handleRemoveTag(tag)}>×</button>
                                                </span>
                                            </li>
                                        ))} 
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.tag}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <select className={commonStyles.primarySelect} name='tag' ref={selectTag} onChange={()=>{setCautions({...cautions, tag: ''})}}>
                                            {
                                                Object.keys(tagAndLabel).map((key, i)=>{
                                                    return <option value={key} key={i}>{tagAndLabel[key]}</option>
                                                })
                                            }
                                        </select>
                                        <button className={commonStyles.primaryButton} onClick={(event) => {handleClickAddTag(event)}}>追加</button>
                                        {/*<p className={editorStyles.restrictArrayLength}>{appDetails.tags.length}個/{MAX_LENGTH_TAGS}個</p>*/}
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor={'environment'}>動作環境</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <ul style={{display: appDetails.environments.length ? 'flex' : 'none'}}>
                                        {appDetails.environments.map((environment, i)=>(
                                            <li key={i}>
                                                <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                    {environmentAndLabel[environment]}
                                                    <button className={editorStyles.removeButton} onClick={() => handleRemoveEnvironment(environment)}>×</button>
                                                </span>
                                            </li>
                                        ))} 
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.environment}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <select className={commonStyles.primarySelect} name='environment' ref={selectEnvironment} onChange={()=>{setSubmissionMessage(''); setCautions({...cautions, environment: ''})}}>
                                            {
                                                Object.keys(environmentAndLabel).map((key, i)=>{
                                                    return <option value={key} key={i}>{environmentAndLabel[key]}</option>
                                                })
                                            }
                                        </select>
                                        <button className={commonStyles.primaryButton} onClick={(event) => {setSubmissionMessage(''); handleClickAddEnvironment(event)}}>追加</button>
                                        {/*<p className={editorStyles.restrictArrayLength}>{appDetails.environments.length}個/{MAX_LENGTH_ENVIRONMENTS}個</p>*/}
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='payment'>料金・料金タイプ</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <ul style={{display: appDetails.payments.length ? 'flex' : 'none'}}>
                                        {appDetails.payments.map((payment, i) => (
                                            <li key={i}>
                                                <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                    {paymentAndLabel[payment]}  
                                                    <button className={editorStyles.removeButton} onClick={() => handleRemovePayment(payment)}>×</button>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.payment}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <select className={commonStyles.primarySelect} name='payment' ref={selectPayment} onChange={()=>{setCautions({...cautions, payment: ''})}}>
                                            {
                                                Object.keys(paymentAndLabel).map((key, i)=>{
                                                    return <option value={key} key={i}>{paymentAndLabel[key]}</option>
                                                })
                                            }
                                        </select>
                                        <button className={commonStyles.primaryButton} onClick={(event) => {handleClickAddPayment(event)}}>追加</button>
                                        {/*<p className={editorStyles.restrictArrayLength}>{appDetails.payments.length}個/4個</p>*/}
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='published_at'>公開日</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <p className={commonStyles.cautionText}>{cautions.published_at}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <input 
                                            className={commonStyles.primaryInput} 
                                            name='published_at' 
                                            type='month' 
                                            value={appDetails.published_at.slice(0,7)}  
                                            ref={inputPublishedAt} 
                                            onChange={handleChangePublishedAt}
                                        />
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='shortDescription'>短い説明（60字まで）</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <p className={commonStyles.cautionText}>{cautions.short_description}</p>
                                    <div className={`${editorStyles.inputWrapper} ${editorStyles.descriptionWrapper}`}>
                                        <textarea className={commonStyles.primaryTextArea} name='shortDescription' value={appDetails.short_description} maxLength={MAX_LENGTH_SHORT_DESCRIPTION} ref={textAreaShortDescription} onChange={handleChangeShortDescription}></textarea>
                                        <p>{appDetails.short_description.length}/{MAX_LENGTH_SHORT_DESCRIPTION}字</p>
                                    </div>
                                </div>
                                <div className={editorStyles.attributeWrapper}>
                                    <label htmlFor='longDescription'>長い説明（200字まで）</label>
                                    <span className={editorStyles.anyMark}>任意</span>
                                    <p className={commonStyles.cautionText}>{cautions.long_descriptpion}</p>
                                    <div className={`${editorStyles.inputWrapper} ${editorStyles.descriptionWrapper}`}>
                                        <textarea className={commonStyles.primaryTextArea} name='longDescription' value={appDetails.long_description} maxLength={MAX_LENGTH_LONG_DESCRIPTION} ref={textAreaLongDescription} onChange={handleChangeLongDescription}></textarea>
                                        <p>{appDetails.long_description.length}/{MAX_LENGTH_LONG_DESCRIPTION}字</p>
                                    </div>
                                </div>
                                <div className={editorStyles.buttonsWrapper}>
                                    {/*
                                        <button className={commonStyles.primaryButton}>下書き保存</button>
                                    */}
                                    <button className={commonStyles.primaryButton} onClick={()=>{setIsOpenPreview(true);window.scrollTo({top: 0});}}>プレビュー</button>
                                    <button 
                                        className={commonStyles.primaryButton} 
                                        onClick={(event) => {
                                            if (isNew) {
                                                event.preventDefault();
                                                handleAddNewService(event);
                                                setPredictServiceNames([]);
                                            } else {
                                                handleUpdateService(event);
                                            }
                                        }}> 公開
                                    </button>
                                </div>
                                {/*<div className={editorStyles.attributeWrapper}>
                                    <label htmlFor={'equipment'}>必要機材</label>
                                    <ul style={{display: appDetails.equipments.length ? 'flex' : 'none'}}>
                                    {appDetails.equipments.map((equipment)=>(
                                        <li>
                                            <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                {equipmentAndLabel[equipment]}
                                                <button className={editorStyles.removeButton} onClick={() => handleRemoveEquipment(equipment)}>×</button>
                                            </span>
                                        </li>
                                    ))}
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.equipment}</p>
                                    <div className={editorStyles.inputWrapper}
                                        <select className={commonStyles.primarySelect} name='equipment' ref={selectEquipment} onChange={()=>{setCautions({...cautions, equipment: ''})}}>
                                            {
                                                Object.keys(equipmentAndLabel).map((key)=>{
                                                    return <option value={key}>{equipmentAndLabel[key]}</option>
                                                })
                                            }
                                        </select>
                                        <button className={commonStyles.primaryButton} onClick={handleClickAddEquipment}>追加</button>
                                        <p className={editorStyles.restrictArrayLength}>{appDetails.equipments.length}個/{MAX_LENGTH_EQUIPMENTS}個</p>
                                    </div>
                                </div>*/}
                                {/*<div className={editorStyles.attributeWrapper}>
                                    <label for='dataset'>データセット</label>
                                    <ul style={{display: appDetails.datasets.length ? 'flex' : 'none'}}>
                                        {appDetails.datasets.map((dataset)=>(
                                            <li>
                                                <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                    {dataset}
                                                    <button className={editorStyles.removeButton} onClick={() => handleRemoveDataset(dataset)}>×</button>
                                                </span>
                                            </li>
                                        ))} 
                                    </ul>
                                    <p className={commonStyles.cautionText}>{cautions.dataset}</p>
                                    <div className={editorStyles.inputWrapper}>
                                        <input className={commonStyles.primaryInput} name='dataset' type='text' maxLength={MAX_LENGTH_DATASET_CONTENTS} ref={inputDataset} onChange={()=>{setCautions({...cautions, dataset: ''})}}></input>
                                        <button className={commonStyles.primaryButton} onClick={handleClickAddDataset}>追加</button>
                                    </div>
                                    <p className={editorStyles.restrictLength}>{appDetails.datasets.length}個/{MAX_LENGTH_DATASETS}個</p>
                                </div>*/}
                            </div>
                        </section>
                        <section className={commonStyles.primarySection}>
                            <div className={editorStyles.tabContainer}>
                                {tabs.map(tab => (
                                <div key={tab} className={`${editorStyles.tabItem} ${activeTab === tab ? editorStyles.tabItemActive : ''}`} onClick={() => setActiveTab(currentTab => currentTab === tab ? null : tab)}>
                                    {tab}
                                </div>
                                ))}
                            </div> 
                            <div className={editorStyles.articleArea}>
                                {(()=>{
                                    switch(activeTab) {
                                        case '追加情報':
                                            return(
                                            <>
                                                <h2 className={commonStyles.primaryH2}>主な用途</h2>
                                                {/*{errorSection === 'usage' && <div ref={errorRefUsage} className={editorStyles.errorMessage}>{errorMessage}</div>}*/}
                                                <div className={editorStyles.articleWrapper}>
                                                    {
                                                        appDetails.primary_usage.map((p, index)=>{
                                                            return (
                                                                <P key={index} handleChangeTextAreaAsP={(e)=>handleChangeTextAreaAsP(e.target, p.content_type, index, 'primary_usage')} 
                                                                    handleChangeSelectTypeOfP={(e)=>handleChangeSelectTypeOfP(e.target, p.content, index, 'primary_usage')}
                                                                    handleChangeTable={(table)=>handleChangeTable(table, index, 'primary_usage')}
                                                                    handleChangeTableType={(type)=>handleChangeTableType(type, index, 'primary_usage')}
                                                                    id={`primary_usage_${index}`}
                                                                    handleChangeInputFile={(e)=>handleChangeInputFile(e.target, p.content_type, index, 'primary_usage')}
                                                                    handleClickMoveButton={(direction)=>handleClickMoveButton(index, direction, 'primary_usage')}
                                                                    handleClickDeleteButton={()=>(handleClickDeleteButton(index, 'primary_usage'))}
                                                                    handleClickAddPButton={()=>(handleClickAddPButton(index, 'primary_usage'))}
                                                                    setSubmissionMessage={setSubmissionMessage}
                                                                    p={p}/>
                                                        );
                                                        })
                                                    }
                                                    { !appDetails.primary_usage.length && 
                                                        <button type="button" className={editorStyles.buttonAddP} onClick={() => handleClickAddPButton(appDetails.primary_usage.length, 'primary_usage')}>+</button>
                                                    }
                                                </div>
                                                <h2 className={commonStyles.primaryH2}>導入方法</h2>
                                                {/*{errorSection === 'howToInstall' && <div ref={errorRefHowToInstall} className={editorStyles.errorMessage}>{errorMessage}</div>}*/}
                                                <div className={editorStyles.articleWrapper}>
                                                    {
                                                        appDetails.how_to_install.map((p, index)=>{
                                                            return (
                                                                <P key={index} handleChangeTextAreaAsP={(e)=>handleChangeTextAreaAsP(e.target, p.content_type, index, 'how_to_install')} 
                                                                    handleChangeSelectTypeOfP={(e)=>handleChangeSelectTypeOfP(e.target, p.content, index, 'how_to_install')}
                                                                    handleChangeTable={(table)=>handleChangeTable(table, index, 'how_to_install')}
                                                                    handleChangeTableType={(type)=>handleChangeTableType(type, index, 'how_to_install')}
                                                                    id={`how_to_install_${index}`}
                                                                    handleChangeInputFile={(e)=>handleChangeInputFile(e.target, p.content_type, index, 'how_to_install')}
                                                                    handleClickMoveButton={(direction)=>handleClickMoveButton(index, direction, 'how_to_install')}
                                                                    handleClickDeleteButton={()=>(handleClickDeleteButton(index, 'how_to_install'))}
                                                                    handleClickAddPButton={()=>(handleClickAddPButton(index, 'how_to_install'))}
                                                                    setSubmissionMessage={setSubmissionMessage}
                                                                    p={p}/>
                                                        );
                                                        })
                                                    }
                                                    { !appDetails.how_to_install.length && 
                                                        <button type="button" className={editorStyles.buttonAddP} onClick={() => handleClickAddPButton(appDetails.how_to_install.length, 'how_to_install')}>+</button>
                                                    }
                                                </div>
                                                <h2 className={commonStyles.primaryH2}>操作方法</h2>
                                                {/*{errorSection === 'howToOperate' && <div ref={errorRefHowToOperate} className={editorStyles.errorMessage}>{errorMessage}</div>}*/}
                                                <div className={editorStyles.articleWrapper}>
                                                    {
                                                        appDetails.how_to_execute.map((p, index)=>{
                                                            return (
                                                                <P key={index} handleChangeTextAreaAsP={(e)=>handleChangeTextAreaAsP(e.target, p.content_type, index, 'how_to_execute')} 
                                                                    handleChangeSelectTypeOfP={(e)=>handleChangeSelectTypeOfP(e.target, p.content, index, 'how_to_execute')}
                                                                    handleChangeTable={(table)=>handleChangeTable(table, index, 'how_to_execute')}
                                                                    handleChangeTableType={(type)=>handleChangeTableType(type, index, 'how_to_execute')}
                                                                    id={`how_to_execute_${index}`}
                                                                    handleChangeInputFile={(e)=>handleChangeInputFile(e.target, p.content_type, index, 'how_to_execute')}
                                                                    handleClickMoveButton={(direction)=>handleClickMoveButton(index, direction, 'how_to_execute')}
                                                                    handleClickDeleteButton={()=>(handleClickDeleteButton(index, 'how_to_execute'))}
                                                                    handleClickAddPButton={()=>(handleClickAddPButton(index, 'how_to_execute'))}
                                                                    setSubmissionMessage={setSubmissionMessage}
                                                                    p={p}/>
                                                        );
                                                        })
                                                    }
                                                    { !appDetails.how_to_execute.length && 
                                                        <button type="button" className={editorStyles.buttonAddP} onClick={() => handleClickAddPButton(appDetails.how_to_execute.length, 'how_to_execute')}>+</button>
                                                    }
                                                </div>
                                                <h2 className={commonStyles.primaryH2}>料金の詳細</h2>
                                                {/*{errorSection === 'paymentDetail' && <div ref={errorRefPaymentDetail} className={editorStyles.errorMessage}>{errorMessage}</div>}*/}
                                                <div className={editorStyles.articleWrapper}>
                                                    {
                                                        appDetails.payment_detail.map((p, index)=>{
                                                            return (
                                                                <P key={index} handleChangeTextAreaAsP={(e)=>handleChangeTextAreaAsP(e.target, p.content_type, index, 'payment_detail')} 
                                                                    handleChangeSelectTypeOfP={(e)=>handleChangeSelectTypeOfP(e.target, p.content, index, 'payment_detail')}
                                                                    handleChangeTable={(table)=>handleChangeTable(table, index, 'payment_detail')}
                                                                    handleChangeTableType={(type)=>handleChangeTableType(type, index, 'payment_detail')}
                                                                    id={`payment_detail_${index}`}
                                                                    handleChangeInputFile={(e)=>handleChangeInputFile(e.target, p.content_type, index, 'payment_detail')}
                                                                    handleClickMoveButton={(direction)=>handleClickMoveButton(index, direction, 'payment_detail')}
                                                                    handleClickDeleteButton={()=>(handleClickDeleteButton(index, 'payment_detail'))}
                                                                    handleClickAddPButton={()=>(handleClickAddPButton(index, 'payment_detail'))}
                                                                    setSubmissionMessage={setSubmissionMessage}
                                                                    p={p}/>
                                                        );
                                                        })
                                                    }
                                                    { !appDetails.payment_detail.length && 
                                                        <button type="button" className={editorStyles.buttonAddP} onClick={() => handleClickAddPButton(appDetails.payment_detail.length, 'payment_detail')}>+</button>
                                                    }
                                                </div>
                                                <div className={editorStyles.buttonsWrapper}>
                                                    <button className={commonStyles.primaryButton} onClick={()=>{setIsOpenPreview(true);window.scrollTo({top: 0});}}>プレビュー</button>
                                                    {/*<button className={commonStyles.primaryButton}>下書き保存</button>*/}
                                                    <button 
                                                        className={commonStyles.primaryButton} 
                                                        onClick={(event) => {
                                                            if (isNew) {
                                                                event.preventDefault();
                                                                handleAddNewService(event);
                                                                setPredictServiceNames([]);
                                                            } else {
                                                                handleUpdateService(event);
                                                            }
                                                        }}> 公開
                                                    </button>
                                                </div>
                                            </>);
                                        case '開発者向け':
                                            return(
                                            <>
                                                <div className={editorStyles.attributeWrapper}>
                                                    <label htmlFor='publisher'>開発者</label>
                                                    <span className={editorStyles.anyMark}>任意</span>
                                                    <ul style={{display: appDetails.publishers.length ? 'flex' : 'none'}}>
                                                    {appDetails.publishers.map((publisher, i)=>(
                                                            <li key={i}>
                                                                <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                                    {publisher}
                                                                    <button className={editorStyles.removeButton} onClick={() => handleRemovePublisher(publisher)}>×</button>
                                                                </span>
                                                            </li>
                                                    ))} 
                                                    </ul>
                                                    <p className={commonStyles.cautionText}>{cautions.publisher}</p>
                                                    <div className={editorStyles.inputWrapper}>
                                                        <input className={commonStyles.primaryInput} name='publisher' type='text' maxLength={MAX_LENGTH_PUBLISHER_CONTENTS} ref={inputPublisher} onChange={()=>{setSubmissionMessage(''); setCautions({...cautions, publisher: ''})}}></input>
                                                        <button className={commonStyles.primaryButton} onClick={handleClickAddPublisher}>追加</button>
                                                    </div>
                                                    {/*<p className={editorStyles.restrictLength}>{appDetails.publishers.length}個/{MAX_LENGTH_PUBLISHERS}個</p>*/}
                                                </div>
                                                <div className={editorStyles.attributeWrapper}>
                                                    <label htmlFor='model'>モデル</label>
                                                    <span className={editorStyles.anyMark}>任意</span>
                                                    <ul style={{display: appDetails.models.length ? 'flex' : 'none'}}>
                                                    {appDetails.models.map((model, i)=>(
                                                            <li key={i}>
                                                                <span className={`${editorStyles.tag} ${commonStyles.tag}`}>
                                                                    {model}
                                                                    <button className={editorStyles.removeButton} onClick={() => handleRemoveModel(model)}>×</button>
                                                                </span>
                                                            </li>
                                                    ))} 
                                                    </ul>
                                                    <p className={commonStyles.cautionText}>{cautions.model}</p>
                                                    <div className={editorStyles.inputWrapper}>
                                                        <input className={commonStyles.primaryInput} name='model' type='text' maxLength={MAX_LENGTH_MODEL_CONTENT} ref={inputModel} onChange={()=>{setSubmissionMessage(''); setCautions({...cautions, model: ''})}}></input>
                                                        <button className={commonStyles.primaryButton} onClick={handleClickAddModel}>追加</button>
                                                    </div>
                                                    {/*<p className={editorStyles.restrictLength}>{appDetails.models.length}個/{MAX_LENGTH_MODELS}個</p>*/}
                                                </div>
                                                <div className={editorStyles.buttonsWrapper}>
                                                    {/*
                                                        <button className={commonStyles.primaryButton}>下書き保存</button>
                                                    */}
                                                    <button className={commonStyles.primaryButton} onClick={()=>{setIsOpenPreview(true);window.scrollTo({top: 0});}}>プレビュー</button>
                                                    <button 
                                                        className={commonStyles.primaryButton} 
                                                        onClick={(event) => {
                                                            if (isNew) {
                                                                event.preventDefault();
                                                                handleAddNewService(event);
                                                                setPredictServiceNames([]);
                                                            } else {
                                                                handleUpdateService(event);
                                                            }
                                                        }}> 公開
                                                    </button>
                                                </div>                          
                                            </>);
                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </section>
                    </div>
                    <div className={`${commonStyles.overlay} ${isLoading ? commonStyles.active : ''}`}>
                        {isLoading && <Loading />}
                    </div>
                </main>
                :
                <></>
            }
            {
                isOpenPreview ?
                <main className={`${commonStyles.mainWithSideBarLR} ${commonStyles.primaryMain}`}>
                    <SideBar twitterNav={true} updateNav={true} last={'updateNav'}/>
                    <div className={commonStyles.sectionWrapper}>
                        <section className={commonStyles.secondarySection}>
                            <button className={commonStyles.primaryButton} onClick={()=>setIsOpenPreview(false)}>編集に戻る</button>
                        </section>
                        <section className={commonStyles.primarySection}>
                            <div className={articleStyles.appInfoWrapper}>
                                <img className={articleStyles.appThumbnail} src={appDetails.thumbnail_base64 ? (appDetails.thumbnail_base64.startsWith('data:image') ? appDetails.thumbnail_base64 : `data:image/jpeg;base64,${appDetails.thumbnail_base64}`) : ''}></img>
                                <div>
                                    <div className={articleStyles.titleWrapper}>
                                        <h1 className={articleStyles.appName}>{appDetails.name}</h1>
                                        <div className={articleStyles.settingButtonContainer}>
                                            <button className={articleStyles.editButton}>
                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                            </button>
                                        </div>
                                    </div>
                                    <p className={articleStyles.viewWrapper}>
                                        <span className={articleStyles.viewCount}>{appDetails.view_count}</span><span className={articleStyles.viewsText}>views</span>
                                    </p>
                                    <div className={articleStyles.evaluateWrapper}>
                                        <button>
                                            <FontAwesomeIcon className={articleStyles.thumbUp} icon={faThumbsUp}/>
                                        </button>
                                        {/*<span>n</span>*/}
                                        <button className={articleStyles.favoriteButton}>
                                            <FontAwesomeIcon className={articleStyles.favorite} icon={faBookmark}/>
                                        </button>
                                    </div>
                                    {(()=>{
                                        switch(activePreviewInfoTab) {
                                            case '基本':
                                                return (
                                                    <div className={articleStyles.infoTab} >
                                                        {appDetails.published_at ? 
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faRocket} />
                                                                <p>{appDetails.published_at.slice(0,7)}</p>    
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {appDetails.revisioned_at ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faArrowsRotate} />
                                                                <p>{appDetails.revisioned_at}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {appDetails.tags.length ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faTag} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        appDetails.tags.map((tag, i)=>{
                                                                            return <li key={i} className={commonStyles.tagPrimaryColor}><FontAwesomeIcon icon={faHashtag}/>{tagAndLabel[tag]}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {appDetails.payments.length  ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faCreditCard} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        appDetails.payments.map((payment, i)=>{
                                                                            return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{paymentAndLabel[payment]}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {appDetails.environments.length ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faLaptop} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        appDetails.environments.map((environment,i)=>{
                                                                            return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{environmentAndLabel[environment]}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                );
                                            case '開発':
                                                return (
                                                    <div className={articleStyles.infoTab}>
                                                        {appDetails.publishers.length ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faAt} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        appDetails.publishers.map((publisher, i)=>{
                                                                            return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{publisher}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {appDetails.models.length ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faBrain} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        appDetails.models.map((model, i)=>{
                                                                            return <li key={i} className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{model}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {/*appDetails.datasets.length ?
                                                            <div className={articleStyles.iconWrapper}>
                                                                <FontAwesomeIcon icon={faDatabase} />
                                                                <ul className={articleStyles.iconsUl}>
                                                                    {
                                                                        appDetails.datasets.map((dataset)=>{
                                                                            return <li className={`${commonStyles.tag} ${commonStyles.secondaryBgColor}`}>{dataset}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <></>
                                                        */}
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })()}
                                    <div className={commonStyles.tabInverseContainer}>
                                        {previewInfoTabs.map(tab => (
                                        <div key={tab} className={`${commonStyles.tabItemInverse} ${activePreviewInfoTab === tab ? commonStyles.tabItemActive : ''}`} onClick={() => setActivePreviewInfoTab(tab)}>
                                            {tab}
                                        </div>
                                        ))}
                                    </div> 
                                </div>
                            </div>
                            <p className={`${commonStyles.primaryP} ${articleStyles.longDescription}`}>{appDetails.long_description}</p>
                            <a href={isURL(appDetails.url) ? appDetails.url : ''} target='_blank' rel='nofollow' className={`${commonStyles.primaryButton} ${articleStyles.toSiteButton}`}>公式サイトはこちら</a>
                        </section>
                        <section className={commonStyles.primarySection}>
                            <div className={`${commonStyles.tabContainer} ${articleStyles.tabContainerArticle}`}>
                                {previewTabs.map(tab => (
                                <div key={tab} className={`${commonStyles.tabItem} ${activePreviewTab === tab ? commonStyles.tabItemActive : ''}`} onClick={() => setActivePreviewTab(tab)}>
                                    {tab}
                                </div>
                                ))}
                            </div> 
                            <div className={articleStyles.articleArea}>
                            {(()=>{
                                switch(activePreviewTab) {
                                    case '使い方':
                                        return (
                                            <>
                                                <h2 id='primary_usage' className={commonStyles.primaryH2}>主な用途</h2>
                                                {
                                                    appDetails.primary_usage.map((p, i)=>{
                                                        switch(p.content_type){
                                                            case 'paragraph':
                                                                return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                            case 'headline':
                                                                return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                            case 'small_headline':
                                                                return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                            case 'image':
                                                                return <img src={PreviewImg} className={articleStyles.img} />
                                                            case 'table':
                                                                if(p.content && p.table_type=='no_th'){
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                    {
                                                                                                        row.map((column, j)=>{
                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                        })
                                                                                                    }
                                                                                                </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                } else if (p.content && p.table_type=='row_th') {
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                if(i == 0){
                                                                                                    return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                } else {
                                                                                                    return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                } else if (p.content && p.table_type=='col_th') {
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                    {
                                                                                                        row.map((column, j)=>{
                                                                                                            if(j==0){
                                                                                                                return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                            }
                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                        })
                                                                                                    }
                                                                                                </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                } else if (p.content && p.table_type=='col_row_th') {
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                if(i == 0){
                                                                                                    return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                } else {
                                                                                                    return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                if(j==0){
                                                                                                                    return <th key={j} className={commonStyles.primaryTh}>{column}</th>
                                                                                                                }
                                                                                                                return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                }
                                                                break;
                                                        }
                                                    })
                                                }
                                                <h2 id='how_to_install' className={commonStyles.primaryH2}>導入方法</h2>
                                                {
                                                    appDetails.how_to_install.map((p, i)=>{
                                                        switch(p.content_type){
                                                            case 'paragraph':
                                                                return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                            case 'headline':
                                                                return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                            case 'small_headline':
                                                                return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                            case 'image':
                                                                return <img src={PreviewImg} className={articleStyles.img} />
                                                            case 'table':
                                                                if(p.content && p.table_type=='no_th'){
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                    {
                                                                                                        row.map((column, j)=>{
                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                        })
                                                                                                    }
                                                                                                </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                } else if(p.content && p.table_type=='row_th') {
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                if(i == 0){
                                                                                                    return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <th key={j} className={commonStyles.primaryTd}>{column}</th>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                } else {
                                                                                                    return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                }
                                                                break;    
                                                        }
                                                    })
                                                }
                                                <h2 id='how_to_execute' className={commonStyles.primaryH2}>操作方法</h2>
                                                {
                                                    appDetails.how_to_execute.map((p, i)=>{
                                                        switch(p.content_type){
                                                            case 'paragraph':
                                                                return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                            case 'headline':
                                                                return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                            case 'small_headline':
                                                                return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                            case 'image':
                                                                return <img src={PreviewImg} className={articleStyles.img} />
                                                            case 'table':
                                                                if(p.content && p.table_type=='no_th'){
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                    {
                                                                                                        row.map((column, j)=>{
                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                        })
                                                                                                    }
                                                                                                </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                } else if(p.content && p.table_type=='row_th') {
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                if(i == 0){
                                                                                                    return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <th key={j} className={commonStyles.primaryTd}>{column}</th>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                } else {
                                                                                                    return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                }
                                                                break;   
                                                        }
                                                    })
                                                } 
                                            </>
                                        ); 
                                    case '料金':
                                        return (
                                            <>
                                                <h2 id='payment_detail' className={commonStyles.primaryH2}>料金の詳細</h2>
                                                {
                                                    appDetails.payment_detail.map((p, i)=>{
                                                        switch(p.content_type){
                                                            case 'paragraph':
                                                                return <p key={i} className={commonStyles.primaryP}>{p.content}</p>
                                                            case 'headline':
                                                                return <h3 id={p.content} key={i} className={commonStyles.primaryH3}>{p.content}</h3>
                                                            case 'small_headline':
                                                                return <h4 key={i} className={`${articleStyles.head}`}><span className={commonStyles.primaryH4}>{p.content}</span></h4>
                                                            case 'image':
                                                                return <img src={PreviewImg} className={articleStyles.img} />
                                                            case 'table':
                                                                if(p.content && p.table_type=='no_th'){
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                    {
                                                                                                        row.map((column, j)=>{
                                                                                                            return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                        })
                                                                                                    }
                                                                                                </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                } else if(p.content && p.table_type=='row_th') {
                                                                    return <div className={commonStyles.primaryTableWrapper}>
                                                                                <table className={commonStyles.primaryTable}>
                                                                                    <tbody>
                                                                                        {
                                                                                            p.content.map((row, i)=>{
                                                                                                if(i == 0){
                                                                                                    return <tr key={i} className={commonStyles.primaryTrHeader}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <th key={j} className={commonStyles.primaryTd}>{column}</th>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                } else {
                                                                                                    return <tr key={i} className={commonStyles.primaryTr}>
                                                                                                        {
                                                                                                            row.map((column, j)=>{
                                                                                                                return <td key={j} className={commonStyles.primaryTd}>{column}</td>
                                                                                                            })
                                                                                                        }
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                }
                                                                break;       
                                                        }
                                                    })
                                                }
                                            </>
                                        );                                              
                                    default:
                                        return null;
                                }
                            })()}
                            </div>
                        </section>
                    </div>
                    <SideBar 
                        articleNav={true} 
                        articleNavList={
                            activeTab == '使い方' ? 
                            [{key:'primary_usage', label:'主な用途', list: createNavList(appDetails.primary_usage)}, {key:'how_to_install', label:'導入方法', list: createNavList(appDetails.how_to_install)}, {key:'how_to_execute', label:'操作方法', list:createNavList(appDetails.how_to_execute)}] 
                            : activeTab=='料金' ? 
                            [{key:'payment_detail', label:'料金の詳細', list: createNavList(appDetails.payment_detail)}]
                            : []
                        }
                        last={'articleNav'}
                    />
                </main>
            :
            <></>
            }
            </>
            :
            <></>
        }
        {
            isInitialized && isError ?
            <Page404/>
            :
            <></>
        }
        {
            !isInitialized && !isError ?
            <main className={commonStyles.primaryMain}>
                <section className={commonStyles.transparentBgColor}>
                    <div className={commonStyles.sectionInner}>
                        <Loading/>
                    </div>
                </section>
            </main>
            :
            <></>
        }
    </>
    );
};

export default Editor;